import { css } from "@emotion/react";
import { ImageBgAffiliate } from "assets/common/images/affilate";

export const RegisterAffiliateStyle = {
  wrapper: css`
    background-image: url(${ImageBgAffiliate});
    background-repeat: no-repeat;
    background-size: cover;

    display: flex;
    align-items: center;

    width: 100%;
    height: 100vh;
  `,

  textBox: css`
    display: flex;
    justify-content: center;
    align-items: flex-end;
    flex-direction: column;

    width: 45%;

    font-style: normal;
    font-weight: 400;
    text-align: right;
    color: #fff;
    h1 {
      font-size: 70px;
      font-family: "Poppins", sans-serif;

      line-height: 105px;
    }
    p {
      font-size: 16px;
      font-family: "Poppins", sans-serif;
      line-height: 24px;
    }

    span {
      font-family: "Poppins", sans-serif;
      margin-top: 20px;
      font-size: 16px;
      line-height: 24px;
      text-decoration-line: underline;
    }
  `,

  mainContent: css`
    padding-left: 5%;
    width: 55%;

    h3 {
      text-align: center;

      color: #3e363f;
      font-family: "Poppins", sans-serif;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      margin-top: 20px;
      line-height: 27px;
    }
  `,

  formGroup: css`
    background-color: white;
    border-radius: 5px;
    margin: 5px 0;

    padding: 15% 10% 10%;
    width: 60%;
  `,

  form: css`
    margin-top: 15px;

    .ant-input {
      &:last-of-type {
        height: 37.91px !important;
      }
      height: 36.91px !important;
      border-color: rgba(0, 0, 0, 0.24) !important;
      border-radius: 5px !important;
      margin: 5px 0 15px 0;

      padding: 0 12px !important;
      &-affix-wrapper {
        overflow: hidden;
        height: 40px !important;
        border-color: rgba(0, 0, 0, 0.24) !important;
        border-radius: 5px !important;

        margin: 5px 0 15px 0;
      }
    }
    label {
      color: #3e363f;
      font-family: "Poppins", sans-serif;
      font-size: 0.8vw;
      font-style: normal;
      font-weight: 400;
      line-height: 23px;

      span {
        color: #6f6af9;
      }
    }
  `,

  btnGroup: css`
    gap: 10px;
    margin-top: 5px;

    button {
      width: 6vw;
      height: 2.25vw;
      border-radius: 30px;
      span {
        color: #fff;
        text-align: center;
        font-family: "Poppins", sans-serif;
        font-size: 0.8vw;
        font-style: normal;
        font-weight: 500;
        line-height: 1.715em;
      }
      &:first-of-type {
        background: #6f6af9;
        &:hover {
          background-color: #673ab7 !important;
        }
      }
      &:nth-of-type(2) {
        border-color: #6f6af9 !important;
        &:hover {
          background-color: #673ab7;
          span {
            color: white;
          }
        }
        span {
          color: #6f6af9;
        }
      }
    }
  `,
};
