import styled from "@emotion/styled";
import { theme, themeCustom } from "styles/theme";

interface Props {
  percent: number;
}

export const ProfileUserContainer = styled.div<Props>`
  .wrapperProfile {
    padding: 48px 16px 0 16px;
  }

  .header {
    &-index {
      text-align: center;
      margin-top: 25.3px;
      p {
        color: ${(props) => props.theme.colors.white};
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 28px;
        letter-spacing: 0.15px;
      }
      span {
        font-size: 12px;
        color: ${(props) => props.theme.colors.white};
      }
    }

    &-vip {
      margin-top: 4px;
      span {
        font-size: 10px;
        color: ${theme.colors.white};
        text-transform: uppercase;
        border-radius: 4px;

        padding: 2px 6px;
        background-color: #ff1a00;
      }
    }
  }

  .progress {
    overflow: hidden;

    margin-top: 28px;
    border-radius: 20px;

    height: 32px;
    width: 100%;

    background-color: #d3d3d3;

    position: relative;
    &::before {
      content: "";
      width: ${(props) => props.percent}%;
      height: 100%;
      background: #3119ff;
      position: absolute;
      left: 0;
      top: 0;
      border-radius: 20px;
    }

    span {
      position: absolute;
      font-size: 14px;
      color: ${themeCustom.colors.white};
      font-weight: 700;
      line-height: 0;

      top: 50%;

      transform: translateY(-50%);
    }
  }

  .vipProgress {
    &-lv {
      left: 6px;
    }

    &-total {
      right: 6px;
    }
  }

  .describe {
    text-align: right;

    padding: 15px 16.78px 9px 0;
    color: #9e9e9e;
    font-size: 9px;
    font-style: normal;
    font-weight: 300;
    line-height: 15px;
  }
`;
