import { ImageESportGame } from "assets/desktop/games/e-sport";
import { ItemGame } from "types/game";

export const LIST_GAME_ESPORT: ItemGame[] = [
  {
    image: ImageESportGame,
    provider_code: "WKS9",
    provider_name: "Pinnacle",
    game_type: "SB",
  },
  // {
  //   image: "/assets/game/sports/exchange-sports.jpg",
  //   provider_code: "WKS9",
  //   provider_name: "9Wickets Sport",
  //   game_type: "SB",
  // },
  // {
  //   image: "/assets/game/sports/saba-sports.jpg",
  //   provider_code: "IBC",
  //   provider_name: "SABA Sport",
  //   game_type: "SB",
  // },
  // {
  //   image: "/assets/game/sports/becric-sports.jpg",
  //   provider_code: "SBO",
  //   provider_name: "SBO",
  //   game_type: "SB",
  // },
  // {
  //   image: "/assets/game/sports/bti-sports.jpg",
  //   provider_code: "BTI",
  //   provider_name: "BTI Sport",
  //   game_type: "SB",
  // },
  // {
  //   image: "/assets/game/sports/CMD-sports.jpg",
  //   provider_code: "CMD",
  //   provider_name: "CMD365",
  //   game_type: "SB",
  // },
  // {
  //   image: "/assets/game/sports/Betswiz-sports.jpg",
  //   provider_code: "BW",
  //   provider_name: "BetsWiz",
  //   game_type: "SB",
  // },
];
