const MESSAGE = {
  notification: "Thông báo",
  confirm: "Xác nhận",
  updateSuccessfully: "Cập nhật thành công",

  loginGame: {
    requiredLoginGame: "Bạn phải đăng nhập để tham gia trò chơi này!!",
    loadingLoginGame: "Đang tải game ...",
    cancelRequired: "Thao tác bị huỷ do có một yêu cầu mới.",
    errorLoginGame: "Game hiện tại đang bảo trì, vui lòng thử lại sau.",
    successLoginGame: "Đăng nhập game thành công !!",
    serverErrorLoginGame: "Server hiện tại đang bảo trì, vui lòng thử lại sau.",
  },

  changePassword: {
    warning:
      "Xin lưu ý rằng để bảo mật cho tài khoản của bạn, vui lòng không sử dụng mật khẩu mới giống mật khẩu cũ, sau khi thay đổi mật khẩu, bạn sẽ cần đăng nhập lại với mật khẩu mới ngay lập tức",
    success:
      "Mật khẩu bạn đã thay đổi thành công, xin vui lòng đăng nhập lại bằng mật khẩu mới",
  },

  UPLOAD_FILE_SUCCESS: "File is uploaded success",
  UPLOAD_FILE_FAIL: "File is uploaded fail. Try again",
  ALL_FIELD: "Please enter all fields",
  logout: {
    title: "Bạn có muốn đăng xuất?",
  },
};
export default MESSAGE;
