import axiosRequest from "api";
import { AxiosError } from "axios";
import { ResponseCode } from "constants/response";
import { payloadHelper } from "helpers/payload-helper";
import { ApiResponse } from "types/api";
import {
  MemberBalancePayload,
  MemberBalanceResType,
  ReferralOrderResponse,
  ReferralResponse,
  UploadAvatarUserPayload,
} from "types/user";

import {
  ChangePasswordPayload,
  GetProfilePayload,
  GetProfileResponse,
  ReferralOrderPayload,
  UpdateProfilePayload,
  UpdateProfileResponse,
} from "./type";

export const UserApi = {
  async updateProfile(typename: string, payload: UpdateProfilePayload) {
    const { data } = await axiosRequest.post<UpdateProfileResponse>(
      "/",
      payloadHelper("UpdateMemberDetail", typename, payload),
    );
    return data;
  },

  async changePassword(typename: string, payload: ChangePasswordPayload) {
    const { data } = await axiosRequest.post<ApiResponse>(
      "/",
      payloadHelper("ChangePassword", typename, payload),
    );
    return data;
  },
  async getProfile(payload: GetProfilePayload) {
    const response = await axiosRequest.post<GetProfileResponse>(
      "/",
      payloadHelper("MemberDetail", payload.username, payload),
    );
    return response.data;
  },

  async getMemberBalance(typename: string, payload: MemberBalancePayload) {
    const response = await axiosRequest.post<MemberBalanceResType>(
      "",
      payloadHelper("GetBalanceDV", typename, payload),
    );

    return response.data;
  },
  async getReferral(username: string) {
    const response = await axiosRequest.post<ReferralResponse>(
      "",
      payloadHelper("Referral", username, { username }),
    );
    const resData = response.data;
    if (resData.status === ResponseCode.SUCCESS) {
      return response.data;
    }
    throw new AxiosError(resData.msg);
  },

  async getReferralOrder(username: string, payload: ReferralOrderPayload) {
    const response = await axiosRequest.post<ReferralOrderResponse>(
      "",
      payloadHelper("ReferralOrder", username, payload),
    );
    const resData = response.data;
    if (resData.status === ResponseCode.SUCCESS) {
      return response.data;
    }
    throw new AxiosError(resData.msg);
  },

  async uploadAvatarUser(username: string, payload: UploadAvatarUserPayload) {
    const response = await axiosRequest.post<ApiResponse>(
      "",
      payloadHelper("UpdateAvatar", username, payload),
    );
    return response.data;
  },
};
