import styled from "@emotion/styled";
import { theme } from "styles/theme";
interface Props {
  isPromotion: boolean;
}
export const HeaderStyle = styled.div`
  background-color: ${(props) => props.theme.colors.black} !important;

  .wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 0 17px 0 42px;
  }

  .mainContentHeader {
    display: flex;
    align-items: center;
  }

  .menu {
    display: flex;
    align-items: center;
    gap: 20px;

    a {
      text-decoration: none;
    }

    .active {
      &::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 2px;
        background-color: ${theme.colors.text?.link};
        bottom: 0;
      }
    }
  }

  .logo {
    margin-right: 66px;
    cursor: pointer;
  }
`;

export const MenuItem = styled.div<Props>`
  .menuItem {
    padding: 24px 0;
    display: flex;
    align-items: center;
    gap: 8px;

    position: relative;

    span {
      font-size: 14px;
      color: ${(props) =>
        props.isPromotion ? "#B98D1B" : theme.colors.gray[100]};
    }
  }
`;
/* menuItem: (isPromotion: boolean) => css`
    padding: 24px 0;
    display: flex;
    align-items: center;
    gap: 8px;
    color: ${isPromotion ? "#B98D1B" : theme.colors.black};
    position: relative;
  `, */
