import styled from "@emotion/styled";
import { ImageBgAffiliate } from "assets/common/images/affilate";
import { media } from "styles/breakpoints";

export const LoginAffiliateStyle = styled.div`
  background-image: url(${ImageBgAffiliate});
  background-repeat: no-repeat;
  background-size: cover;

  display: flex;
  align-items: center;
  ${media.sm} {
    justify-content: center;
  }

  width: 100%;
  height: 100vh;
  .header {
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
    h1 {
      color: ${(props) => props.theme.colors.text?.[30]};
      font-size: 24px;
      font-weight: 700;
      line-height: 32px;
      padding: 4.61px;

      font-weight: bold;
      font-style: normal;
      text-transform: uppercase;
      font-family: "Poppins", sans-serif;
    }
    img {
      max-width: 172px;
      ${media.sm} {
        max-width: 156px;
      }
    }
  }

  .mainContent {
    padding: 3rem;
    margin-left: 35vw;
    margin-right: 35vw;
    width: 100vw;
    border-radius: 5px;
    ${media.sm} {
      margin-left: 5vw;
      margin-right: 5vw;
      padding: 6vw 4vw;
    }
    background-color: ${(props) => props.theme.colors.black};
    .ant-input-affix-wrapper {
      box-shadow: unset !important;
      overflow: hidden;
      border: unset !important;

      height: 56px !important;
      width: 100%;
      padding: 12px;
      border-bottom: 2px solid transparent !important;
      border-radius: 4px 4px 0 0 !important;

      background-color: #f2f2f2 !important;

      &:focus,
      :hover {
        border-bottom: 2px solid red !important;
      }
      .ant-input {
        height: unset !important;

        font-size: 16px;
        padding: 0 12px;

        background-color: #f2f2f2;

        &::placeholder {
          font-size: 16px;
          line-height: 24px;
          min-width: 0;
          outline: 0 !important;
          border-radius: 0;
          width: 100%;
          font-weight: 400;
          letter-spacing: 0.00937em;
          opacity: 0.65;
          font-family: "Poppins", sans-serif;

          background: none;
          border: none;

          color: #000000de;
        }
        &-suffix {
          padding-right: unset !important;
        }
      }
    }
  }
  .formGroup {
    display: flex;
    flex-direction: column;
    gap: 35px;

    margin-top: 34px;
  }

  .btn {
    text-align: center;

    margin: 80px 0 24px 0;
    button {
      width: 35%;
      height: 36px;
      border-radius: 3px;

      background: #6f6af9;

      span {
        color: #fff;
        text-align: center;
        font-style: normal;
        text-transform: uppercase;
        font-family: "Poppins", sans-serif;

        font-weight: 500;
        font-size: 14px;
        line-height: 24.01px;
      }
    }
  }
  .registerText {
    text-align: center;
    color: ${(props) => props.theme.colors.text?.[60]};
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    text-decoration: none;
    line-height: 24px;
    font-family: "Poppins", sans-serif;
    a {
      cursor: pointer;
      font-family: "Poppins", sans-serif;
      text-decoration: none;
      color: #6f6af9;
    }
  }
`;
