import styled from "@emotion/styled";

export const DefaultStyle = styled.div`
  .header {
    position: sticky;
    top: 0;
    z-index: 99;
  }

  .mainContent {
    background-color: ${(props) => props.theme.colors.black};
  }
`;
