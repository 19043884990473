import axiosRequest from "api";
import { payloadHelper } from "helpers/payload-helper";

import { AnnouncementFormType, AnnouncementResponse } from "./type";

export const AnnouncementApi = {
  getAnnouncement: (payload: AnnouncementFormType) =>
    axiosRequest.post<AnnouncementResponse>(
      "/",
      payloadHelper("Announcement", null, payload),
    ),
};
