import { ProviderGameType } from "types/game";

export const HotCrashGame: ProviderGameType = {
  provider_name: "HOT",
  provider_code: "HOT",
};
export const providerCrash: ProviderGameType[] = [
  {
    provider_name: "T1 Gaming",
    provider_code: "T1G",
  },
];
