/** @jsxImportSource @emotion/react */

import { useQuery, useQueryClient } from "@tanstack/react-query";
import { message } from "antd";
import { getMemberVipUser } from "api/vip/query";
import { VipMemberDetailType } from "api/vip/type";
import { IconBalanceUser } from "assets/mobile/icons/profile";
import { AvatarUserMobile } from "assets/mobile/images";
import { IconReferProfile, IconVipProfile } from "assets/mobile/images/profile";
import Rollover from "components/common/Modal/ModalProfile/ProfileModalSidebar/Rollover";
import MenuInformationUser from "components/mobile/MenuInformationUser";
import { LIST_FUNC_INFO_USER } from "constants/list-func-info-user";
import { PATH } from "constants/path";
import { QueryKeys } from "constants/queryKeys";
import { AppContext } from "contexts/app.context";
import { useGetMemberBalance, useGetProfile } from "query/users/queryHookUser";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { ProfileType } from "types/user";

import { InformationUserContainer } from "./style.index";

const InformationUser = () => {
  const queryClient = useQueryClient();
  const { state, dispatch } = useContext(AppContext);
  const navigate = useNavigate();
  const username = queryClient.getQueryData<string>([QueryKeys.username]) || "";

  const { data: dataProfile } = useGetProfile(username || "");
  const { data: balanceMember } = useGetMemberBalance(username || "");

  const { data: dataMemberVip } = useQuery<VipMemberDetailType>({
    queryKey: [QueryKeys.MemberVIP],
    queryFn: () => getMemberVipUser(username as string),
  });

  const handleNavigate = (key: string) => {
    if (key === "cashback") {
      message.warning({
        key: "cashback-maintenance",
        content: "Under maintenance !!!",
      });
      return;
    }
    navigate(key);
  };

  return (
    <InformationUserContainer>
      <div className="headerUser-index">
        <div className="headerUser-informationGroup">
          <div className="funcUser">
            <div
              className="funcUserItem"
              onClick={() => navigate(PATH.user.vipLevel)}
            >
              <img src={IconVipProfile} alt="" />
              <span>VIP</span>
            </div>
          </div>
          <div className="infoUser-index">
            <div className="infoUser-avatar">
              <img
                src={(dataProfile as ProfileType)?.avatar || AvatarUserMobile}
                alt=""
              />
              <span>VIP {dataMemberVip?.current_level}</span>
            </div>
            <p>UID: {username}</p>
            <p>{(dataProfile as ProfileType)?.full_name}</p>
            <div className="infoUser-balance">
              <IconBalanceUser />
              <span>{balanceMember?.total || 0.0} </span>
            </div>
            <Rollover />
          </div>
          <div className="funcUser">
            <div
              className="funcUserItem"
              onClick={() => navigate(PATH.user.affiliate)}
            >
              <img src={IconReferProfile} alt="" />
              <span>Refer</span>
            </div>
          </div>
        </div>
        <div className="headerUser-btnGroup">
          {LIST_FUNC_INFO_USER.map((item) => {
            return (
              <div
                onClick={() => {
                  if (item.name === "Deposit") {
                    if (!dispatch) return;
                    dispatch({
                      type: "SET_NAVIGATE_PROFILE",
                      payload: {
                        ...state,
                        idNavigateProfile: "user",
                      },
                    });
                  }

                  handleNavigate(item.link);
                }}
                key={item.key}
                className="btn-index"
              >
                <img src={item.icon} alt="" />
                {item.name}
              </div>
            );
          })}
        </div>
      </div>
      <MenuInformationUser />
    </InformationUserContainer>
  );
};

export default InformationUser;
