import axiosRequest from "api";
import { payloadHelper } from "helpers/payload-helper";
import { ApiResponse } from "types/api";

export interface CashBackReward {
  reward: string;
}

export interface CashBackRewardResponse {
  msg: string;
  status: number;
  data: CashBackReward[];
}

export const getMemberCashBackReward = async ({
  username,
}: {
  username: string;
}) => {
  const { data } = await axiosRequest.post<CashBackRewardResponse>(
    "/",
    payloadHelper("CashBack", username, { username }),
  );
  return data;
};

export interface CashBackRewardClaimPayload {
  player_id: string;
  claim: boolean;
  username: string;
}
export interface CashBackRewardClaimResponse extends ApiResponse {
  numberofsuccessful: string;
  showClaimButton: boolean;
}
export const dailyCashBackBonusClaim = async (
  payload: CashBackRewardClaimPayload,
) => {
  try {
    const { data } = await axiosRequest.post<CashBackRewardClaimResponse>(
      "/",
      payloadHelper("DailyCashBackBonusClaim", payload.username, payload),
    );
    if (data.status === 0) {
      return data;
    } else {
      return Promise.reject(data.msg);
    }
  } catch (error) {
    return Promise.reject(error);
  }
};
