import { css } from "@emotion/react";

import { media } from "./breakpoints";
import { theme } from "./theme";

export const DepositModalOverride = css`
  & .modal__user {
    &.ant-modal .ant-modal-close {
      display: none;
    }

    /* .ant-modal-centered .ant-modal {
      &.ant-layout .ant-layout-sider {
        border-radius: 8px !important;
      }
    } */
    .ant-layout .ant-layout-sider-children {
      width: 100%;
    }
    &.ant-modal .ant-modal-content {
      padding: 0;
      /* border-radius: 8px !important; */

      /* .ant-layout.ant-layout-has-sider {
        border-radius: 8px !important;
      }
      &.ant-layout .ant-layout-sider {
        border-radius: 8px !important;
      } */
    }
  }
`;

export const DropDownHeaderOverride = css`
  & .ant-dropdown {
    margin-top: 10px;
    &-menu {
      li {
        background-color: white !important;
        &:hover {
          span {
            color: #ff5d08;
          }
        }
      }
    }
  }
`;

export const MessageCustomOverride = css`
  .ant-message {
    ${media.md} {
      top: 50% !important;
    }
    &-notice-content {
      background: ${theme.colors.primary} !important;
      ${media.md} {
        background: ${theme.colors.black} !important;
      }
      border-radius: 4px !important;
      padding: 12px 18px !important;
      span {
        color: ${theme.colors.white} !important;
        font-size: 16px;
        font-weight: 700;

        margin-left: 10px;

        svg {
          width: 24px;
          height: 24px;
        }
      }
      .anticon {
        margin-left: unset !important;
      }
    }
    &-custom-content {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;

export const DropDownOverride = css`
  .ant-dropdown {
    padding: 3px;

    border-radius: 2px;
    background-color: #193b65;
    &-menu {
      border-radius: 2px !important;
      background-color: #0a203b !important;
      &-item {
        padding: unset !important;
        &:hover {
          background-color: ${theme.colors.primary} !important;
        }
      }
    }
  }
`;

export const SelectDropdownOverride = css`
  .ant-select {
    &-dropdown {
      border-radius: unset !important;
    }
    &-item {
      align-items: center;

      &-option {
        &-selected {
          background-color: #ea56c233 !important;
        }
        &-active {
          background-color: #ea56c233 !important;
          border-radius: unset !important;
          .ant-select-item-option-content {
            color: ${theme.colors.primary};
            font-weight: unset;
          }
        }
        &-content {
          font-size: 10px;
        }
      }
    }
  }
`;
