import { css } from "@emotion/react";
import { theme } from "styles/theme";

export const GameProviderStyle = {
  index: (length: number) => css`
    color: #fff;
    border-radius: 6px;
    background: #fff;
    box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.15);

    z-index: 10;
    margin-bottom: ${length > 10 ? "2vw" : 0};

    margin: 56px 0 53px 0;
  `,
  list: css`
    display: flex;
    justify-content: center;
    list-style: none;
    flex-wrap: wrap;
  `,
  item: css`
    display: flex;

    padding: 0 14px;

    &:last-of-type {
      .link {
        &::after {
          display: none;
        }
      }
    }

    .link {
      color: ${theme.colors.gray[100]};
      cursor: pointer;
      display: block;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      text-decoration: none;
      position: relative;

      padding: 12px 8px;

      border-bottom: 1px solid transparent;

      &::after {
        content: "";
        position: absolute;
        right: -14px;
        top: 50%;
        transform: translateY(-50%);
        width: 1px;
        height: calc(100% - 16px);
        background-color: ${theme.colors.gray[100]};
      }
    }

    .transition-all {
      transition-duration: 0.2s;
      transition-property: all;
      transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    }

    .selected {
      color: ${theme.colors.text?.link};

      border-radius: 6px;
      border-bottom: 1px solid #1877f2;
      background: #fff;
      box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.15);

      &::after {
        display: none !important;
      }

      &::before {
        content: "";
        position: absolute;
        left: -15px;
        top: 50%;
        transform: translateY(-50%);
        width: 1px;
        height: calc(100% - 16px);
        background-color: white;
      }
    }
  `,
};
