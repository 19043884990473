export enum EnumGameType {
  LiveCasino = "LC",
  Slot = "SL",
  Sport = "SB",
  Lottery = "LT",
  Fishing = "FH",
}

export enum SubGameEnum {
  Lobby = 1,
}

export enum EnumHotGame {
  provider_name = "HOT",
  provider_code = "HOT",
}
