import { AppState, ContextActionType } from "contexts/app.context";
import { ModalTypeValue } from "enums/modalType";
import { EnumItemMenuUserModal } from "enums/user";
import { Dispatch } from "react";
import { ContextAction } from "types";

export const handleCloseModal = (
  state: AppState,
  dispatch: Dispatch<ContextAction<ContextActionType, AppState>> | null,
) => {
  if (!dispatch) return;

  dispatch({
    type: "OPENMODAL",
    payload: {
      ...state,
      openModal: ModalTypeValue.CLOSE,
    },
  });
};
export const handleCloseModalRecord = (
  state: AppState,
  dispatch: Dispatch<ContextAction<ContextActionType, AppState>> | null,
) => {
  if (!dispatch) return;

  dispatch({
    type: "MODAL_RECORD",
    payload: {
      ...state,
      openModalRecord: ModalTypeValue.CLOSE,
    },
  });
};

export const handleOpenModal = (
  state: AppState,
  dispatch: Dispatch<ContextAction<ContextActionType, AppState>> | null,
  openModal: ModalTypeValue,
) => {
  if (!dispatch) return;
  dispatch({
    type: "OPENMODAL",
    payload: {
      ...state,
      openModal,
    },
  });
};
export const handleOpenModalRecord = (
  state: AppState,
  dispatch: Dispatch<ContextAction<ContextActionType, AppState>> | null,
  openModalRecord: ModalTypeValue,
) => {
  if (!dispatch) return;
  dispatch({
    type: "MODAL_RECORD",
    payload: {
      ...state,
      openModalRecord,
    },
  });
};
export const handleOpenKeyItemModal = (
  state: AppState,
  dispatch: Dispatch<ContextAction<ContextActionType, AppState>> | null,
  keyItem: EnumItemMenuUserModal,
) => {
  if (!dispatch) return;

  dispatch({
    type: "KEYITEM",
    payload: {
      ...state,
      keyItem,
    },
  });
};
export const handleKeyModal = (
  state: AppState,
  dispatch: Dispatch<ContextAction<ContextActionType, AppState>> | null,
  keymodal: EnumItemMenuUserModal,
) => {
  if (!dispatch) return;

  dispatch({
    type: "KEYMODAL",
    payload: {
      ...state,
      keymodal,
    },
  });
};
