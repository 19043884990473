/** @jsxImportSource @emotion/react */

import { CalendarOutlined } from "@ant-design/icons";
import { Button, ConfigProvider, Form, message, Modal } from "antd";
import { UserApi } from "api/user";
import DatePickerCustom from "components/common/DatePickerCustom";
import LoadingCustom from "components/common/LoadingCustom";
import InputCustomMobile from "components/mobile/base/InputCustomMobile";
import { isMobile } from "constants/common";
import { QueryKeys } from "constants/queryKeys";
import { REGEX_EMAIL, REGEX_FULLNAME } from "constants/regex";
import { ResponseCode } from "constants/response";
import { AppContext } from "contexts/app.context";
import dayjs from "dayjs";
import { ModalTypeValue } from "enums/modalType";
import { handleCloseModal } from "helpers/handleModal";
import { queryClient } from "index";
import moment from "moment";
import { useGetProfile } from "query/users/queryHookUser";
import { useContext, useEffect } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { ConfigCss } from "styles/config";
import { ProfileType } from "types/user";

import { ModalUserDetailStyle, TitleModalStyle } from "./style.index";

interface FormData {
  fullName: string;
  birthDay: any;
  email: string;
}
//

const ModalUserDetail = () => {
  const { state, dispatch } = useContext(AppContext);
  const username =
    localStorage.getItem("username") ||
    queryClient.getQueryData<string>([QueryKeys.username]);

  const { data, isLoading, refetch } = useGetProfile(username || "");
  const dataUserDetail = data as ProfileType;

  const { handleSubmit, control, getValues, watch, reset } = useForm<FormData>({
    mode: "all",
    defaultValues: {
      fullName: dataUserDetail?.full_name || "",
      birthDay: dataUserDetail?.birth
        ? dayjs(new Date(dataUserDetail?.birth))
        : null,
      email: dataUserDetail?.email || "",
    },
  });

  const validateForm = () => {
    const form = getValues();

    if (!form.fullName?.trim().length) {
      message.info({
        key: "profileData",
        content: "Full Name must be filled",
      });
      return true;
    }

    if (!form.birthDay?.trim().length) {
      message.info({
        key: "profileData",
        content: "BirthDay must be filled",
      });
      return true;
    }

    if (!form?.email?.length || !REGEX_EMAIL.test(form.email)) {
      message.info({
        key: "profileDataEmail",
        content: "Email invalidate",
      });
      return true;
    }
  };

  const onSubmit: SubmitHandler<FormData> = async (form: FormData) => {
    const isValidate = validateForm();

    if (isValidate) return;
    const { birthDay, fullName, email } = form;

    const birth =
      typeof birthDay === "string"
        ? moment(birthDay, "DD-MM-YYYY").format("YYYY-MM-DD")
        : moment(new Date(birthDay)).format("YYYY-MM-DD");

    const { status, msg } = await UserApi.updateProfile(
      dataUserDetail?.username || "",
      {
        username: dataUserDetail?.username || "",
        birth,
        email: email,
        fullname: fullName,
      },
    );

    if (status !== ResponseCode.SUCCESS) {
      message.info({
        key: "Error",
        content: msg,
      });
    } else {
      message.info({
        key: "memberupdate",
        content: "Update successful",
      });
      refetch();
    }
  };

  const handleInputFullNameChange = (
    event: React.KeyboardEvent<HTMLInputElement>,
  ) => {
    const key = event.key;

    if (!REGEX_FULLNAME.test(key) && key !== "Enter") {
      event.preventDefault();
    }
  };

  useEffect(() => {
    reset({
      fullName: dataUserDetail?.full_name || "",
      birthDay: dataUserDetail?.birth
        ? dayjs(new Date(dataUserDetail?.birth))
        : null,
      email: dataUserDetail?.email || "",
    });
  }, [data, state.openModal]);

  if (isLoading && !isMobile) {
    return <LoadingCustom height="100%" />;
  }

  const isDisable = dataUserDetail?.isUpdateProfile === "TRUE";

  return (
    <>
      {state.openModal === ModalTypeValue.USER_DETAIL && (
        <Modal
          open={state.openModal === ModalTypeValue.USER_DETAIL}
          onCancel={() => handleCloseModal(state, dispatch)}
          css={ModalUserDetailStyle.wrapper}
          footer={null}
          centered
          width={292}
        >
          <div css={ModalUserDetailStyle.mainContent}>
            <TitleModalStyle>Edit my Profile</TitleModalStyle>

            <Form
              css={ModalUserDetailStyle.form}
              onSubmitCapture={handleSubmit(onSubmit)}
            >
              <div css={[ModalUserDetailStyle.label, ConfigCss.flexBetween]}>
                <label>Full Name</label>
                <span>(As per you PAN id/ Aadhaar card)</span>
              </div>
              <InputCustomMobile
                // key={watch("fullName")}
                disabled={!!dataUserDetail?.full_name || isDisable}
                placeholder={"Please enter full name"}
                name={"fullName"}
                control={control}
                id={"fullName"}
                defaultValue={getValues("fullName")}
                onKeyDown={handleInputFullNameChange}
              />

              <div css={[ModalUserDetailStyle.label, ConfigCss.flexBetween]}>
                <label>Date of Birth</label>
              </div>

              <ConfigProvider
                theme={{
                  token: {
                    colorPrimary: "#ffbc00",
                    controlItemBgHover: "#EBEBEB",
                    borderRadiusSM: 50,
                  },
                }}
              >
                <DatePickerCustom
                  disabled={isDisable}
                  defaultValue={watch("birthDay")}
                  name="birthDay"
                  control={control}
                  format={"DD-MM-YYYY"}
                  suffixIcon={<CalendarOutlined />}
                />
              </ConfigProvider>

              <div css={[ModalUserDetailStyle.label, ConfigCss.flexBetween]}>
                <label>Email</label>
              </div>
              <InputCustomMobile
                // key={watch("email")}
                disabled={isDisable}
                placeholder={"Please enter email"}
                name={"email"}
                control={control}
                id={"email"}
              />

              <div css={ModalUserDetailStyle.tip}>
                Tips: Invalid/Unreal Information will hold Withdrawal
              </div>

              <div css={ModalUserDetailStyle.btnSubmit}>
                <Button disabled={isDisable} htmlType="submit" type="primary">
                  Submit
                </Button>
              </div>
            </Form>
          </div>
        </Modal>
      )}
    </>
  );
};

export default ModalUserDetail;
