import { css } from "@emotion/react";

type Props = {
  height: string;
  sizeIcon: string;
  colorSpin: string;
};

export const LoadingCustomStyle = {
  wrapper: ({ height, sizeIcon, colorSpin }: Props) => css`
    height: ${height};

    .anticon {
      width: ${sizeIcon};
      height: ${sizeIcon};
      svg {
        fill: ${colorSpin};
        width: 100%;
        height: 100%;
      }
    }
  `,
};
