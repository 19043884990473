import { UserApi } from "api/user";

export const getMemberBalanceQueryFn = async (username: string) => {
  const response = await UserApi.getMemberBalance(username, {
    username,
  });

  return response.data;
};

export const getUsersQueryFn = async (username: string) => {
  const response = await UserApi.getProfile({ username });

  return response.data;
};
