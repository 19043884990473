import {
  IconHome,
  IconHorse,
  IconLive,
  IconPokies,
  IconPromotions,
  IconSport,
} from "assets/desktop/header/icons";

import { PATH } from "./path";

export const LIST_MENU_HEADER = [
  {
    name: "Home",
    icon: IconHome,
    href: PATH.Home,
  },
  {
    name: "Sport",
    icon: IconSport,
    href: PATH.GamePage.Sports,
  },
  {
    name: "E-Sport",
    icon: IconSport,
    href: PATH.GamePage.eSports,
  },
  {
    name: "Instant Game",
    icon: IconHorse,
    href: PATH.GamePage.Instant,
  },
  {
    name: "Slots",
    icon: IconPokies,
    href: PATH.GamePage.Slot,
  },
  {
    name: "Live Casino",
    icon: IconLive,
    href: PATH.GamePage.LiveCasino,
  },
  {
    name: "Fishing",
    icon: IconHorse,
    href: PATH.GamePage.Fishing,
  },
  {
    name: "Promotions",
    icon: IconPromotions,
    href: PATH.Promotions,
  },
];
