import styled from "@emotion/styled";

export const CashBackContainer = styled.div`
  .contentCash {
    height: calc(100vh - 50px);
    overflow: auto;
    img {
      width: 100%;
      margin-top: -50px;
      object-fit: contain;
    }
  }

  .padding {
    padding: 63px 15px 15px;
  }

  .groupImage {
    display: flex;
    align-items: center;
    padding-left: 10px;
  }
  .width33 {
    margin-right: 15%;
    height: 70px;
    width: 70px;
    border-radius: 100px;

    background: ${(props) => props.theme.colors?.fullWhite};
    border: 1px solid white;

    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      width: 46px;
      height: 52px;
    }
  }
  .width1 {
    width: 100%;
    height: auto;
  }

  .p1 {
    font-size: 13px;
    display: flex;
    flex-direction: column;
    color: ${(props) => props.theme.colors.white};
  }

  .buttonShow {
    margin-left: 0.4vw;
    font-weight: bold;
    cursor: pointer;
    span {
      text-decoration: underline;
    }
  }
`;
