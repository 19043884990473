import styled from "@emotion/styled";
import { theme, themeCustom } from "styles/theme";

export const PanCardMobileContainer = styled.div`
  background: ${(props) => props.theme.colors.black};
  height: 100vh;

  .wrapperPan {
    padding: 48px 40px 0 40px;
  }
  .formGroup {
    &:last-of-type {
      padding-top: 20px;
    }
    label {
      display: block;
      font-size: 3.5vw;
      margin-bottom: 5px;
      width: 100%;
      color: ${(props) => props.theme.colors.white};
    }

    .ant-input {
      background: ${themeCustom.colors.white};
      color: ${themeCustom.colors.dark};
    }

    .ant-input-affix-wrapper,
    .ant-picker {
      border: 1.5px solid #00d39c !important;
      overflow: hidden;
    }

    .ant-input-prefix {
      margin-inline-end: 0px;
    }
    .ant-input {
      padding-left: 4px;
    }
  }

  .inputFile {
    align-items: center;
    background-color: #d1d1d1;
    display: flex;
    height: 200px;
    justify-content: center;
    margin-bottom: 30px;
    width: 100%;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    svg {
      rect {
        fill: transparent;
      }
      path {
        fill: black;
      }
    }
  }
  .btnSubmit {
    button {
      width: 100%;
      height: 42px;
      background: ${themeCustom.colors.white};
    }
  }

  .title {
    color: grey;
    font-size: 4.2vw;
    font-weight: 700;
    margin-top: 20px;
    color: ${(props) => props.theme.colors.text?.[30]};
  }

  .iconCalendar {
    color: ${theme.colors.white};
  }
  .loading {
    padding-bottom: 52px;
  }
`;
