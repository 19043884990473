/** @jsxImportSource @emotion/react */

import { Button } from "antd";
import { ReactComponent as IconLockPrefix } from "assets/common/icons/lock.svg";
import { ReactComponent as IconUserPrefix } from "assets/common/icons/person.svg";
import { LogoLoginAffiliate } from "assets/common/images/affilate";
import InputCustom from "components/common/InputCustom";
import InputPassword from "components/common/InputPassword";
import { PATH } from "constants/path";
import { useScrollTop } from "hooks/useScrollTop";
import { Link } from "react-router-dom";

import { LoginAffiliateStyle } from "./style.index";

const LoginAffiliatePage = () => {
  useScrollTop();
  return (
    <LoginAffiliateStyle>
      <div className="mainContent">
        <div className="header">
          <img src={LogoLoginAffiliate} alt="" />
          <h1>Sign In</h1>
        </div>
        <div className="formGroup">
          <InputCustom
            autoComplete="off"
            className="form__field"
            placeholder="Username"
            prefix={<IconUserPrefix />}
          />

          <InputPassword
            autoComplete="off"
            className="form__field"
            placeholder="Password"
            prefix={<IconLockPrefix />}
          />
        </div>

        <div className="btn">
          <Button type="primary">Login</Button>
        </div>
        <div className="registerText">
          Not yet registered? <Link to={PATH.agentRegister}>Register Now!</Link>
        </div>
      </div>
    </LoginAffiliateStyle>
  );
};

export default LoginAffiliatePage;
