import { withdrawApi } from "api/withdraw";
import { ResponseCode } from "constants/response";

export const getWithdrawalForm = async (username: string) => {
  const res = await withdrawApi.getWithdrawalForm(username);
  const resData = res.data;

  if (res.status === ResponseCode.SUCCESS) {
    return resData;
  }
  throw new Error(res.msg);
};
