import styled from "@emotion/styled";

export const HeaderUserContainer = styled.div`
  position: fixed;
  top: 0;

  width: 100%;
  padding: 8px 12px;

  z-index: 99;

  display: flex;
  align-items: center;
  justify-content: center;
  background: ${(props) => props.theme.colors.black};

  .iconH {
    position: absolute;

    left: 18px;

    height: 16px;
    width: 16px;
    svg {
      path {
        fill: ${(props) => props.theme.colors.text?.[30]};
      }
    }
  }
  .titleH {
    span {
      color: ${(props) => props.theme.colors.white};
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 32px;
      letter-spacing: 0.25px;
    }
  }
`;
