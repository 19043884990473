import { Helmet } from "react-helmet";
type Props = {
  title: string | undefined;
};

function HelmetCustom(props: Props) {
  const { title = "" } = props;
  return (
    <Helmet>
      <title>{title + " - AUS22"}</title>
    </Helmet>
  );
}

export default HelmetCustom;
