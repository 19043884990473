/** @jsxImportSource @emotion/react */

import { ImageDownloadApp, ImageFooterBackground } from "assets/desktop/footer";

import { FooterStyle } from "./index.style";

export interface SelectType {
  key: string;
  index: number;
}
export const Footer = () => {
  return (
    <FooterStyle>
      <div className="wrapperFooter">
        <img src={ImageFooterBackground} className="background" alt="" />

        <div className="textBox">
          <div className="heading">
            <h2>AUS22 Mobile APP</h2>
            <p>World of gaming truly in your palm of your hand</p>
          </div>

          <div className="download">
            <div className="downloadItem">
              <img src={ImageDownloadApp} alt="" />

              <span>Android</span>
              <p>Download</p>
            </div>
            <div className="downloadItem">
              <img src={ImageDownloadApp} alt="" />

              <span>IOS</span>
              <p>Download</p>
            </div>
          </div>
        </div>
      </div>
    </FooterStyle>
  );
};
