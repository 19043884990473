import { Global } from "@emotion/react";

import {
  DepositModalOverride,
  DropDownOverride,
  MessageCustomOverride,
  SelectDropdownOverride,
} from "./css";
import { reset } from "./reset";
import { variablesCss } from "./variables";

const GlobalStyles = () => {
  return (
    <>
      <Global
        styles={[
          reset,
          variablesCss,
          DepositModalOverride,
          MessageCustomOverride,
          DropDownOverride,
          SelectDropdownOverride,
        ]}
      />
    </>
  );
};

export default GlobalStyles;
