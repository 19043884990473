import { CurrenciesApi } from "api/currencies";
import { ResponseCode } from "constants/response";

export const getCurrencyList = async () => {
  const res = await CurrenciesApi.getCurrencyList();

  const { data, msg, status } = res.data;

  if (status === ResponseCode.SUCCESS) {
    return data;
  }
  throw new Error(msg);
};
