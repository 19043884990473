/** @jsxImportSource @emotion/react */

import { useMutation, useQuery } from "@tanstack/react-query";
import { Button, message } from "antd";
import { MemberDetailType } from "api/auth/type";
import { dailyCashBackBonusClaim, getMemberCashBackReward } from "api/cash";
import { IconCashback } from "assets/mobile/icons";
import HeaderUser from "components/mobile/HeaderUser";
import { QueryKeys } from "constants/queryKeys";
import { queryClient } from "index";
import { useEffect } from "react";

import { CashBackContainer } from "./index.style";

export const CashBack = () => {
  const username = queryClient.getQueryData<string>([QueryKeys.username]) || "";

  const { data: cashback, isLoading } = useQuery({
    queryKey: [QueryKeys.CashBackReward],
    queryFn: () => getMemberCashBackReward({ username }),
  });
  const { data: profile } = useQuery<MemberDetailType>([
    QueryKeys.MemberDetail,
  ]);
  const {
    data: claimData,
    mutate,
    isLoading: loadingClaim,
  } = useMutation({
    mutationKey: ["DailyCashBackBonusClaim"],
    mutationFn: dailyCashBackBonusClaim,
    onError: (err) => {
      message.error(err + "");
    },
    onSuccess: (data) => {
      if (data.msg) {
        message.success(data.msg + ". " + data.numberofsuccessful);
      }
    },
  });

  useEffect(() => {
    if (!profile) return;
    mutate({
      player_id: profile.player_id,
      claim: false,
      username: username as string,
    });
  }, [profile]);

  const showCashbackReward = () => {
    if (isLoading) return "Cashback 0.00";

    if (cashback?.data.length === 0) return "Cashback 0.00";

    if (cashback?.data[0].reward) return `Cashback ${cashback?.data[0].reward}`;
  };

  const handleClaim = () => {
    mutate({
      player_id: profile?.player_id as string,
      claim: true,
      username: username as string,
    });
  };
  return (
    <CashBackContainer>
      <HeaderUser title="Cashback" />
      <div className="padding">
        <div className="groupImage">
          <div className="width33">
            {/* <Image
              src={CashbackMobile}
              alt=""
              height={52}
              width={46}
              className="width1"
              preview={false}
            /> */}
            <IconCashback />
          </div>

          <p className="p1">
            {showCashbackReward()}
            {claimData?.showClaimButton && (
              <Button
                onClick={handleClaim}
                className="buttonShow"
                loading={loadingClaim}
                type="text"
              >
                <span>Claim</span>
              </Button>
            )}
          </p>
        </div>
      </div>
    </CashBackContainer>
  );
};
