/** @jsxImportSource @emotion/react */

import { Modal } from "components/common/Modal";
import { Footer } from "components/desktop/Footer";
import { Header } from "components/desktop/Header";
import { Outlet } from "react-router-dom";

import { DefaultStyle } from "./index.style";

export const DefaultLayout = () => {
  return (
    <DefaultStyle>
      <div className="header">
        <Header />
      </div>
      <main className="mainContent">
        <Outlet />
      </main>
      <Footer />
      <Modal />
    </DefaultStyle>
  );
};
