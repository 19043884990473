import { useQuery } from "@tanstack/react-query";
import { message } from "antd";
import { PATH } from "constants/path";
import { QueryKeys } from "constants/queryKeys";
import { queryClient } from "index";
import { useEffect } from "react";
import { Navigate, Outlet } from "react-router-dom";

const ProtectedRoutes = ({ children }: { children?: React.ReactNode }) => {
  const username =
    queryClient.getQueryData<string>([QueryKeys.username]) ||
    localStorage.getItem(QueryKeys.username);
  const isAuthenticated = useQuery([QueryKeys.isAuthenticated]);

  const path = PATH.Signin;

  useEffect(() => {
    if (!(isAuthenticated && username)) {
      message.warning({
        key: "error-login",
        content: "Please Login",
      });
    }
  }, [username]);

  return (
    <>
      {isAuthenticated && username ? (
        children || <Outlet />
      ) : (
        <Navigate to={path} replace />
      )}
    </>
  );
};

export default ProtectedRoutes;
