import { Theme } from "@emotion/react";

export const themeDark = {
  colors: {
    white: "#FFFFFF",
    black: "#0D1725",
    background: "#050117",
    primary: "#ffbc00",
    red: "#f00",
    gray: {
      10: "#0D1725",
      150: "#E0E0E0",
      100: "#808792",
      200: "#808792",
    },
    rgb: {
      10: "rgba(255, 255, 255, 0.15)",
      20: "rgba(255, 255, 255, 0.2)",
      30: "rgba(61, 70, 104, 0.3)",
      60: "rgba(61, 70, 104, 0.6)",
      brown: "rgba(255, 255, 255, 0.6)",
      solid: "rgba(0, 0, 0, 0.24)",
    },
    neutrals: {
      50: "#F5F5F9",
      100: "#E5E5E6",
      200: "#CACACE",
      250: "#d1ccd9",
      300: "#AFAFB5",
      400: "#95959D",
      500: "#7B7B84",
      600: "#636369",
      700: "#4A4A4F",
      800: "#313135",
      900: "#1C1C1E",
    },
    quantity: {
      green: "#01A730",
      red: "#D20404",
      blue: "#0477FD",
    },
    dark: "#D9D9D9",
    darkA: "#050116",
    darkProfile: "#26263c",
    darkCash: "#FFFFFF",
    blue: "#1877F2",
    fullWhite: "#FFFFFF",
    text: {
      link: "#1877f2",
      10: "#D9D9D9",
      20: "#2A253A",
      30: "#F5F5F9",
      40: "rgba(255, 255, 255, 0.6)",
      50: "rgba(255, 255, 255, 0.25)",
      60: "#FFFFFF",
      70: "#FFFFFF",
    },
  },
  fontSize: {
    13: "13px",
    14: "14px",
    15: "15px",
    16: "16px",
    18: "18px",
    20: "20px",
    24: "24px",
    32: "32px",
    36: "36px",
    40: "40px",
    48: "48px",
    64: "64px",
    96: "96px",
  },
} as Theme;

export const theme = {
  colors: {
    white: "#0D1725",
    black: "#FFFFFF",
    background: "#050117",
    primary: "#1877f2",
    red: "#f00",
    gray: {
      10: "#f5f5f5",
      150: "#E0E0E0",
      100: "#808792",
      200: "#2A253A",
    },
    rgb: {
      10: "rgba(255, 255, 255, 0.15)",
      20: "rgba(255, 255, 255, 0.2)",
      30: "rgba(61, 70, 104, 0.3)",
      60: "rgba(61, 70, 104, 0.6)",
      brown: "rgb(88, 59, 59)",
      solid: "rgba(255, 255, 255, 0.24)",
    },
    neutrals: {
      50: "#F5F5F9",
      100: "#E5E5E6",
      200: "#CACACE",
      250: "#d1ccd9",
      300: "#AFAFB5",
      400: "#95959D",
      500: "#7B7B84",
      600: "#636369",
      700: "#4A4A4F",
      800: "#313135",
      900: "#1C1C1E",
    },
    quantity: {
      green: "#01A730",
      red: "#D20404",
      blue: "#0477FD",
    },
    dark: "#2A253A",
    darkA: "#FFFFFF",
    blue: "#1877F2",
    darkProfile: "#FFFFFF",
    darkCash: "#26263c ",
    fullWhite: "#FFFFFF",
    text: {
      link: "#1877f2",
      10: "#2A253A",
      20: "#2A253A",
      30: "#616161",
      40: "#9e9e9e",
      50: "rgba(0, 0, 0, 0.25)",
      60: "#3e363f",
      70: "#444",
    },
  },
  fontSize: {
    13: "13px",
    14: "14px",
    15: "15px",
    16: "16px",
    18: "18px",
    20: "20px",
    24: "24px",
    32: "32px",
    36: "36px",
    40: "40px",
    48: "48px",
    64: "64px",
    96: "96px",
  },
} as Theme;

export const themeCustom = {
  colors: {
    white: "#FFFFFF",
    black: "#000000",
    background: "#050117",
    primary: "#ffbc00",
    red: "#f00",
    gray: {
      150: "#E0E0E0",
    },
    rgb: {
      10: "rgba(255, 255, 255, 0.15)",
      20: "rgba(255, 255, 255, 0.2)",
      30: "rgba(61, 70, 104, 0.3)",
      60: "rgba(61, 70, 104, 0.6)",
    },
    neutrals: {
      50: "#F5F5F9",
      100: "#E5E5E6",
      200: "#CACACE",
      250: "#d1ccd9",
      300: "#AFAFB5",
      400: "#95959D",
      500: "#7B7B84",
      600: "#636369",
      700: "#4A4A4F",
      800: "#313135",
      900: "#1C1C1E",
    },
    quantity: {
      green: "#01A730",
      red: "#D20404",
      blue: "#0477FD",
    },
    dark: "#0D1725",
  },
  fontSize: {
    13: "13px",
    14: "14px",
    15: "15px",
    16: "16px",
    18: "18px",
    20: "20px",
    24: "24px",
    32: "32px",
    36: "36px",
    40: "40px",
    48: "48px",
    64: "64px",
    96: "96px",
  },
} as Theme;
