import { css } from "@emotion/react";

export const InputCustomStyle = {
  self: css`
    &.ant-input,
    .ant-input {
      border: none !important;
      box-shadow: none !important;
    }

    &:-webkit-autofill {
      -webkit-background-clip: text;
    }

    &:focus,
    &:hover {
      border-color: none !important;
      outline: none;
      background: none;
    }
    &-disabled {
      opacity: 0.5;
    }

    &-status-error {
      border-color: var(--error-color) !important;
      &::placeholder {
        color: var(--error-color);
      }
    }
    & .ant-form-item-explain-error {
      color: var(--error-color) !important;
    }
  `,
};
