/** @jsxImportSource @emotion/react */

import { LoadingOutlined } from "@ant-design/icons";
import { css } from "@emotion/react";
import { Spin } from "antd";
type Props = {
  visible: boolean;
};
const antIcon = (
  <LoadingOutlined style={{ fontSize: 48, color: "#ffbc00" }} spin />
);
function Loading({ visible }: Props) {
  return (
    <div
      css={css`
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 9;
        display: ${visible ? "flex" : "none"};
        align-items: center;
        justify-content: center;
      `}
    >
      <Spin indicator={antIcon} size={"large"} spinning />
    </div>
  );
}

export default Loading;
