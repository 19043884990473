import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { themeDark } from "styles/theme";

export const HeaderAuthStyle = {
  wrapper: css`
    display: flex;
    align-items: center;
    height: 100%;

    padding-right: 25px;
  `,

  btnGroup: css`
    margin-right: 18px;
    display: flex;
    align-items: center;
    gap: 5px;
    height: 100%;

    button {
      height: 40px;
      padding: 0 12px;
      border: unset;

      border-radius: 6px;
      background: #03e019;

      &:last-of-type {
        background: #fe5400;
      }
      span {
        color: #fff;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
      }
    }
  `,

  user: css`
    display: flex;
    align-items: center;
  `,

  userAvt: css`
    width: 40px;
    height: 40px;
    overflow: hidden;
    border-radius: 50%;

    cursor: pointer;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  `,

  dropdownItem: css`
    color: ${themeDark.colors.white};
    font-size: 11.48px;
    font-style: normal;
    font-weight: 400;
    line-height: 18.72px;
    min-width: 100%;

    display: flex;
    align-items: center;
    gap: 11.51px;

    padding: 6px 46px 6px 5px;

    img {
      width: 13.05px;
      height: 13.05px;
    }
  `,
};

export const UserInfoStyle = styled.div`
  font-weight: 600;
  font-style: normal;
  text-align: center;

  margin-right: 11px;

  color: ${(props) => props.theme.colors.text?.[10]};
  p {
    font-size: 14px;
  }

  span {
    font-size: 10px;
  }
`;
