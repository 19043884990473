import axiosRequest from "api";
import { ResponseCode } from "constants/response";
import { payloadHelper } from "helpers/payload-helper";

import {
  ClaimPromotionPayload,
  ClaimPromotionResponse,
  MemberPromotionPayload,
  MemberPromotionResponse,
  PromotionResponse,
} from "./type";

export const getPromotion = async () => {
  const { data } = await axiosRequest.post<PromotionResponse>(
    "/",
    payloadHelper("PromotionList", "", {}),
  );
  if (data.status === ResponseCode.SUCCESS) {
    return data.data;
  }
  throw new Error(data.msg);
};

export const getMemberPromotion = async (payload: MemberPromotionPayload) => {
  const { username } = payload;
  const { data } = await axiosRequest.post<MemberPromotionResponse>(
    "/",
    payloadHelper("MemberPromotion", username, { ...payload }),
  );
  return data;
};

export const claimMemberPromotion = async (payload: ClaimPromotionPayload) => {
  const { username } = payload;
  const response = await axiosRequest.post<ClaimPromotionResponse>(
    "/",
    payloadHelper("ClaimMemberPromotion", username, { ...payload }),
  );
  return response.data;
};
