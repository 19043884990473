/** @jsxImportSource @emotion/react */

import { Checkbox, Modal } from "antd";
import { AnnouncementApi } from "api/announcement";
import { ReactComponent as CommentDotsIcon } from "assets/mobile/icons/comment-dots-solid.svg";
import { ReactComponent as ArrowNextIcon } from "assets/mobile/icons/return-up-next.svg";
// import Loading from "components/common/Loading";
import { ResponseCode } from "constants/response";
import { AppContext } from "contexts/app.context";
import { ModalTypeValue } from "enums/modalType";
import { handleCloseModal } from "helpers/handleModal";
import _map from "lodash/map";
import { useContext, useEffect, useState } from "react";
import { AnnouncementItemType } from "types/announcement";

import MobileNotificationDetail from "./MobileNotificationDetail";
import { ModalMobileNotificationStyle } from "./style.index";

const ModalMobileNotification = () => {
  const { state, dispatch } = useContext(AppContext);

  const [notificationList, setNotificationList] = useState<
    AnnouncementItemType[] | null
  >(null);
  const [notificationSelected, setNotificationSelected] =
    useState<AnnouncementItemType | null>(null);
  // const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleSelectNotification = (notification: AnnouncementItemType) => {
    setNotificationSelected(notification);
  };

  const getData = async () => {
    try {
      // setIsLoading(true);
      const { data } = await AnnouncementApi.getAnnouncement({ type: 1 });
      if (data.status === ResponseCode.SUCCESS) {
        setNotificationList(data.data);
      }
    } finally {
      // setIsLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <Modal
        title="NEW NOTIFICATION"
        open={state.openModal.includes(ModalTypeValue.MOBILE_NEWS)}
        onCancel={() => handleCloseModal(state, dispatch)}
        css={ModalMobileNotificationStyle.wrapper}
        style={{
          margin: "0 auto",
        }}
        footer={
          <div css={ModalMobileNotificationStyle.close}>
            <div css={ModalMobileNotificationStyle.label}>
              <span onClick={() => handleCloseModal(state, dispatch)}>
                Close
              </span>
            </div>
            <div css={ModalMobileNotificationStyle.checkBox}>
              <Checkbox /> Do not show again
            </div>
          </div>
        }
      >
        <div css={ModalMobileNotificationStyle.mainContent}>
          <ul css={ModalMobileNotificationStyle.listNotification}>
            {_map(notificationList, (item, index) => (
              <li
                key={index}
                css={ModalMobileNotificationStyle.notificationItem}
                onClick={() => handleSelectNotification(item)}
              >
                <div css={ModalMobileNotificationStyle.itemTitleWrapper}>
                  <CommentDotsIcon className="iconCommentDot" />
                  <span css={ModalMobileNotificationStyle.itemTitle}>
                    {item.title}
                  </span>
                </div>
                <ArrowNextIcon
                  css={ModalMobileNotificationStyle.arrowNextIcon}
                />
              </li>
            ))}
          </ul>
        </div>
      </Modal>
      <MobileNotificationDetail
        data={notificationSelected}
        onBack={() => setNotificationSelected(null)}
      />
    </>
  );
};

export default ModalMobileNotification;
