/** @jsxImportSource @emotion/react */

import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import { ConfigCss } from "styles/config";
import { theme } from "styles/theme";

import { LoadingCustomStyle } from "./style.index";

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

type Props = {
  height: string;
  sizeIcon?: string;
  colorSpin?: string;
};

const LoadingCustom = ({
  height,
  sizeIcon = "46.6px",
  colorSpin = theme.colors.primary,
}: Props) => {
  return (
    <div
      css={[
        LoadingCustomStyle.wrapper({ height, sizeIcon, colorSpin }),
        ConfigCss.flexCenter,
      ]}
    >
      <Spin indicator={antIcon} />
    </div>
  );
};

export default LoadingCustom;
