/** @jsxImportSource @emotion/react */

import { yupResolver } from "@hookform/resolvers/yup";
import { useQuery } from "@tanstack/react-query";
import { Button, Form, message } from "antd";
import { AuthApi } from "api/auth";
import { IconPrev } from "assets/desktop/homepage/icons";
import { ReactComponent as DropdownIcon } from "assets/mobile/icons/icon_drop-down.svg";
import HelmetCustom from "components/common/Helmet";
import InputCustomMobile from "components/mobile/base/InputCustomMobile";
import HideFooter from "components/mobile/HideFooter";
import { COUNTRY_CODE } from "constants/common";
import { PATH } from "constants/path";
import { QueryKeys } from "constants/queryKeys";
import { ResponseCode } from "constants/response";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { ApiResponse } from "types/api";
import * as yup from "yup";

import { ForgotPasswordContainer } from "./index.style";

export interface ForgotPasswordForm {
  username?: string;
  email: string;
  mobile: string;
}

const schema = yup.object({
  // username: yup.string().trim().required("This is field"),
  email: yup.string().trim().email().required("This is field required"),
  mobile: yup.string().trim().required("This is field required"),
});

const ForgotPassword = () => {
  const navigate = useNavigate();

  const isAuthenticated = useQuery([QueryKeys.isAuthenticated]);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [messagePassword, setMessagePassword] = useState<string>("");
  const [valueMobile, setValueMobile] = useState<string>("");

  const {
    handleSubmit,
    reset,
    control,
    setValue,
    formState: { isValid },
  } = useForm<ForgotPasswordForm>({
    resolver: yupResolver(schema),
    mode: "all",
  });

  const onSubmit = async (data: ForgotPasswordForm) => {
    const username = COUNTRY_CODE + valueMobile;
    setIsLoading(true);
    const response = await AuthApi.forgotPassword(username, {
      ...data,
      mobile: COUNTRY_CODE + valueMobile,
      username,
    });

    const { msg, status } = response.data as ApiResponse;
    setIsLoading(false);

    if (status !== ResponseCode.SUCCESS) {
      message.error({
        content: msg,
      });
      return;
    }
    message.success({
      content: "Password reset successfully",
    });
    setMessagePassword(msg);
    setValueMobile("");
    reset();
  };

  useEffect(() => {
    if (!isAuthenticated.data) return;
    navigate("/");
  }, []);

  useEffect(() => {
    setValue("username", valueMobile);
  }, [valueMobile]);

  return (
    <ForgotPasswordContainer
      isValid={isValid}
      valueMobile={valueMobile}
      className="asdfasdfasdfasdf"
    >
      <HelmetCustom title="SignIn" />
      <div
        onClick={() => {
          setMessagePassword("");
          navigate(PATH.Home);
        }}
        className="iconForgot"
      >
        <IconPrev />
      </div>
      <div className="labelForgot">Forgot Password</div>

      <Form onSubmitCapture={handleSubmit(onSubmit)}>
        {messagePassword && <div className="mg10">{messagePassword}</div>}

        <div>
          <label className="labelMobile">Mobile</label>

          <div className="groupMobile">
            <div className="boxNumber">
              <p className="pt2">+{COUNTRY_CODE}</p>
              <div>
                <DropdownIcon />
              </div>
            </div>
            <div className="flex10">
              <InputCustomMobile
                control={control}
                name={"mobile"}
                value={valueMobile}
                margin_bottom="0"
                setValueProps={(value: string) => {
                  const regex = new RegExp(/^[0-9]+$/);

                  if (!regex.test(value)) {
                    if (value.length === 0 && value === "") setValueMobile("");
                    return;
                  }

                  setValueMobile(value);
                }}
              />
            </div>
          </div>
        </div>

        <div className="mb40">
          <label className="labelEmail">Email</label>
          <InputCustomMobile
            control={control}
            name={"email"}
            margin_bottom="0"
            className="inputForgot"
          />
        </div>

        <div className="groupButtonForgot">
          <Button
            className="buttonSubmitForgot"
            disabled={!isValid || !valueMobile}
            loading={isLoading}
            htmlType="submit"
          >
            <span className="spanSubmitForgot">Submit</span>
          </Button>
        </div>
      </Form>
      <HideFooter />
    </ForgotPasswordContainer>
  );
};

export default ForgotPassword;
