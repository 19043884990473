/** @jsxImportSource @emotion/react */

import HelmetCustom from "components/common/Helmet";
import GameProvider from "components/desktop/GameProvider";
import JackPot from "components/desktop/JackPot";
import ListSubGame from "components/desktop/ListSubGame";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { CrashPageStyle } from "./index.style";
import { HotCrashGame } from "./list-game";

function CrashPage() {
  const myRef = useRef(null);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const params = useParams();
  const [isLoadingBanner, setIsLoadingBanner] = useState<boolean>(false);

  useEffect(() => {
    if (params.provider_code) return;
    navigate(`${pathname}/${HotCrashGame.provider_code}`, {
      replace: true,
    });
  }, []);

  useEffect(() => {
    if (!isLoadingBanner) return;

    if (myRef.current === null) return;

    (myRef.current as any).scrollIntoView({ behavior: "smooth" });
  }, [myRef.current, isLoadingBanner]);

  return (
    <div css={CrashPageStyle.index}>
      <HelmetCustom title="Crash Game" />
      <div css={CrashPageStyle.banner}>
        <img
          src="/assets/game/slots/slot_banner.png"
          alt=""
          onLoad={() => {
            setIsLoadingBanner(true);
          }}
        />
      </div>
      <div css={CrashPageStyle.wrapper} ref={myRef}>
        <JackPot />
        <GameProvider currentCode={params?.provider_code} gameType="BG" />
        <div>
          <ListSubGame game_type="BG" provider_code={params?.provider_code} />
        </div>
      </div>
    </div>
  );
}

export default CrashPage;
