import loadable from "@loadable/component";
import App from "App";
import { PATH, userSettingPath } from "constants/path";
import { DefaultLayout } from "layouts/mobile/DefaultLayout";
import LoginAffiliatePage from "pages/common/LoginAffiliatePage";
import { CashBack } from "pages/mobile/Cashback";
import { ChangePassword } from "pages/mobile/ChangePassword";
import ForgotPassword from "pages/mobile/ForgotPassword";
import MFishingGame from "pages/mobile/GamePage/Fishing";
import InformationUser from "pages/mobile/InformationUser";
import BankEnroll from "pages/mobile/InformationUser/BankEnroll";
import BankListUser from "pages/mobile/InformationUser/BankListUser";
import EditPhoneUser from "pages/mobile/InformationUser/EditPhoneUser";
import ProfileUser from "pages/mobile/InformationUser/ProfileUser";
import Rewards from "pages/mobile/InformationUser/Rewards";
import VipDetailUser from "pages/mobile/InformationUser/VipDetailUser";
import Privacy from "pages/mobile/Privacy";
import RegisterAffiliate from "pages/mobile/RegisterAffiliateM";
import PanCardMobile from "pages/mobile/SettingsPage/PanCardPage";
import WalletPage from "pages/mobile/WalletPage";
import { RouteObject } from "react-router-dom";

import ProtectedRoutes from "./protectedRoutes";

const DetailPromotion = loadable(
  () => import("pages/mobile/InformationUser/Rewards/DetailPromotion"),
);
const HomePage = loadable(() => import("pages/mobile/HomePage"));
const InstantGame = loadable(() => import("pages/mobile/GamePage/Flight"));
const LiveCasino = loadable(() => import("pages/mobile/GamePage/LiveCasino"));
const Slot = loadable(() => import("pages/mobile/GamePage/Slot"));
const TableGame = loadable(() => import("pages/mobile/GamePage/TableGame"));
const SigninPage = loadable(() => import("pages/mobile/Signin"));
const SignupPage = loadable(() => import("pages/mobile/Signup"));
const SettingsPage = loadable(() => import("pages/mobile/SettingsPage"));

const Promotions = loadable(() => import("pages/mobile/Promotions"));
const PromotionDetail = loadable(
  () => import("pages/mobile/Promotions/PromotionDetail"),
);
const SupportPage = loadable(() => import("pages/mobile/SupportPage"));
const AboutPage = loadable(() => import("pages/mobile/AboutPage"));
// const AffiliatePage = loadable(() => import("pages/mobile/AffiliatePage"));
const DepositPage = loadable(() => import("pages/mobile/DepositPage"));
const WithdrawPage = loadable(() => import("pages/mobile/WithdrawPage"));
const TransactionHistory = loadable(
  () => import("pages/mobile/TransactionHistory"),
);
const Referral = loadable(() => import("pages/mobile/Referral"));
const ReferralProgram = loadable(() => import("pages/mobile/ReferralProgram"));
const ReferralReward = loadable(() => import("pages/mobile/ReferralReward"));
const ReferralDicrectQuery = loadable(
  () => import("pages/mobile/ReferralDicrectQuery"),
);
const ReferralPerformance = loadable(
  () => import("pages/mobile/ReferralPerformance"),
);
const NotifycationPage = loadable(() => import("pages/mobile/Notifycation"));
const BettingRecord = loadable(() => import("pages/mobile/BettingRecord"));

export const MobileRoutes: RouteObject[] = [
  {
    path: "/",
    element: <App />,
    children: [
      {
        element: <DefaultLayout />,
        children: [
          {
            path: PATH.Home,
            element: <HomePage />,
          },
          {
            path: PATH.Promotions,
            element: <Promotions />,
          },
          {
            path: PATH.Support,
            element: <SupportPage />,
          },
          {
            path: `${PATH.Promotions}/:id`,
            element: <PromotionDetail />,
          },
          {
            path: PATH.GamePage.Instant,
            element: <InstantGame />,
          },
          {
            path: PATH.GamePage.LiveCasino,
            element: <LiveCasino />,
          },
          {
            path: `${PATH.GamePage.LiveCasino}/:provider_code`,
            element: <LiveCasino />,
          },
          {
            path: PATH.GamePage.Fishing,
            element: <MFishingGame />,
          },
          {
            path: `${PATH.GamePage.Fishing}/:provider_code`,
            element: <MFishingGame />,
          },
          {
            path: PATH.GamePage.Slot,
            element: <Slot />,
          },
          {
            path: `${PATH.GamePage.Slot}/:provider_code`,
            element: <Slot />,
          },
          {
            path: PATH.GamePage.TableGames,
            element: <TableGame />,
          },
          {
            path: `${PATH.GamePage.TableGames}/:provider_code`,
            element: <TableGame />,
          },
          {
            path: PATH.Signin,
            element: <SigninPage />,
          },
          {
            path: PATH.Signup,
            element: <SignupPage />,
          },
          {
            path: userSettingPath,
            element: <SettingsPage />,
          },
          {
            path: PATH.user.panCard,
            element: <PanCardMobile />,
          },
          {
            path: PATH.ForgotPassword,
            element: <ForgotPassword />,
          },
          { path: PATH.about, element: <AboutPage /> },
          { path: PATH.privacy, element: <Privacy /> },
          {
            element: <ProtectedRoutes />,
            children: [
              {
                element: <CashBack />,
                path: PATH.user.cashback,
              },
              {
                element: <InformationUser />,
                path: PATH.user.index,
              },
              { path: PATH.user.affiliate, element: <Referral /> },
              {
                path: PATH.user.affiliateProgram,
                element: <ReferralProgram />,
              },
              {
                path: PATH.user.affiliateReward,
                element: <ReferralReward />,
              },
              {
                path: PATH.user.affiliateDirectQuery,
                element: <ReferralDicrectQuery />,
              },
              {
                path: PATH.user.affiliatePerformance,
                element: <ReferralPerformance />,
              },
              {
                element: <ProfileUser />,
                path: PATH.user.profile,
              },
              {
                element: <EditPhoneUser />,
                path: PATH.user.mobileUser,
              },
              {
                element: <BankListUser />,
                path: PATH.user.bankListUser,
              },
              {
                element: <BankEnroll />,
                path: PATH.user.bankEnrollUser,
              },
              {
                element: <VipDetailUser />,
                path: PATH.user.vipLevel,
              },
              {
                element: <NotifycationPage />,
                path: PATH.user.notification,
              },
              {
                element: <Rewards />,
                path: PATH.user.reward,
              },
              {
                element: <DetailPromotion />,
                path: PATH.user.reward + "/:id",
              },
              { path: PATH.Settings, element: <SettingsPage /> },
              { path: PATH.ChangePassword, element: <ChangePassword /> },
              { path: PATH.user.deposit, element: <DepositPage /> },
              { path: PATH.user.withdraw, element: <WithdrawPage /> },
              { path: PATH.user.transaction, element: <TransactionHistory /> },
              { path: PATH.user.bettingRecord, element: <BettingRecord /> },
              { path: PATH.user.wallet, element: <WalletPage /> },
              {
                element: <Referral />,
                path: PATH.share,
              },
            ],
          },
        ],
      },

      {
        path: PATH.agentLogin,
        element: <LoginAffiliatePage />,
      },
      {
        path: PATH.agentRegister,
        element: <RegisterAffiliate />,
      },
    ],
  },
];
