import styled from "@emotion/styled";

export const DarkModeStyle = styled.div`
  margin-left: 44px;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 11px;

  span {
    font-size: ${(props) => props.theme.fontSize[14]};
    color: ${(props) => props.theme.colors.white};
  }
`;

export const SwitchStyle = styled.div`
  &.active {
    &::before {
      transition: 0.35s;
      transform: translate(100%, -50%);
    }
  }

  cursor: pointer;

  width: 40px;
  height: 20px;

  border-radius: 16px;
  background: #fff;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.15) inset;

  position: relative;

  transition: 0.35s;

  &::before {
    content: "";
    position: absolute;
    width: 16px;
    height: 16px;
    background-color: #808792;
    border-radius: 50%;

    left: 3px;

    transition: 0.35s;
    top: 50%;
    transform: translateY(-50%);
  }
`;
