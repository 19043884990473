/** @jsxImportSource @emotion/react */
import { useAppContext } from "contexts/app.context";
import { handleLoginGame } from "helpers/handleLoginGame";
import { useNavigate } from "react-router-dom";
import { SubGameType } from "types/game";

import { SubGameListStyle } from "./index.style";

type Props = {
  games: SubGameType[];
};

function SubGameList({ games }: Props) {
  const { state, dispatch } = useAppContext();
  const navigate = useNavigate();
  return (
    <div css={SubGameListStyle.index}>
      <div css={SubGameListStyle.listGame}>
        {games.map((item, index) => {
          return (
            <div
              onClick={() => handleLoginGame(item, state, dispatch, navigate)}
              css={SubGameListStyle.item}
              key={index}
              id={`sub-game-${index}`}
            >
              <img
                src={item.picture}
                alt=""
                onError={(e) => {
                  e.currentTarget.src = "/assets/game/image-error.png";
                  const element = document.getElementById(`sub-game-${index}`);
                  if (element) {
                    element.classList.add("image-error");
                  }
                }}
              />
              <div className="box-name">
                <span>{item.name}</span>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default SubGameList;
