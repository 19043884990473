import loadable from "@loadable/component";
import { useQuery } from "@tanstack/react-query";
import { QueryKeys } from "constants/queryKeys";
import { AppContext } from "contexts/app.context";
import { ModalTypeValue } from "enums/modalType";
import { useContext } from "react";

import ModalMobileNotification from "./ModalMobileNotification";
import ModalUserDetail from "./UserProfileModal/ModalUserDetail";
import ModalUserNickName from "./UserProfileModal/ModalUserNickName";
import ModalUserPicture from "./UserProfileModal/ModalUserPicutre";
import ModalWithdrawable from "./UserProfileModal/ModalWithdrawable";

const ModalSignin = loadable(() => import("./ModalSignin"));
const ModalSignUp = loadable(() => import("./ModalSignup"));
const ModalForgetPassword = loadable(() => import("./ModalForgetPassword"));
const ModalProfile = loadable(() => import("./ModalProfile"));
const ModalInformation = loadable(() => import("./ModalInformation"));

export const Modal = () => {
  const { state } = useContext(AppContext);
  const { data: isAuthenticated } = useQuery([QueryKeys.isAuthenticated]);

  return (
    <>
      {state.openModal.includes(ModalTypeValue.LOGIN) && <ModalSignin />}
      {!isAuthenticated && <ModalSignUp />}
      <ModalForgetPassword />
      {state.openModal.localeCompare(ModalTypeValue.USER) === 0 && (
        <ModalProfile />
      )}
      <ModalUserDetail />

      <ModalUserNickName />
      <ModalUserPicture />
      <ModalInformation />
      <ModalMobileNotification />
      <ModalWithdrawable />
    </>
  );
};
