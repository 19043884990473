import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const ProviderGameStyle = {
  index: css`
    border-bottom: 1px solid #808080;
  `,
  content: css`
    display: flex;
    align-items: center;
    flex-direction: row;
    width: 100%;
    overflow: auto;
    padding-bottom: 10px;

    &::-webkit-scrollbar {
      display: none;
    }
  `,
};

export const ProviderItemStyle = styled.div`
  padding: 13px 19.45px 14.28px 18.55px;
  width: auto;
  white-space: pre;
  color: ${(props) => props.theme.colors.white};
  border-bottom: 2px solid #f44336;
  transition:
    color 0.3s,
    background-color 0.3s;

  span {
    text-align: center;
    font-size: 13px;
    font-weight: 500;
    line-height: 22.295px;
    letter-spacing: 0.217px;
  }

  &.active {
    color: #f44336;
  }
`;
