import { css } from "@emotion/react";
import { theme } from "styles/theme";

export const BankCardStyle = {
  wrapper: css`
    color: ${theme.colors.white};
    margin-bottom: 12px;
    padding: 16px;
    background: radial-gradient(
      101.49% 101.2% at 9.41% 19.29%,
      #e20021 0%,
      #bc0061 47.24%,
      #9f00a2 78.51%,
      #7e00ea 100%
    );

    border-radius: 20px;
    box-shadow:
      0px 3px 1px -2px rgba(0, 0, 0, 0.12),
      0px 2px 2px 0px rgba(0, 0, 0, 0.14),
      0px 1px 5px 0px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  `,
  icon: css`
    font-size: 20px;
    cursor: pointer;
    margin-right: 20px;
  `,
  nameBank: css`
    display: flex;
    align-items: center;
    gap: 8px;
    img {
      width: 25px;
      height: 25px;
    }
    span {
      font-size: 13px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px;
      letter-spacing: 0.1px;
    }
  `,
  nameContentBank: css`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  `,

  numberBank: css`
    padding: 8px 0;

    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: 0.25px;
  `,

  nameUser: css`
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0.1px;
  `,
};
