import {
  IconBettingRecord,
  IconMenuAboutUs,
  IconMenuAffiliate,
  IconMenuNotifications,
  IconMenuProfile,
  IconMenuProfileEnrollBank,
  IconMenuProfileNickName,
  IconMenuProfilePhone,
  IconMenuProfilePicture,
  IconMenuSettings,
  IconMenuTransactions,
  IconMenuWallet,
} from "assets/mobile/icons/profile";
import { ModalTypeValue } from "enums/modalType";

import { PATH } from "./path";

export const LIST_MENU_INFORMATION_USER = [
  {
    icon: IconMenuProfile,
    name: "Profile",
    key: "profile",
    link: PATH.user.profile,
  },
  {
    icon: IconMenuTransactions,
    name: "Transactions",
    key: "transactions",
    link: PATH.user.transaction,
  },
  {
    icon: IconBettingRecord,
    name: "Betting Record",
    key: "bettingRecord",
    link: PATH.user.bettingRecord,
  },
  {
    icon: IconMenuWallet,
    name: "Wallet",
    key: "wallet",
    link: PATH.user.wallet,
  },
  {
    icon: IconMenuNotifications,
    name: "Notifications",
    key: "notifications",
    link: PATH.user.notification,
  },
];
export const LIST_SUB_MENU_USER = [
  {
    icon: IconMenuSettings,
    name: "Settings",
    key: "settings",
    link: PATH.Settings,
  },
  {
    icon: IconMenuAffiliate,
    name: "Affiliate",
    key: "affiliate",
    link: PATH.user.affiliateProgram,
  },
  {
    icon: IconMenuAboutUs,
    name: "AboutUs",
    key: "aboutUs",
    link: PATH.about,
  },
];

export const LIST_DETAIL_USER = [
  {
    name: "Accounts Details",
    key: ModalTypeValue.USER_DETAIL,
    icon: IconMenuProfile,
  },
  {
    name: "Profile Picture",
    key: ModalTypeValue.USER_PICTURE,
    icon: IconMenuProfilePicture,
  },
  {
    name: "Mobile Number",
    icon: IconMenuProfilePhone,
    key: PATH.user.mobileUser,
  },
  {
    name: "Nick Name",
    key: ModalTypeValue.USER_NICKNAME,
    icon: IconMenuProfileNickName,
  },
  {
    name: "Enroll Bank",
    key: PATH.user.bankListUser,
    icon: IconMenuProfileEnrollBank,
  },
  {
    name: "VIP Level",
    key: PATH.user.vipLevel,
    icon: IconMenuSettings,
  },
];
