import axiosRequest from "api";
import { payloadHelper } from "helpers/payload-helper";
import { ApiResponse } from "types/api";

import {
  ListBankResponseType,
  ListPlayerBankResponseType,
  PlayerBankFormType,
  RemoveMemberBankType,
} from "./type";

export const BankApi = {
  async getListBank(username: string) {
    const response = await axiosRequest.post<ListBankResponseType>(
      "",
      payloadHelper("GetBankList", username, { username }),
    );

    return response.data;
  },
  async getListPlayerBank(username: string) {
    const response = await axiosRequest.post<ListPlayerBankResponseType>(
      "",
      payloadHelper("GetMemberBank", username, { username }),
    );

    return response.data;
  },

  async addPlayerBank(username: string, payload: PlayerBankFormType) {
    const response = await axiosRequest.post<ApiResponse>(
      "",
      payloadHelper("AddMemberBank", username, payload),
    );
    return response.data;
  },

  async removeMemberBank(username: string, payload: RemoveMemberBankType) {
    const response = await axiosRequest.post<ApiResponse>(
      "/",
      payloadHelper("RemoveMemberBank", username, payload),
    );
    return response.data;
  },
};
