import { ResponseCode } from "constants/response";

import { VipMemberDetailApi } from ".";

export const getMemberVipUser = async (username: string) => {
  const response = await VipMemberDetailApi.VipMemberDetail(username);

  if (response.status === ResponseCode.SUCCESS) {
    return response.data;
  }
  throw new Error(response.msg);
};
