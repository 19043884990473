/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Button, message } from "antd";
import { claimMemberPromotion, getMemberPromotion } from "api/promotion";
import { ClaimPromotionPayload } from "api/promotion/type";
import { TypePromotionItem } from "constants/list-promotion";
import { PATH } from "constants/path";
import { QueryKeys } from "constants/queryKeys";
import { queryClient } from "index";
import { getWithdrawalForm } from "query/withdraw/queryFnsWithdraw";
import { useNavigate } from "react-router-dom";
import { ConfigCss } from "styles/config";

interface Props {
  dataPromotionMemberList: TypePromotionItem[];
  runningPromotion?: number;
}

export const ListPromotionNews = (props: Props) => {
  const navigate = useNavigate();
  const { dataPromotionMemberList, runningPromotion } = props;

  const username = queryClient.getQueryData<string>([QueryKeys.username]) || "";

  const { refetch } = useQuery({
    queryKey: [QueryKeys.MemberPromotion],
    queryFn: () => getMemberPromotion({ username }),
  });

  const { refetch: refetchWithDrawForm, data: withDrawForm } = useQuery({
    queryKey: [QueryKeys.WithDrawForm],
    queryFn: () => getWithdrawalForm(username || ""),
    enabled: !!username,
  });
  const pending_rollover = withDrawForm?.pending_rollover;
  const detailRollover = pending_rollover ? pending_rollover.detail[0] : null;
  const isRollover =
    detailRollover?.achieve_amount &&
    parseFloat(detailRollover.achieve_amount) > 0
      ? true
      : false;

  const isClaim = runningPromotion === 0 ? false : isRollover;

  const claimMemberMutation = useMutation({
    mutationFn: (payload: ClaimPromotionPayload) =>
      claimMemberPromotion(payload),
    onSuccess(data) {
      message.success({
        key: "claim-promotion",
        content: data.msg,
      });
      refetch();
      refetchWithDrawForm();
    },
    onError(error) {
      message.error({
        key: "claim-promotion",
        content: error + ``,
      });
    },
  });

  return (
    <>
      {isRollover ? (
        <div
          css={css`
            display: flex;
            align-items: center;
            justify-content: center;
            padding-bottom: 0.5rem;
            p {
              font-size: 13px;
              color: red;
            }
          `}
        >
          <p>
            Please complete current mission before you can claim reward that
            need turnover
          </p>
        </div>
      ) : null}
      <div
        css={css`
          display: flex;
          flex-direction: column;
        `}
      >
        {dataPromotionMemberList.map((item) => (
          <div
            css={css`
              position: relative;
              overflow: hidden;
              padding-bottom: 15px;
              img {
                width: 100%;
                height: 100%;
                border-radius: 10px;
                object-fit: cover;
              }
            `}
            key={item.id || item.promotion_id}
          >
            <img
              src={item.mobile}
              onClick={() =>
                navigate(PATH.user.reward + "/" + item.promotion_id)
              }
              alt=""
              css={css`
                box-shadow: 0 15px 8px -9px #82a3cf;
              `}
            />
            <div
              css={[
                css`
                  padding: 0 25px;
                  width: 100%;
                  position: absolute;
                  gap: 10px;
                  bottom: 35px;

                  button {
                    width: 100%;
                    height: 36px;

                    background: gold !important;
                    border-radius: 5px;
                    &:disabled {
                      opacity: 0.6 !important;
                      background: white !important;
                      border: unset !important;
                      span {
                        color: #9e9e9e;
                      }
                    }

                    span,
                    p {
                      font-weight: 700;
                      color: black;
                      font-size: 12px;
                    }
                    &:nth-of-type(2) {
                      background: 0 0 !important;
                      background-color: #f3ebf3 !important;
                      border: 0.1vw solid #fff;

                      cursor: pointer;
                    }
                  }
                `,
                ConfigCss.flexCenter,
              ]}
            >
              <Button
                onClick={() => {
                  claimMemberMutation.mutate({
                    username,
                    id: Number(item.promotion_id),
                  });
                }}
                type="primary"
                disabled={item.disable || isClaim}
                loading={claimMemberMutation.isLoading}
              >
                {item.title}
              </Button>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};
