/** @jsxImportSource @emotion/react */

import { Form } from "antd";
import { ValidateStatus } from "antd/es/form/FormItem";
import Input, { PasswordProps } from "antd/es/input";
import { Control, Controller, FieldValues } from "react-hook-form";

import { InputPasswordStyle } from "./index.style";

export interface InputPasswordProps extends PasswordProps {
  name?: string;
  control?: Control<FieldValues | any>;
  defaultValue?: any;
  error?: string;
  validate_status?: ValidateStatus;
}

const InputPassword = (props: InputPasswordProps) => {
  const { name = "", control, defaultValue, error, validate_status } = props;

  return (
    <>
      {control ? (
        <Form.Item
          help={error}
          validateStatus={validate_status}
          css={InputPasswordStyle.self}
          className="input-password"
        >
          <Controller
            name={name}
            control={control}
            render={({ field: { onChange, value } }) => (
              <Input.Password value={value} onChange={onChange} {...props} />
            )}
          />
        </Form.Item>
      ) : (
        <Input.Password
          css={InputPasswordStyle.self}
          defaultValue={defaultValue}
          {...props}
        />
      )}
    </>
  );
};

export default InputPassword;
