/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import { useEffect, useState } from "react";

export const Sticky = () => {
  const [isShowScrollTop, setIsShowScrollTop] = useState<boolean>(false);

  const handleScrollVisiblity = () => {
    if (window.scrollY > 100) {
      setIsShowScrollTop(true);
      return;
    }
    setIsShowScrollTop(false);
  };

  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScrollVisiblity);

    return () => {
      window.removeEventListener("scroll", handleScrollVisiblity);
    };
  }, []);

  return (
    <>
      {isShowScrollTop ? (
        <div
          css={css`
            cursor: pointer;
            position: fixed;
            bottom: 6vw;
            right: 2vw;
            background-color: #ffbc00;
            border-radius: 50%;
            height: 3vw;
            width: 3vw;
            z-index: 9999;
          `}
          onClick={() => {
            handleScrollToTop();
          }}
        >
          <div
            css={css`
              display: flex;
              justify-content: center;
              align-items: center;
              height: 100%;
            `}
          >
            <ChevronUp />
          </div>
        </div>
      ) : (
        <></>
      )}

      <div
        css={css`
          cursor: pointer;
          position: fixed;
          bottom: 2vw;
          right: 2vw;
          background-color: #ffbc00;
          border-radius: 50%;
          height: 3vw;
          width: 3vw;
          z-index: 9999;
        `}
      >
        <div
          css={css`
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            img {
              object-fit: contain;
              height: 3vw;
              width: 3vw;
            }
          `}
        >
          <img alt="" src="/assets/app/messageIcon.png" />
        </div>
      </div>
    </>
  );
};

export const ChevronUp = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 25 25"
      fill="none"
      style={{
        rotate: "180deg",
      }}
    >
      <path d="M18.5 9.5L12.5 15.5L6.5 9.5" stroke="white" strokeWidth="2" />
    </svg>
  );
};
