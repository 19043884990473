import { message } from "antd";
import { gameApi } from "api/game";
import axios, { CancelTokenSource } from "axios";
import { PATH } from "constants/path";
import { ResponseCode } from "constants/response";
import { AppState, ContextActionType } from "contexts/app.context";
import { ModalTypeValue } from "enums/modalType";
import { Dispatch } from "react";
import { NavigateFunction } from "react-router-dom";
import { isMobile } from "router";
import { ContextAction } from "types";
import { ItemGame, SubGameType } from "types/game";

import { handleOpenModal } from "./handleModal";

let source: CancelTokenSource;
export const handleLoginGame = async (
  dataLogin: SubGameType,
  state: AppState,
  dispatch: any,
  navigate: NavigateFunction,
) => {
  const username = localStorage.getItem("username");
  if (!username) {
    if (isMobile) {
      navigate(PATH.Signin);
      message.warning("Please login");
      return;
    }
    handleOpenModal(state, dispatch, ModalTypeValue.LOGIN);
    return;
  }

  message.loading({
    key: "Game",
    content: "Game is loading...",
    duration: 20,
  });

  if (source) {
    source.cancel("Operation canceled due to new request.");
  }
  source = axios.CancelToken.source();
  if (isMobile) {
    try {
      const response = await gameApi.loginGame(username, {
        username,
        game_type: dataLogin.type,
        game_code: dataLogin.game_code,
        provider: dataLogin.provider_code,
        source,
      });
      const dataLoginGame = response.data;
      if (dataLoginGame.status !== ResponseCode.SUCCESS) {
        message.destroy();
        message.error({
          key: "Game",
          content: "The game is under maintenance, please try again later!",
        });
        return;
      }
      message.destroy();
      message.success({
        key: "Game",
        content: "Login game is success!",
      });
      window.location.href = dataLoginGame.url;
    } catch (error) {
      if (
        (error as { message: string }).message.includes(
          "Operation canceled due to new request.",
        )
      ) {
        return;
      }
      message.destroy();
      message.error({
        key: "Game",
        content: "Server is under maintenance, please try again later!",
      });
    }
    return;
  }
  try {
    const openBlank = window.open(
      "",
      "LoginGame",
      `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,
      width=1000,height=600,left=200,top=-1000`,
    );
    if (!openBlank) return;

    const loaderElement = openBlank.document.createElement("div");
    const style = openBlank.document.createElement("style");

    loaderElement.className = "loader";

    style.textContent = `
    .loader { height:100%;width:100%;display:flex;justify-content:center;align-items:center;position:fixed;top:0;left:0;background:rgba(0, 0, 0, 0.5);z-index:9999} 
    .loader::after{content:"";width:75px;height:75px;border:15px solid #dddddd;border-top-color:#6bb0c8;border-radius:50%;animation:loading 2s ease infinite} 
    @keyframes loading {
      from { transform: rotate(0turn)}
      to { transform:rotate(1turn)}
    }
  `;

    openBlank.document.body.appendChild(loaderElement);
    openBlank.document.head.appendChild(style);

    try {
      const response = await gameApi.loginGame(username, {
        username,
        game_type: dataLogin.type,
        game_code: dataLogin.game_code,
        provider: dataLogin.provider_code,
        source,
      });

      const dataLoginGame = response.data;

      if (dataLoginGame.status !== ResponseCode.SUCCESS) {
        message.error({
          key: "Game",
          content: "The game is under maintenance, please try again later!",
        });
        openBlank?.close();
        return;
      }

      message.success({
        key: "Game",
        content: "Login game is success!",
      });

      (openBlank?.location as { href: string }).href = dataLoginGame.url;
    } catch (error) {
      if (
        (error as { message: string }).message.includes(
          "Operation canceled due to new request.",
        )
      ) {
        return;
      }

      message.error({
        key: "Game",
        content: "Server is under maintenance, please try again later!",
      });
    }
  } catch (error) {
    const openBlank = window.open("", "LoginGame");
    if (!openBlank) return;
    openBlank.blur();
    openBlank.close();
    const openGameBlank = window.open(
      "",
      "LoginGame",
      `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,
      width=1000,height=600,left=200,top=-1000`,
    );
    if (!openGameBlank) return;
    const loaderElement = openGameBlank.document.createElement("div");
    const style = openGameBlank.document.createElement("style");
    loaderElement.className = "loader";
    style.textContent = `
    .loader { height:100%;width:100%;display:flex;justify-content:center;align-items:center;position:fixed;top:0;left:0;background:rgba(0, 0, 0, 0.5);z-index:9999} 
    .loader::after{content:"";width:75px;height:75px;border:15px solid #dddddd;border-top-color:#6bb0c8;border-radius:50%;animation:loading 2s ease infinite} 
    @keyframes loading {
      from { transform: rotate(0turn)}
      to { transform:rotate(1turn)}
    }
    `;

    openGameBlank.document.body.appendChild(loaderElement);
    openGameBlank.document.head.appendChild(style);

    try {
      const response = await gameApi.loginGame(username, {
        username,
        game_type: dataLogin.type,
        game_code: dataLogin.game_code,
        provider: dataLogin.provider_code,
        source,
      });

      const dataLoginGame = response.data;

      if (dataLoginGame.status !== ResponseCode.SUCCESS) {
        message.error({
          key: "Game",
          content: "The game is under maintenance, please try again later!",
        });
        openGameBlank?.close();
        return;
      }
      message.success({
        key: "Game",
        content: "Login game is success!",
      });

      (openGameBlank?.location as { href: string }).href = dataLoginGame.url;
    } catch (error) {
      if (
        (error as { message: string }).message.includes(
          "Operation canceled due to new request.",
        )
      ) {
        return;
      }

      message.error({
        key: "Game",
        content: "Server is under maintenance, please try again later!",
      });
    }
  }
};

export const handleLoginGameProvider = (
  dataLogin: ItemGame,
  state: AppState,
  dispatch: Dispatch<ContextAction<ContextActionType, AppState>> | null,
  navigate: NavigateFunction,
) => {
  const gameType: SubGameType = {
    picture: dataLogin.image,
    provider_code: dataLogin.provider_code,
    type: dataLogin.game_type,
    provider_name: dataLogin.provider_name,
    name: dataLogin.provider_name,
    game_code: dataLogin.game_code,
    featured: "",
    new_arrive: "",
  };
  handleLoginGame(gameType, state, dispatch, navigate);
};
