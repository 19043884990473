/** @jsxImportSource @emotion/react */

import HelmetCustom from "components/common/Helmet";
import { useAppContext } from "contexts/app.context";
import { handleLoginGameProvider } from "helpers/handleLoginGame";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import { LIST_GAME_ESPORT } from "./list-game";
import { ESportGameStyle } from "./style.index";

const ESportGame = () => {
  const navigate = useNavigate();
  const { state, dispatch } = useAppContext();

  const myRef = useRef(null);
  const [isLoadingBanner, setIsLoadingBanner] = useState<boolean>(false);

  useEffect(() => {
    if (!isLoadingBanner) return;

    if (myRef.current === null) return;

    (myRef.current as any).scrollIntoView({ behavior: "smooth" });
  }, [myRef.current, isLoadingBanner]);

  return (
    <div css={ESportGameStyle.index}>
      <HelmetCustom title="E-Sport" />
      <div css={ESportGameStyle.banner}>
        <img
          src="/assets/game/sports/sport-banner.png"
          alt=""
          onLoad={() => {
            setIsLoadingBanner(true);
          }}
        />
      </div>
      <div css={ESportGameStyle.containerGame}>
        <div css={ESportGameStyle.listGame} ref={myRef}>
          {LIST_GAME_ESPORT.map((item, index) => {
            return (
              <div
                onClick={() =>
                  handleLoginGameProvider(item, state, dispatch, navigate)
                }
                key={index}
                css={ESportGameStyle.item}
              >
                <img src={item.image} alt="" />
                <button className="button">
                  <span>Play Now</span>
                </button>
                <div className="overlay" />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ESportGame;
