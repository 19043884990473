/** @jsxImportSource @emotion/react */

import { Modal } from "components/common/Modal";
import { Footer } from "components/mobile/ui/Footer";
import { Outlet } from "react-router-dom";

import { DefaultLayoutContainer } from "./index.style";

export const DefaultLayout = () => {
  return (
    <DefaultLayoutContainer>
      <main className="main">
        <Outlet />
      </main>
      <Footer />
      <Modal />
    </DefaultLayoutContainer>
  );
};
