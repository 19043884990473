import { useQuery } from "@tanstack/react-query";
import { QueryKeys } from "constants/queryKeys";

import { getCurrencyList } from "./queryFnsCurrencies";

export const useQueryCurrencies = () =>
  useQuery({
    queryKey: [QueryKeys.Currency],
    queryFn: () => getCurrencyList(),
  });
