import { css } from "@emotion/react";

export const CrashPageStyle = {
  index: css`
    background: #050117;
  `,
  banner: css`
    width: 100%;

    img {
      width: 100%;
    }
  `,
  wrapper: css`
    max-width: 1700px;
    padding: 0 2vw;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1vw 3vw;
    margin: 0 auto;
  `,
};
