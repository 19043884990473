import styled from "@emotion/styled";

export const PrivacyContainer = styled.div`
  .wrapperPrivacy {
    width: auto;
    padding: 0 10px;
    margin: 0 auto;
    line-height: 1.7;
    color: ${(props) => props.theme.colors.white};

    font-family: unset !important;
    h1 {
      text-align: center;
    }
    h2 {
      margin-top: 20px;
    }
    p {
      font-size: 16px;
      /* color: dimgrey; */
      color: ${(props) => props.theme.colors.dark};
    }
    ul {
      display: block;
      list-style-type: decimal;

      /* color: #696969; */
      color: ${(props) => props.theme.colors.dark};
      margin-left: 15px;
    }
  }
`;
