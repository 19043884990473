/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import { Image } from "antd";

export const LoadingMobile = () => {
  return (
    <div
      css={css`
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100vh;
      `}
    >
      <Image
        src="/mobile/loading.gif"
        alt=""
        height={200}
        width={200}
        css={css`
          object-fit: contain;
        `}
        preview={false}
      />
    </div>
  );
};
