import styled from "@emotion/styled";
export const RegisterAffiliateMStyle = styled.div`
  background: ${(props) => props.theme.colors.black};

  .header {
    background: linear-gradient(45deg, #a05ae2 0%, #8965ec 30%, #4c1aaa 100%);
    padding-bottom: 18.75px;
    h2 {
      font-family: "Poppins", sans-serif;
      color: #fff;
      font-size: 40px;
      text-align: center;
    }
    p {
      color: #fff;
      font-size: 12px;
      text-align: center;
      font-family: "Poppins", sans-serif;
    }
    span {
      display: block;
      width: 100%;
      color: #fff;
      font-size: 14px;
      margin-top: 20px;
      text-align: center;
      text-decoration: underline;
      font-family: "Poppins", sans-serif;
    }
  }
  .mainContent {
    border-radius: 5px;
    height: 100%;
    width: 100%;
    padding: 10% 10% 20% 10%;
    background: ${(props) => props.theme.colors.black};

    /* background-color: #fff; */

    h1 {
      color: ${(props) => props.theme.colors.text?.[70]};
      display: flex;
      font-size: 8vw;
      font-weight: 700;
      justify-content: center;
      margin-bottom: 2vh;
      flex-wrap: wrap;
    }
    p {
      font-size: 4vw;
      margin-top: 20px;
      width: 100%;
      color: ${(props) => props.theme.colors.text?.[60]};
      text-align: center;
      font-family: "Poppins", sans-serif;
      margin-bottom: 15px;
    }
  }
  .form {
    .ant-input {
      &:last-of-type {
        height: 37.91px !important;
      }
      height: 36.91px !important;
      border-color: rgba(0, 0, 0, 0.24) !important;
      border-radius: 5px !important;
      margin: 5px 0 15px 0;

      padding: 0 12px !important;
      &-affix-wrapper {
        overflow: hidden;
        height: 40px !important;
        border-color: rgba(0, 0, 0, 0.24) !important;
        border-radius: 5px !important;

        margin: 5px 0 15px 0;
      }
    }
    label {
      color: ${(props) => props.theme.colors.text?.[60]} !important;
      font-family: "Poppins", sans-serif;
      font-size: 1.8vh;
      font-style: normal;
      font-weight: 400;
      line-height: 23px;

      span {
        color: #6f6af9;
      }
    }
    .ant-input {
      &:last-of-type {
        height: 37.91px !important;
      }
      height: 36.91px !important;
      border-color: rgba(0, 0, 0, 0.24) !important;
      border-radius: 5px !important;
      margin: 5px 0 15px 0;

      padding: 0 12px !important;
      &-affix-wrapper {
        overflow: hidden;
        height: 40px !important;
        border-color: rgba(0, 0, 0, 0.24) !important;
        border-radius: 5px !important;

        margin: 5px 0 15px 0;
      }
    }
    label {
      color: #3e363f;
      font-family: "Poppins", sans-serif;
      font-size: 1.8vh;
      font-style: normal;
      font-weight: 400;
      line-height: 23px;

      span {
        color: #6f6af9;
      }
    }
  }

  .btnGroup {
    gap: 10px;
    margin-top: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    button {
      width: 30%;
      height: 42px;
      border-radius: 30px;
      span {
        color: #fff;
        text-align: center;
        font-family: "Poppins", sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 1.715em;
      }
      &:first-of-type {
        background: #6f6af9;
        &:hover {
          background-color: #673ab7 !important;
        }
      }
      &:nth-of-type(2) {
        border-color: #6f6af9 !important;
        &:hover {
          background-color: #673ab7;
          span {
            color: white;
          }
        }
        span {
          color: #6f6af9;
        }
      }
    }
  }
`;
