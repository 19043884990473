import { css } from "@emotion/react";

export const InputFileStyle = {
  self: css`
    & .ant-upload {
      width: 100%;
      cursor: pointer;
      &-disabled {
        opacity: 0.8;
      }
    }
  `,

  content: css``,
  contentError: css`
    /* border: 1px solid var(--error-color); */
  `,

  placeholder: () => css`
    color: white;
  `,
  placeholderError: css`
    color: var(--error-color);
  `,

  buttonRepeat: css`
    align-items: center;
    background-color: #ffbc00;
    border-radius: 5px;
    height: 36.7px;
    width: 6.8vw;

    span {
      font-size: 0.8vw;
      text-align: center;
    }
  `,

  fileName: css`
    display: inline-block;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: inherit;

    span {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  `,

  iconUpload: css`
    svg,
    path {
      fill: white;
      vertical-align: middle;
    }
  `,
  iconUploadError: css`
    svg,
    path {
      fill: var(--error-color);
    }
  `,
};
