export const isMobile = /iPhone|iPad|iPod|Android|tablet/i.test(
  navigator.userAgent,
);
export const FORMAT_DATE = "DD/MM/YYYY hh:mm";
export const FORMAT_DATE_TRANSACTION = "MM-DD-YYYY";

export const DOMAIN = "https://iplay88.asia/";

export const URL_DOWNLOAD_ANDROID = "https://iplay88.asia/app/iPLAY88.apk";

export const COUNTRY_CODE = "61";
