import styled from "@emotion/styled";

export const FooterContainer = styled.div`
  position: fixed;
  border-radius: 27px 27px 0 0;
  box-shadow: 0 -8px 5px -1px hsla(0, 15%, 82%, 0.555);
  overflow: hidden;
  padding-top: 0;
  z-index: 999;
  bottom: 0;
  left: 0;
  right: 0;
  background: white;
  /* background: ${(props) => props.theme.colors.black}; */
  .homeContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding-bottom: 12%;
  }
`;
/* menuItem: (isPromotion: boolean) => css`
    padding: 24px 0;
    display: flex;
    align-items: center;
    gap: 8px;
    color: ${isPromotion ? "#B98D1B" : theme.colors.black};
    position: relative;
  `, */
