export enum ModalTypeValue {
  LOGIN = "LOGIN",
  REGISTER = "REGISTER",
  FORGET_PASSWORD = "FORGET_PASSWORD",
  CLOSE = "",
  BANK = "Bank",
  ANNOUNCEMENT = "Announcement",
  USER = "USER",

  USER_DETAIL = "USER_DETAIL",
  USER_PICTURE = "USER_PICTURE",
  USER_NICKNAME = "USER_NICKNAME",
  MOBILE_NEWS = "MOBILE_NEWS",
  WITHDRAWABLE = "WITHDRAWABLE",

  PROMOTION_DETAIL = "PROMOTION_DETAIL",
  DETAILS_NOTIFICATION = "DETAILS_NOTIFICATION",
}
export enum ContentModalTypeValue {
  DETAIL = "DETAIL",
  CHANGE_PASSWORD = "CHANGE_PASSWORD",
  CLOSE = "",
}
