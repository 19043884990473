/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import { Form, Input, InputProps } from "antd";
import { ValidateStatus } from "antd/es/form/FormItem";
import { ReactComponent as EyeHiddenIcon } from "assets/mobile/icons/eye-hidden.svg";
import { ReactComponent as EyeShowIcon } from "assets/mobile/icons/eye-show.svg";
import { useState } from "react";
import { Control, Controller, FieldValues } from "react-hook-form";

import { InputCustomStyle } from "./index.style";
export interface InputCustomType extends InputProps {
  margin_bottom?: string;
  name?: string;
  control?: Control<FieldValues | any>;
  errors?: string;
  validate_status?: ValidateStatus;
  defaultValue?: string | number;
  width?: string;
  label?: string;
}

const InputPasswordMobile = (props: InputCustomType) => {
  const {
    name = "",
    control,
    margin_bottom,
    errors,
    validate_status,
    defaultValue,
    width,
    label,
  } = props;

  const [isShowPassword, setIsShowPassword] = useState<boolean>(false);

  const handleShowPassword = () => {
    setIsShowPassword(!isShowPassword);
  };

  return (
    <>
      {control ? (
        <Form.Item
          css={InputCustomStyle.self}
          label={
            label ? (
              <p style={{ fontSize: "13px", color: "#00d8a0" }}>{label}</p>
            ) : null
          }
          help={errors}
          validateStatus={validate_status}
          className="input-custom"
          style={{
            marginBottom: margin_bottom ? margin_bottom : "20px",
            width: width ? width : "100%",
            position: "relative",
          }}
          labelCol={{ span: 24 }}
        >
          <div
            css={css`
              width: 100%;
              height: 40px;
              border: 1px solid #c9c9c9;
              display: flex;
              align-items: center;
              padding: 5px 10px;
              font-size: 14px;
              background-color: white;
              border-radius: 4px;
              color: black;
              outline: none;
              box-shadow: none;
              font-weight: 400;
              transition: all 0.3s ease;

              :focus,
              :hover {
                border: 1px solid #00d8a0;
              }
            `}
          >
            <Controller
              name={name}
              defaultValue={defaultValue}
              control={control}
              render={({ field: { onChange, value } }) => {
                return (
                  <Input
                    type={isShowPassword ? "text" : "password"}
                    onChange={onChange}
                    value={value}
                    {...props}
                  />
                );
              }}
            />
            <div
              css={css`
                padding-right: 2px;
                position: absolute;
                right: 10px;
                top: 8px;
                height: 40px;
              `}
            >
              {isShowPassword ? (
                <EyeShowIcon
                  height={24}
                  width={24}
                  onClick={handleShowPassword}
                />
              ) : (
                <EyeHiddenIcon
                  height={24}
                  width={24}
                  onClick={handleShowPassword}
                />
              )}
            </div>
          </div>
        </Form.Item>
      ) : (
        <Input {...props} css={InputCustomStyle.self} />
      )}
    </>
  );
};

export default InputPasswordMobile;
