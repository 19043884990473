import {
  IconCashBackUser,
  IconDepositUser,
  IconRewardUser,
  IconWithdrawUser,
} from "assets/mobile/images/profile";

import { PATH } from "./path";

type TypeListFuncInfoUser = {
  name: string;
  link: string;
  key: number;
  icon: string;
};

export const LIST_FUNC_INFO_USER: TypeListFuncInfoUser[] = [
  {
    name: "Deposit",
    link: PATH.user.deposit,
    key: 1,
    icon: IconDepositUser,
  },
  {
    name: "Withdraw",
    link: PATH.user.withdraw,
    key: 2,
    icon: IconWithdrawUser,
  },
  {
    name: "Rewards",
    link: PATH.user.reward,
    key: 3,
    icon: IconRewardUser,
  },
  {
    name: "CashBack",
    link: PATH.user.cashback,
    key: 4,
    icon: IconCashBackUser,
  },
];
