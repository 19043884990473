import { useQuery } from "@tanstack/react-query";
import { UserApi } from "api/user";
import { AxiosError } from "axios";
import { QueryKeys } from "constants/queryKeys";
import { ResponseCode } from "constants/response";
import { queryClient } from "index";

import { getMemberBalanceQueryFn } from "./queryFnsUser";

export const useGetMemberBalance = (username: string) =>
  useQuery({
    queryKey: [QueryKeys.MemberBalance, username],
    queryFn: () => getMemberBalanceQueryFn(username),
    staleTime: Infinity,
    enabled: !!username,
    refetchInterval: username ? 1000 * 30 : false,
  });

export const useGetProfile = (username: string) =>
  useQuery(
    [QueryKeys.MemberDetail],
    async () => {
      const token = localStorage.getItem("token");
      if (!username) {
        queryClient.setQueryData([QueryKeys.isAuthenticated], false);

        return new AxiosError("username invalid");
      }
      const response = await UserApi.getProfile({ username });
      if (response.status !== ResponseCode.SUCCESS) {
        return new AxiosError("username invalid");
      }
      queryClient.setQueryData([QueryKeys.isAuthenticated], true);
      queryClient.setQueryData([QueryKeys.username], username);
      queryClient.setQueryData([QueryKeys.token], token);
      return response.data;
    },
    {
      staleTime: Infinity,
    },
  );
