/** @jsxImportSource @emotion/react */

import { Button } from "antd";
import InputCustom from "components/common/InputCustom";
import InputPassword from "components/common/InputPassword";
import { PATH } from "constants/path";
import { useScrollTop } from "hooks/useScrollTop";
import { useNavigate } from "react-router-dom";
import { ConfigCss } from "styles/config";

import { RegisterAffiliateStyle } from "./style.index";

const RegisterAffiliatePage = () => {
  useScrollTop();
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate(PATH.agentLogin);
  };
  return (
    <div css={RegisterAffiliateStyle.wrapper}>
      <div css={RegisterAffiliateStyle.textBox}>
        <h1>Join</h1>
        <p>
          Most authoritative online events group. <br />
          You call it, we do it.
        </p>

        <span>Register Now!</span>
      </div>
      <div css={RegisterAffiliateStyle.mainContent}>
        <div css={RegisterAffiliateStyle.formGroup}>
          <h3>Registration</h3>

          <div css={RegisterAffiliateStyle.form}>
            <label htmlFor="">
              Full Name <span> *</span>
            </label>
            <InputCustom />
            <label htmlFor="">
              Email <span> *</span>
            </label>
            <InputCustom />
            <label htmlFor="">
              Username <span> *</span>
            </label>
            <InputCustom />
            <label htmlFor="">
              Password <span> *</span>
            </label>
            <InputPassword />
            <label htmlFor="">Whatsapp</label>
            <InputCustom />
            <label htmlFor="">Telegram</label>
            <InputCustom />
            <label htmlFor="">Skype</label>
            <InputCustom />
          </div>

          <div css={[RegisterAffiliateStyle.btnGroup, ConfigCss.flexCenter]}>
            <Button type="primary">Register</Button>
            <Button onClick={() => handleNavigate()}>Back</Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegisterAffiliatePage;
