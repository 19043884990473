/** @jsxImportSource @emotion/react */

import { ReactComponent as ReplyIcon } from "assets/mobile/icons/reply-solid.svg";
import { AnnouncementItemType } from "types/announcement";

import { MobileNotificationDetailStyle } from "./index.style";

interface MobileNotificationDetailProps {
  data: AnnouncementItemType | null;
  onBack?: () => void;
}

const MobileNotificationDetail = ({
  data,
  onBack,
}: MobileNotificationDetailProps) => {
  if (!data) {
    return <></>;
  }

  const { title, content } = data;

  return (
    <div css={MobileNotificationDetailStyle.self}>
      <div css={MobileNotificationDetailStyle.header}>
        <ReplyIcon
          css={MobileNotificationDetailStyle.replyIcon}
          onClick={() => onBack && onBack()}
        />
        <h2 css={MobileNotificationDetailStyle.title}>{title}</h2>
      </div>
      {!!content && (
        <div
          css={MobileNotificationDetailStyle.content}
          dangerouslySetInnerHTML={{
            __html: content,
          }}
        ></div>
      )}
    </div>
  );
};

export default MobileNotificationDetail;
