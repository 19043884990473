import { css } from "@emotion/react";

export const ESportGameStyle = {
  index: css`
    /* background: #050117; */
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  `,
  banner: css`
    width: 100%;

    img {
      width: 100%;
    }
  `,
  containerGame: css`
    max-width: 1700px;
    padding: 0 2vw;
    width: 100%;
  `,
  listGame: css`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding: 1.5vw 7%;
    gap: 0.75vw;
  `,
  item: css`
    cursor: pointer;
    display: inline-block;
    overflow: hidden;
    position: relative;
    position: relative;
    overflow: hidden;

    &:hover {
      .overlay {
        display: block;
      }

      .button {
        background: url("/assets/game/sports/hover_bg-button.png");
      }

      img {
        transform: scale(1.02);
      }
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: 50% 50%;
      transition: all 0.15s ease-in;
    }

    .button {
      position: absolute;
      bottom: 0px;
      left: 0;
      width: 100%;
      padding: 0.7vw;
      background: rgba(0, 0, 0, 0.5);
      border: 0;
      cursor: pointer;
      z-index: 3;

      span {
        color: #fff;
        text-align: center;
        font-size: 0.8vw;
        font-weight: 700;
      }
    }

    .overlay {
      display: none;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.2);
      z-index: 2;
    }
  `,
};
