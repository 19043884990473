/** @jsxImportSource @emotion/react */

import { Button, Dropdown, MenuProps } from "antd";
import { AvatarUser } from "assets/common/images";
import * as DropdownIcon from "assets/desktop/dropdown";
import LoadingCustom from "components/common/LoadingCustom";
import { QueryKeys } from "constants/queryKeys";
import { AppContext } from "contexts/app.context";
import { ModalTypeValue } from "enums/modalType";
import { EnumItemMenuUserModal } from "enums/user";
import { handleLogout } from "helpers/handleLogout";
import {
  handleCloseModal,
  handleKeyModal,
  handleOpenKeyItemModal,
  handleOpenModal,
} from "helpers/handleModal";
import { queryClient } from "index";
import { useGetMemberBalance, useGetProfile } from "query/users/queryHookUser";
import { getWithdrawalForm } from "query/withdraw/queryFnsWithdraw";
import { useContext } from "react";
import { ProfileType } from "types/user";

import { HeaderAuthStyle, UserInfoStyle } from "./style.index";

const HeaderAuth = () => {
  const username = queryClient.getQueryData<string>([QueryKeys.username]);

  const { state, dispatch } = useContext(AppContext);

  const { data: dataProfile, isLoading: isLoadingProfile } = useGetProfile(
    username || "",
  );

  const { data: balanceMember, isLoading: isLoadingBalance } =
    useGetMemberBalance(username || "");

  const handleLogoutEvent = () => {
    handleCloseModal(state, dispatch);
    handleLogout(dispatch, state);
  };

  const items: MenuProps["items"] = [
    {
      key: "1",
      label: (
        <div
          onClick={() => {
            handleOpenModal(state, dispatch, ModalTypeValue.USER);
            handleKeyModal(state, dispatch, EnumItemMenuUserModal.profile);
            handleOpenKeyItemModal(
              state,
              dispatch,
              EnumItemMenuUserModal.profileData,
            );
          }}
          css={HeaderAuthStyle.dropdownItem}
        >
          <img src={DropdownIcon.DropdownIconUser} alt="" />
          Edit Profile
        </div>
      ),
    },
    {
      key: "2",
      label: (
        <div
          css={HeaderAuthStyle.dropdownItem}
          onClick={() => {
            handleOpenModal(state, dispatch, ModalTypeValue.USER);
            handleKeyModal(state, dispatch, EnumItemMenuUserModal.myWallet);
            handleOpenKeyItemModal(
              state,
              dispatch,
              EnumItemMenuUserModal.deposit,
            );
          }}
        >
          <img src={DropdownIcon.DropdownIconDeposit} alt="" />
          Deposit
        </div>
      ),
    },
    {
      key: "3",
      label: (
        <div
          css={HeaderAuthStyle.dropdownItem}
          onClick={async () => {
            await queryClient.prefetchQuery({
              queryKey: [QueryKeys.WithDrawForm],
              queryFn: () => getWithdrawalForm(username as string),
            });
            handleOpenModal(state, dispatch, ModalTypeValue.USER);
            handleKeyModal(state, dispatch, EnumItemMenuUserModal.myWallet);
            handleOpenKeyItemModal(
              state,
              dispatch,
              EnumItemMenuUserModal.withdraw,
            );
          }}
        >
          <img src={DropdownIcon.DropdownIconWithdraw} alt="" />
          Withdraw
        </div>
      ),
    },
    {
      key: "4",
      label: (
        <div
          css={HeaderAuthStyle.dropdownItem}
          onClick={() => {
            handleOpenModal(state, dispatch, ModalTypeValue.USER);
            handleKeyModal(state, dispatch, EnumItemMenuUserModal.close);
            handleOpenKeyItemModal(
              state,
              dispatch,
              EnumItemMenuUserModal.vipDetails,
            );
          }}
        >
          <img src={DropdownIcon.DropdownIconVipDetails} alt="" />
          Vip Details
        </div>
      ),
    },
    {
      key: "5",
      label: (
        <div
          onClick={() => {
            handleOpenModal(state, dispatch, ModalTypeValue.USER);
            handleKeyModal(state, dispatch, EnumItemMenuUserModal.close);
            handleOpenKeyItemModal(
              state,
              dispatch,
              EnumItemMenuUserModal.notification,
            );
          }}
          css={HeaderAuthStyle.dropdownItem}
        >
          <img src={DropdownIcon.DropdownIconMessage} alt="" />
          Messages
        </div>
      ),
    },
    {
      key: "7",
      label: (
        <div
          onClick={() => handleLogoutEvent()}
          css={HeaderAuthStyle.dropdownItem}
        >
          <img src={DropdownIcon.DropdownIconLogout} alt="" />
          Logout
        </div>
      ),
    },
  ];

  return (
    <div css={HeaderAuthStyle.wrapper}>
      <div css={HeaderAuthStyle.btnGroup}>
        <Button
          onClick={() => {
            handleOpenModal(state, dispatch, ModalTypeValue.USER);
            handleKeyModal(state, dispatch, EnumItemMenuUserModal.myWallet);
            handleOpenKeyItemModal(
              state,
              dispatch,
              EnumItemMenuUserModal.deposit,
            );
          }}
        >
          Deposit
        </Button>
        <Button
          onClick={async () => {
            await queryClient.prefetchQuery({
              queryKey: [QueryKeys.WithDrawForm],
              queryFn: () => getWithdrawalForm(username as string),
            });
            handleOpenModal(state, dispatch, ModalTypeValue.USER);
            handleKeyModal(state, dispatch, EnumItemMenuUserModal.myWallet);
            handleOpenKeyItemModal(
              state,
              dispatch,
              EnumItemMenuUserModal.withdraw,
            );
          }}
        >
          Withdrawal
        </Button>
      </div>

      <div css={HeaderAuthStyle.user}>
        <UserInfoStyle>
          {isLoadingBalance ? (
            <LoadingCustom height="100%" sizeIcon="17px" />
          ) : (
            <p>₹ {balanceMember?.total || "0.00"}</p>
          )}
          <span>Credit balance</span>
        </UserInfoStyle>
        {isLoadingProfile ? (
          <LoadingCustom height="100%" sizeIcon="40px" />
        ) : (
          <div css={HeaderAuthStyle.userAvt}>
            <Dropdown placement="top" menu={{ items }}>
              <img
                src={(dataProfile as ProfileType)?.avatar || AvatarUser}
                alt=""
                onClick={() => {
                  handleOpenModal(state, dispatch, ModalTypeValue.USER);
                  handleKeyModal(
                    state,
                    dispatch,
                    EnumItemMenuUserModal.profile,
                  );
                  handleOpenKeyItemModal(
                    state,
                    dispatch,
                    EnumItemMenuUserModal.profileData,
                  );
                }}
              />
            </Dropdown>
          </div>
        )}
      </div>
    </div>
  );
};

export default HeaderAuth;
