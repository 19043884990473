import axiosRequest from "api";
import { payloadHelper } from "helpers/payload-helper";
import { ApiResponse } from "types/api";

import {
  WithDrawalFormSettingTypeResponse,
  WithdrawalSubMissionForm,
} from "./type";

export const withdrawApi = {
  async getWithdrawalForm(username: string) {
    const response = await axiosRequest.post<WithDrawalFormSettingTypeResponse>(
      "",
      payloadHelper("WithdrawForm", username, { username }),
    );
    return response.data;
  },

  withdrawalSubmission: (username: string, payload: WithdrawalSubMissionForm) =>
    axiosRequest.post<ApiResponse>(
      "",
      payloadHelper("Withdrawal", username, payload),
    ),
};
