export enum MemberActive {
  INACTIVE = 0,
  ACTIVE = 1,
}

export enum EnumItemMessage {
  inbox = "inbox",
}

export enum EnumItemMenuUserModal {
  profile = "profile",
  pictureUser = "pictureUser",
  profileData = "profileData",
  phoneNumber = "phoneNumber",
  nickName = "nickName",
  changePassword = "changePassword",
  bankCardManagement = "bankCardManagement",
  panCard = "panCard",

  myWallet = "myWallet",
  wallet = "wallet",
  deposit = "deposit",
  withdraw = "withdraw",
  rewards = "rewards",
  cashback = "cashback",

  transaction = "transaction",
  betting = "betting",

  affiliate = "affiliate",
  aboutUs = "aboutUs",
  vipDetails = "vipDetails",
  sharing = "sharing",
  notification = "notification",

  close = "",
}
