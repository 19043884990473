/** @jsxImportSource @emotion/react */

import { Button, Form, Upload, UploadFile, UploadProps } from "antd";
import { ValidateStatus } from "antd/es/form/FormItem";
import { ReactComponent as PresentationSvg } from "assets/common/icons/presentation.svg";
import { Control, Controller, FieldValues } from "react-hook-form";
import { ConfigCss } from "styles/config";

import { InputFileStyle } from "./InputFile.style";

interface InputFileProps {
  name?: string;
  control?: Control<FieldValues | any>;
  error?: string;
  validate_status?: ValidateStatus;
  disabled?: boolean;
  icon: boolean;
  buttonName: string;
  accept?: string;
  placeholder: string;
  onChange?: (file: UploadFile) => void;
  file?: UploadFile | null;
}

const InputFile = (props: InputFileProps) => {
  const {
    name = "",
    accept = "image/*",
    placeholder = "",
    buttonName,
    control,
    error,
    validate_status,
    disabled,
    onChange,
    file,
    icon,
  } = props;

  const uploadProps: UploadProps = {
    beforeUpload: (file) => {
      onChange && onChange(file);

      return false;
    },
    showUploadList: false,
    fileList: file ? [file] : [],
    disabled,
    accept,
  };

  return (
    <div css={InputFileStyle.self}>
      {control ? (
        <Form.Item help={error} validateStatus={validate_status}>
          <Controller
            name={name}
            control={control}
            render={({
              field: { onChange: _onChange },
              formState: { errors },
            }) => {
              const isError = Object.keys(errors).length > 0;
              return (
                <Upload
                  {...uploadProps}
                  onChange={({ file }) => {
                    _onChange(file);
                  }}
                >
                  {buttonName && (
                    <Button type="primary" css={InputFileStyle.buttonRepeat}>
                      {buttonName}
                    </Button>
                  )}
                  <div
                    css={[
                      InputFileStyle.content,
                      ConfigCss.flexCenter,
                      isError && InputFileStyle.contentError,
                    ]}
                  >
                    {placeholder && (
                      <span css={InputFileStyle.fileName}>
                        <span
                          css={[
                            InputFileStyle.placeholder,
                            isError && InputFileStyle.placeholderError,
                          ]}
                        >
                          {placeholder}
                        </span>
                      </span>
                    )}

                    {icon && (
                      <span
                        css={[
                          InputFileStyle.iconUpload,
                          isError && InputFileStyle.iconUploadError,
                        ]}
                      >
                        <PresentationSvg />
                      </span>
                    )}
                  </div>
                </Upload>
              );
            }}
          />
        </Form.Item>
      ) : (
        <Upload {...uploadProps}>
          <div css={InputFileStyle.content}>
            <span css={InputFileStyle.fileName}>
              {file?.name || (
                <span css={InputFileStyle.placeholder}>{placeholder}</span>
              )}
            </span>
            {icon && (
              <span css={InputFileStyle.iconUpload}>
                <PresentationSvg />
              </span>
            )}
          </div>
        </Upload>
      )}
    </div>
  );
};

export default InputFile;
