/** @jsxImportSource @emotion/react */

import { useQuery } from "@tanstack/react-query";
import { getMemberPromotion } from "api/promotion";
import { TypeListReward, TypeReward } from "api/promotion/type";
import LoadingCustom from "components/common/LoadingCustom";
import HeaderUser from "components/mobile/HeaderUser";
import HideFooter from "components/mobile/HideFooter";
import {
  DATA_PROMOTION_MEMBER,
  TypePromotionItem,
} from "constants/list-promotion";
import { QueryKeys } from "constants/queryKeys";
import { queryClient } from "index";
import moment from "moment";
import { useEffect, useState } from "react";

import { ListPromotionNews } from "./ListPromotionNews";
import { RewardsStyle } from "./style.index";

const initialValue = {
  list: [],
  claim_reward: "0.00",
  runningPromotion: 0,
};

const Rewards = () => {
  const username = queryClient.getQueryData<string>([QueryKeys.username]) || "";

  const { data: rewards, isLoading } = useQuery({
    queryKey: [QueryKeys.MemberPromotion],
    queryFn: () => getMemberPromotion({ username }),
    refetchInterval: 1000 * 30,
    refetchOnWindowFocus: true,
  });

  const [dataRewards, setDataRewards] = useState<TypeListReward>(initialValue);
  const [dataPromotionMemberList, setDataPromotionMemberList] = useState<
    TypePromotionItem[]
  >([]);
  const [isLoadingFetching, setIsLoadingFetching] = useState<boolean>(false);

  const [formattedDate, setFormattedDate] = useState<string>("");

  useEffect(() => {
    if (rewards === undefined) return;

    if (!rewards.data) return;

    setDataRewards({
      list: rewards.data.list || [],
      claim_reward: rewards.data.claim_reward,
      runningPromotion: rewards.data.runningPromotion,
    });
    setIsLoadingFetching(true);
  }, [rewards?.data]);

  useEffect(() => {
    if (!isLoadingFetching) return;
    if (dataRewards.list.length === 0) {
      const date = moment(new Date()).format("DD MM YYYY hh:mm:ss");
      setFormattedDate(date);
      setDataRewards(initialValue);
      return;
    }

    setFormattedDate(dataRewards.list[0].create_date);
    handleListMemberPromotion(dataRewards.list);
  }, [dataRewards.list, isLoadingFetching]);

  const handleListMemberPromotion = (listPromotionResponse: TypeReward[]) => {
    const dataPromotionUpdate: TypePromotionItem[] = DATA_PROMOTION_MEMBER.map(
      (item: TypePromotionItem) => {
        const findPromotion = listPromotionResponse.find(
          (reward: TypeReward) => reward.promotion_id === item.promotion_id,
        );

        if (!findPromotion) return item;

        if (findPromotion.status === "0") {
          return {
            ...item,
            ...findPromotion,
            id: findPromotion.id.toString(),
            disable: false,
            title: "Claim",
          };
        }

        return {
          ...item,
          ...findPromotion,
          id: findPromotion.id.toString(),
          disable: true,
          title: "Claim",
        };
      },
    );
    setDataPromotionMemberList(dataPromotionUpdate);
  };

  const renderContent = () => {
    if (isLoading) return <LoadingCustom height="500px" />;

    if (dataRewards.list.length === 0) {
      return (
        <ListPromotionNews
          runningPromotion={rewards?.data.runningPromotion}
          dataPromotionMemberList={DATA_PROMOTION_MEMBER}
        />
      );
    }

    return (
      <ListPromotionNews
        runningPromotion={rewards?.data.runningPromotion}
        dataPromotionMemberList={dataPromotionMemberList}
      />
    );
  };

  return (
    <RewardsStyle>
      <HeaderUser title="Rewards" />
      <div className="wrapperReward">
        <div className="header-index">
          <div className="header-title">
            <p>Total Rewards Claimed:</p>
            <span>₹ {dataRewards?.claim_reward || "0.00"}</span>
          </div>
          <div className="header-describe flexCenter">
            <span>Last update {formattedDate}</span>
            <span>24HRS TO UPDATE</span>
          </div>
        </div>

        {renderContent()}

        <div className="TC">
          <p>Reward T&C</p>
          <ol>
            <li>Promotion cannot combine with Reward</li>
            <li>Please complete Promotion Turnover</li>
            <li>Min Bal 30 below for Claim Reward Bonus</li>
          </ol>
        </div>
      </div>

      <HideFooter />
    </RewardsStyle>
  );
};

export default Rewards;
