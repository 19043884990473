import axiosRequest from "api";
import { payloadHelper } from "helpers/payload-helper";

import { VipMemberDetailResponse } from "./type";

export const VipMemberDetailApi = {
  async VipMemberDetail(username: string) {
    const response = await axiosRequest.post<VipMemberDetailResponse>(
      "",
      payloadHelper("MemberVIP", username, { username }),
    );

    return response.data;
  },
};
