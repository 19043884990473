/** @jsxImportSource @emotion/react */

import { Empty, message } from "antd";
import { gameApi } from "api/game";
import HelmetCustom from "components/common/Helmet";
import HideFooter from "components/mobile/HideFooter";
import LoadingGame from "components/mobile/LoadingGame";
import PageHeader from "components/mobile/PageHeaderAffiliate";
import ProviderGame from "components/mobile/ProviderGame";
import SubGameList from "components/mobile/SubGameList";
import { ResponseCode } from "constants/response";
import { EnumGameType, EnumHotGame } from "enums/game";
import { useEffect, useRef, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useParams } from "react-router-dom";
import { SubGameResponse } from "types/game";

import { HeaderGameStyle, MLayoutGameStyle } from "../style.index";

const initData: SubGameResponse = {
  data: [],
  totalCount: 0,
  totalPages: 0,
  status: 0,
  msg: "",
  currentPage: 0,
};
const MFishingGame = () => {
  const params = useParams();
  const [data, setData] = useState<SubGameResponse>(initData);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const dataGame = data.data;

  const myRef = useRef(null);

  const getDataSubGame = async (page: number) => {
    setIsLoading(true);
    const providerCode =
      params.provider_code === EnumHotGame.provider_code
        ? ""
        : params.provider_code;

    const featured =
      params.provider_code === EnumHotGame.provider_code ? "1" : "";
    const res = await gameApi.getSubGameList({
      page: page,
      size: 30,
      game_type: EnumGameType.Fishing,
      provider: providerCode,
      featured,
    });
    if (res.data.status !== ResponseCode.SUCCESS) {
      message.destroy();
      message.error(res.data.msg);
      setIsLoading(false);
      return;
    }
    setData({
      ...res.data,
      data: page > 1 ? [...dataGame, ...res.data.data] : res.data.data,
    });
    setIsLoading(false);
  };

  useEffect(() => {
    if (!params.provider_code) return;
    setData({ ...data, currentPage: 0 });
    getDataSubGame(1);
  }, [params.provider_code]);

  useEffect(() => {
    if (myRef.current === null) return;
    (myRef.current as any).scrollTo(0, 0);
  }, [myRef.current, params]);

  const onLoadMore = () => {
    getDataSubGame(data.currentPage + 1);
  };

  return (
    <div css={MLayoutGameStyle.wrapper}>
      <HeaderGameStyle>
        <HelmetCustom title="Fishing Games" />
        <PageHeader title="Fishing Games" />
        <ProviderGame gameType={EnumGameType.Fishing} />
      </HeaderGameStyle>
      <div id="SubGame-list" css={MLayoutGameStyle.content} ref={myRef}>
        <InfiniteScroll
          hasMore={dataGame.length > 0 && dataGame.length < data.totalCount}
          dataLength={dataGame.length}
          next={onLoadMore}
          endMessage={""}
          loader={<h4 css={MLayoutGameStyle.loading}>Loading...</h4>}
          scrollableTarget="SubGame-list"
        >
          <SubGameList games={dataGame} />
        </InfiniteScroll>
        {!dataGame.length && data.currentPage > 0 && (
          <div>
            <Empty />
          </div>
        )}
      </div>
      <HideFooter />
      <LoadingGame visible={data.currentPage > 0 ? false : isLoading} />
    </div>
  );
};

export default MFishingGame;
