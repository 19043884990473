import { css } from "@emotion/react";

export const SubGameListStyle = {
  index: css`
    padding: 10px;
  `,
  listGame: css`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 12px;
  `,
  item: css`
    width: 100%;
    border-radius: 5px;
    box-shadow: 0 4px 12px #0000001a;
    margin-bottom: 10px;
    overflow: hidden;
    cursor: pointer;
    position: relative;

    &:active {
      background-color: rgb(244, 67, 54, 0.2);
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: fill;
      object-position: 50% 50%;
      transition: opacity 0.28s ease-in;
    }

    &.image-error {
      .box-name {
        display: flex;
      }
    }

    .box-name {
      width: 100%;
      position: absolute;
      z-index: 3;
      bottom: 0;
      background: rgba(0, 0, 0, 0.3);
      align-items: center;
      justify-content: center;
      padding: 2px;
      display: none;

      span {
        font-size: 11px;
        color: #fff;
      }
    }
  `,
};
