/** @jsxImportSource @emotion/react */

import { Form, Input, InputProps } from "antd";
import { ValidateStatus } from "antd/es/form/FormItem";
import { Control, Controller, FieldValues } from "react-hook-form";

import { InputCustomStyle } from "./index.style";

export interface InputCustomType extends InputProps {
  margin_bottom?: string;
  name?: string;
  control?: Control<FieldValues | any>;
  errors?: string;
  validate_status?: ValidateStatus;
  defaultValue?: string | number;
  width?: string;
  label?: string;
  setValueProps?: any;
  styleLabel?: any;
}

const InputCustom = (props: InputCustomType) => {
  const {
    name = "",
    control,
    margin_bottom,
    errors,
    validate_status,
    defaultValue,
    width,
    label,
    setValueProps,
    ...propsRest
  } = props;

  return (
    <>
      {control ? (
        <Form.Item
          css={InputCustomStyle.self}
          label={
            label ? (
              <p style={{ fontSize: "16px", color: "white" }}>{label}</p>
            ) : null
          }
          help={errors}
          validateStatus={validate_status}
          className="input-custom"
          style={{
            marginBottom: margin_bottom ? margin_bottom : "20px",
            width: width ? width : "100%",
          }}
          labelCol={{ span: 24 }}
        >
          <Controller
            name={name}
            defaultValue={defaultValue}
            control={control}
            render={({ field: { onChange, value } }) => {
              return (
                <Input
                  onChange={(e) => {
                    onChange(e);
                    setValueProps && setValueProps(e.target.value);
                  }}
                  value={value}
                  {...propsRest}
                />
              );
            }}
          />
        </Form.Item>
      ) : (
        <Input {...propsRest} css={InputCustomStyle.self} />
      )}
    </>
  );
};

export default InputCustom;
