import styled from "@emotion/styled";

export const ChangePasswordContainer = styled.div`
  height: 100%;
  width: 100%;
  padding: 15px;

  .boxIcon {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translatey(-50%);
    path {
      fill: ${(props) => props.theme.colors.white};
    }
  }

  .groupIcon {
    position: relative;
    height: 100%;
  }

  .labelReset {
    width: 100%;
    margin-bottom: 20px;
    text-align: center;
    font-weight: 500;
    font-size: 20px;
    letter-spacing: 0.0125em;
    line-height: 2rem;
    color: ${(props) => props.theme.colors.white};
  }

  .boxLabelOld {
    display: flex;
    flex-direction: column;
    gap: 8px;

    label {
      color: ${(props) => props.theme.colors.white};
    }
  }
  .fs14 {
    font-size: 14px;
  }

  .buttonSubmitChange {
    width: 100%;
    background-color: #00d8a0;
    height: 36px;
    margin-top: 4px;
    border-radius: 5px;
    :hover {
      border-color: transparent !important;
    }

    p {
      font-size: 14px;
      font-weight: 400;
      color: white;
    }
  }
`;
