/** @jsxImportSource @emotion/react */

import clsx from "clsx";
import { LOCAL } from "constants/constants";
import { AppContext } from "contexts/app.context";
import { useContext, useState } from "react";

import { DarkModeStyle, SwitchStyle } from "./style.index";

const DarkMode = () => {
  const { state, dispatch } = useContext(AppContext);
  const isDarkMode = state.darkMode === LOCAL.DARK ? true : false;

  const [isActive, setIsActive] = useState<boolean>(isDarkMode);

  const mode = sessionStorage.getItem(LOCAL.DARK_MODE);

  const onSwitchMode = () => {
    if (!dispatch) return;
    setIsActive(!isActive);

    if (mode === LOCAL.DARK) {
      dispatch({
        type: "DARK_MODE",
        payload: {
          ...state,
          darkMode: "light",
        },
      });
      sessionStorage.setItem(LOCAL.DARK_MODE, LOCAL.LIGHT);
    } else {
      dispatch({
        type: "DARK_MODE",
        payload: {
          ...state,
          darkMode: "dark",
        },
      });
      sessionStorage.setItem(LOCAL.DARK_MODE, LOCAL.DARK);
    }
  };

  return (
    <DarkModeStyle>
      <span>Dark mode</span>

      <SwitchStyle
        className={clsx(mode === LOCAL.DARK && "active")}
        onClick={onSwitchMode}
      />
    </DarkModeStyle>
  );
};

export default DarkMode;
