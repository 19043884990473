import styled from "@emotion/styled";
import { themeCustom } from "styles/theme";

interface Props {
  isValid: boolean;
  valueMobile: string;
}

export const ForgotPasswordContainer = styled.div<Props>`
  width: 100%;
  height: 100%;
  padding: 24px;

  position: relative;
  .iconForgot {
    position: absolute;
    path {
      fill: ${(props) => props.theme.colors.white};
    }
  }

  .labelForgot {
    margin-bottom: 27px;
    width: 100%;
    text-align: center;
    font-weight: 500;
    font-size: 20px;
    color: ${(props) => props.theme.colors.white};
  }

  .mg10 {
    margin-bottom: 10px;
  }
  .labelMobile {
    font-weight: 700;
    font-size: 13px;
    color: ${(props) => props.theme.colors.white};
  }

  .groupMobile {
    display: flex;
    align-items: center;
    gap: 4px;
    margin-bottom: 40px;
  }
  .boxNumber {
    flex: 3;
    display: flex;
    align-items: center;
    padding: 6px 12px;
    justify-content: space-between;
    height: 40px;
    border: 1px solid#c9c9c9;
    border-radius: 4px;
    background: ${themeCustom.colors.white};
    color: ${themeCustom.colors.dark};
  }

  .pt2 {
    padding-top: 2px;
  }

  .flex10 {
    flex: 10;

    .ant-input {
      background: ${themeCustom.colors.white};
      color: ${themeCustom.colors.dark};
    }
  }
  .inputForgot {
    background: ${themeCustom.colors.white};
    color: ${themeCustom.colors.dark};
  }
  .mb40 {
    margin-bottom: 40px;
  }

  .labelEmail {
    font-weight: 700;
    font-size: 13px;
    color: ${(props) => props.theme.colors.white};
  }

  .groupButtonForgot {
    margin: 72px 0 112px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .buttonSubmitForgot {
    width: 100%;
    padding: 10px;
    background-color: ${(props) =>
      !props.isValid || !props.valueMobile ? "#9e9e9e !important" : "#00d8a0"};
    border-color: transparent !important;

    height: 100%;
    opacity: ${(props) => (!props.isValid || !props.valueMobile ? 0.7 : 1)};
  }

  .spanSubmitForgot {
    height: 100%;
    font-size: 13px;
    font-weight: 500;
    color: ${(props) =>
      !props.isValid || !props.valueMobile ? "#424242!important" : "white"};
  }
`;
