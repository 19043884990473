import styled from "@emotion/styled";
import { media } from "styles/breakpoints";
import { themeCustom } from "styles/theme";

export const RolloverStyle = styled.div`
  .indexRoll {
    width: 60%;
    margin: 0 auto;
    ${media.md} {
      width: 100%;
      margin-top: 8px;
    }
  }
`;

interface Props {
  percent?: number;
}
export const ProgressTurnOver = styled.div<Props>`
  position: relative;
  border-radius: 28.8px;
  background: #5c49d8;
  overflow: hidden;
  padding: 0 9.6px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  &::before {
    content: "";
    width: ${(props) => props.percent}%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #9e7ede;
    border-radius: 28.8px;
  }
  span {
    z-index: 9;
    color: ${themeCustom.colors.white} !important;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 23.04px;
    &:first-of-type {
      font-size: 12px;
      font-weight: 700;
      ${media.md} {
        font-size: 10px;
      }
    }
  }
  .noMission {
    color: ${themeCustom.colors.white} !important;
    width: 100%;
    text-align: center;
  }
`;
