/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import { Image, message } from "antd";
import Forum from "assets/mobile/images/forum.png";
import Home from "assets/mobile/images/home.png";
import HomeSelected from "assets/mobile/images/home-selected.png";
import Me from "assets/mobile/images/me.png";
import MeSelected from "assets/mobile/images/me-selected.png";
import Promo from "assets/mobile/images/promo.png";
import PromoSelected from "assets/mobile/images/promo-selected.png";
import Support from "assets/mobile/images/support.png";
import SupportSelected from "assets/mobile/images/support-selected.png";
import { PATH } from "constants/path";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { FooterContainer } from "./index.style";

export interface BottomNavigateType {
  icon: string;
  iconSelected: string;
  id: string;
  title: string;
  isMaintenance?: boolean;
}

export const dataBottomNavigate: BottomNavigateType[] = [
  {
    id: "home",
    title: "HOME",
    icon: Home,
    iconSelected: HomeSelected,
  },
  {
    id: "support",
    title: "SUPPORT",
    icon: Support,
    iconSelected: SupportSelected,
  },
  {
    id: "forum",
    title: "FORUM",
    icon: Forum,
    iconSelected: Forum,
    isMaintenance: true,
  },
  {
    id: "promo",
    title: "PROMO",
    icon: Promo,
    iconSelected: PromoSelected,
  },
  {
    id: "me",
    title: "ME",
    icon: Me,
    iconSelected: MeSelected,
  },
];

export const Footer = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const [selected, setSelected] = useState<string>("");

  useEffect(() => {
    if (pathname === "/") {
      setSelected("home");
      return;
    }
    if (pathname === PATH.Promotions) {
      setSelected("promo");
    }
    if (pathname === PATH.Support) {
      setSelected("support");
    }
    if (pathname === PATH.user.index) {
      setSelected("me");
    }
  }, [pathname]);

  const handleAction = (id: string) => {
    switch (id) {
      case "home": {
        navigate(PATH.Home);
        break;
      }
      case "support": {
        navigate(PATH.Support);
        break;
      }
      case "forum": {
        message.warning({
          key: "forum",
          content: "Under maintenance !!!",
        });
        break;
      }
      case "promo": {
        navigate(PATH.Promotions);
        break;
      }
      case "me": {
        navigate(PATH.user.index);
        break;
      }
    }
  };

  return (
    <FooterContainer id={`app-footer`}>
      <div
        css={css`
          display: grid;
          grid-template-columns: repeat(5, 1fr);
        `}
      >
        {dataBottomNavigate.map((item: BottomNavigateType) => (
          <div
            key={item.id}
            css={css`
              position: relative;
              overflow: hidden;
            `}
            onClick={() => {
              if (item.isMaintenance) {
                message.destroy();
                message.error("Under maintenance !!!");
                return;
              }
              setSelected(item.id);
              handleAction(item.id);
            }}
          >
            <div
              css={css`
                position: absolute;
                height: ${selected === item.id ? "calc(100% + 60px)" : 0};
                width: ${selected === item.id ? "calc(100% + 20px)" : 0};
                transition: all 0.3s ease;
                transform: scale(${selected === item.id ? 1 : 0});
                transform: translate(-50%, -50%);
                border-radius: 50%;
                top: 50%;
                left: 50%;
                animation: ${selected === item.id
                  ? "overlay .8s 1"
                  : "initial"};

                @keyframes overlay {
                  from {
                    background-color: rgba(0, 0, 0, 0.45);
                  }
                  to {
                    background-color: transparent;
                  }
                }
              `}
            />
            <div
              css={css`
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                gap: 2px;
                padding: 10px;
                padding-bottom: 14px;
              `}
            >
              <Image
                src={selected === item.id ? item.iconSelected : item.icon}
                alt=""
                height={16.5}
                width={16.5}
                css={css`
                  width: 16.5px !important;
                  height: 16.5px !important;
                `}
                preview={false}
              />
              <p
                css={css`
                  font-size: 9px;
                  margin: 0;
                  color: #757575;
                  font-weight: 400;
                  letter-spacing: 0.03333em;
                  line-height: 1.25rem;
                  text-align: center;
                `}
              >
                {item.title}
              </p>
            </div>
          </div>
        ))}
      </div>
    </FooterContainer>
  );
};
