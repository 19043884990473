/** @jsxImportSource @emotion/react */

import { Empty, Modal } from "antd";
import { IconRecord } from "assets/common/images";
import { AppContext } from "contexts/app.context";
import { ModalTypeValue } from "enums/modalType";
import { handleCloseModalRecord } from "helpers/handleModal";
import { useContext } from "react";

import { ModalUserPictureStyle } from "./style.index";

const ModalWithdrawable = () => {
  const { state, dispatch } = useContext(AppContext);

  return (
    <Modal
      open={state.openModalRecord === ModalTypeValue.WITHDRAWABLE}
      onCancel={() => handleCloseModalRecord(state, dispatch)}
      css={ModalUserPictureStyle.wrapper}
      footer={null}
      centered
      width={350}
    >
      <div css={ModalUserPictureStyle.header}>
        <div css={ModalUserPictureStyle.title}>
          <div css={ModalUserPictureStyle.contentHeader}>
            <img alt="" src={IconRecord} />
            <p>Collect Record</p>
          </div>
          <p
            css={ModalUserPictureStyle.close}
            onClick={() => handleCloseModalRecord(state, dispatch)}
          >
            ×
          </p>
        </div>
      </div>
      <div css={ModalUserPictureStyle.body}>
        <table>
          <colgroup>
            <col></col>
            <col></col>
          </colgroup>
          <thead>
            <tr>
              <th>Time</th>
              <th>Amount</th>
            </tr>
          </thead>
        </table>
        <div css={ModalUserPictureStyle.listBody}>
          <Empty />
        </div>
      </div>
    </Modal>
  );
};

export default ModalWithdrawable;
