/** @jsxImportSource @emotion/react */

import { useQuery } from "@tanstack/react-query";
import { getMemberVipUser } from "api/vip/query";
import { VipMemberDetailType } from "api/vip/type";
import LoadingCustom from "components/common/LoadingCustom";
import HeaderUser from "components/mobile/HeaderUser";
import HideFooter from "components/mobile/HideFooter";
import { QueryKeys } from "constants/queryKeys";
import { queryClient } from "index";
import { useNavigate } from "react-router-dom";

import { VipDetailUserStyle } from "./style.index";

const VipDetailUser = () => {
  const username = queryClient.getQueryData<string>([QueryKeys.username]);
  const navigate = useNavigate();

  const { data: dataMemberVip, isLoading } = useQuery<VipMemberDetailType>({
    queryKey: [QueryKeys.MemberVIP],
    queryFn: () => getMemberVipUser(String(username)),
  });

  const currentLevel = dataMemberVip?.current_level;
  const nextLevel = dataMemberVip?.next_level.level;

  const currentDeposit = Number(dataMemberVip?.next_level.current_deposit);
  const targeDeposit = Number(dataMemberVip?.next_level.deposit);
  const percentDeposit = (currentDeposit / targeDeposit) * 100;

  const currentTurnOver = Number(dataMemberVip?.next_level.current_turnover);

  const targeTurnOver = Number(dataMemberVip?.next_level.turnover);

  const percentTurnOver = (currentTurnOver / targeTurnOver) * 100;

  if (isLoading) {
    return <LoadingCustom height="100vh" />;
  }

  return (
    <VipDetailUserStyle
      percent={percentDeposit}
      percentTurnOver={percentTurnOver}
    >
      <HeaderUser onBack={() => navigate(-1)} title="VIP" />
      <div className="wrapperVip">
        <div className="headerVip flexCenter">
          <span>V{currentLevel}</span>
          <span>&gt;</span>
          <span>V{nextLevel}</span>
        </div>

        <div className="progressDeposit flexCenter">
          <span>Deposit</span>
          <span>
            {currentDeposit}/{targeDeposit}
          </span>
        </div>

        <div className="progressRollover flexCenter">
          <span>Rollover</span>
          <span>
            {currentTurnOver || 0}/{targeTurnOver || 0}
          </span>
        </div>

        <div className="describe">Claim Bonus Once Promoted</div>

        <div className="table">
          <table>
            <tbody>
              <tr>
                <th>Benefit</th>
                <th className="flex">
                  Current: V{dataMemberVip?.current_level}
                </th>
                <th className="flex">
                  Next: V{dataMemberVip?.next_level.level}
                </th>
              </tr>
              <tr>
                <td>Birthday bonus</td>
                <td className="flex">
                  {dataMemberVip?.benefit.birthday_bonus}
                </td>
                <td className="flex">
                  {dataMemberVip?.benefit.birthday_bonus}
                </td>
              </tr>
              <tr>
                <td>Upgrade bonus</td>
                <td className="flex">
                  {dataMemberVip?.benefit.upgrade_reward}
                </td>
                <td className="flex">
                  {dataMemberVip?.benefit.upgrade_reward}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <HideFooter />
    </VipDetailUserStyle>
  );
};

export default VipDetailUser;
