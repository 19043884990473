import { css } from "@emotion/react";
import { theme } from "styles/theme";

export const JackPotStyle = {
  jackpot: {
    index: css`
      text-align: center;
      color: #d4d4d4;
      font-weight: 700;
      overflow: hidden;
      padding: 37px 0 12px 0;
    `,
    title: css`
      color: ${theme.colors.gray[100]};
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      overflow: hidden;
      &::before {
        margin-left: -50%;
        right: 0.5em;
      }
      &::after {
        left: 0.5em;
        margin-right: -50%;
      }
      &::before,
      &::after {
        background-color: ${theme.colors.gray[100]};
        content: "";
        display: inline-block;
        height: 0.05vw;
        position: relative;
        vertical-align: middle;
        width: 50%;
      }
    `,
    amount: css`
      color: ${theme.colors.gray[100]};
      font-size: 48px;
      font-style: normal;
      font-weight: 700;
      overflow: hidden;
      position: relative;

      margin-top: 16px;
    `,
  },
};
