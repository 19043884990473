import axiosRequest from "api";
import { payloadHelper } from "helpers/payload-helper";

import { CurrenciesResponse } from "./type";

export const CurrenciesApi = {
  getCurrencyList: () =>
    axiosRequest.post<CurrenciesResponse>(
      "/",
      payloadHelper("GetCurrencies", null, null),
    ),
};
