/** @jsxImportSource @emotion/react */

import { useQuery } from "@tanstack/react-query";
import { Button, Form, message } from "antd";
import { AuthApi } from "api/auth";
import { IconPrev } from "assets/desktop/homepage/icons";
import InputPasswordMobile from "components/mobile/base/InputPasswordMobile";
import { QueryKeys } from "constants/queryKeys";
import { ResponseCode } from "constants/response";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { ChangePasswordContainer } from "./index.style";

export interface ChangePasswordType {
  username: string;
  oldpass: string;
  password: string;
  passconf: string;
}

export const ChangePassword = () => {
  const navigate = useNavigate();

  const { data: username } = useQuery<string>([QueryKeys.username]);

  const { handleSubmit, control, reset } = useForm<ChangePasswordType>({
    defaultValues: {
      username,
    },
  });

  const onSubmit = async (data: ChangePasswordType) => {
    if (!username) {
      return;
    }

    const response = await AuthApi.changePassword(username, {
      ...data,
    });

    if (response.status === ResponseCode.SUCCESS) {
      message.error({
        content: response.msg,
      });

      reset();
      return;
    }

    message.error({
      content: response.msg,
    });
  };

  return (
    <ChangePasswordContainer>
      <div className="groupIcon">
        <div className="boxIcon" onClick={() => navigate(-1)}>
          <IconPrev />
        </div>

        <div className="labelReset">Reset Password</div>
      </div>

      <Form onSubmitCapture={handleSubmit(onSubmit)}>
        <div className="boxLabelOld">
          <div>
            <label className="fs14">Old Password</label>
            <InputPasswordMobile control={control} name="oldpass" />
          </div>

          <div>
            <label className="fs14">New Password</label>
            <InputPasswordMobile control={control} name="password" />
          </div>

          <div>
            <label className="fs14">Confirm password</label>
            <InputPasswordMobile control={control} name="passconf" />
          </div>
        </div>

        <Button className="buttonSubmitChange" htmlType="submit">
          <p>Submit</p>
        </Button>
      </Form>
    </ChangePasswordContainer>
  );
};
