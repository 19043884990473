import { css } from "@emotion/react";
import { theme, themeCustom } from "styles/theme";

export const ModalMobileNotificationStyle = {
  wrapper: css`
    /* top: 330px;
    left: 50%;
    transform: translate(-50%, -50%); */

    .ant-modal {
      &-content {
        padding: 0;
        background-color: transparent;
        box-shadow: unset;
      }
      &-header {
        margin-bottom: unset;
        background-color: ${theme.colors.primary};
      }
      &-footer {
        margin-top: unset;
      }
      &-title {
        color: ${themeCustom.colors.white};
        text-align: center;
        padding: 10px 0;
        font-size: 18px;
        font-weight: 700;
      }
      &-close {
        top: 11px;
        color: white;
        font-weight: 700;
      }
    }
  `,

  close: css``,

  label: css`
    background-color: ${theme.colors.primary};

    text-align: center;

    padding: 10px 0;

    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;

    span {
      color: white;
      font-size: 18px;
      font-weight: 600;
    }
  `,

  checkBox: css`
    background-color: transparent;
    color: white;
  `,

  mainContent: css`
    background-color: white;
    height: 300px;
    box-shadow: 0 -50px 124px -50px #0006 inset;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  `,

  listNotification: css`
    width: 100%;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
  `,
  notificationItem: css`
    padding: 10px;
    display: flex;
    gap: 10px;
    justify-content: space-between;
    border-bottom: 1px solid #d6d6d6;
    align-items: center;
  `,
  itemTitleWrapper: css`
    width: calc(100% - 20px);
    display: flex;
    align-items: center;
    gap: 6px;
    .iconCommentDot {
      width: 16px;
      svg,
      path {
        fill: ${theme.colors.primary};
      }
    }
  `,
  itemTitle: css`
    width: 100%;
    display: inline-block;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-size: 15px;
    color: #000000de;
  `,
  arrowNextIcon: css`
    font-size: 12px;
    display: inline-block;
    vertical-align: middle;
    path {
      fill: #000000de;
    }
  `,
};
