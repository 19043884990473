import styled from "@emotion/styled";
import { themeCustom } from "styles/theme";

export const BankEnrollContainer = styled.div`
  .boxFrom {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 63px 15px 15px;
  }

  .flex {
    display: flex;
    flex-direction: column;

    input {
      background: ${themeCustom.colors.white};
      color: ${themeCustom.colors.dark};
      /* &::placeholder {
        color: ${themeCustom.colors.white};
      } */
    }
  }

  .labelBankAccount {
    font-size: 13px;
    font-weight: 700;
    color: ${(props) => props.theme.colors.white};
  }

  .buttonSubmit {
    background-color: rgb(39, 216, 160);
    padding: 10px;
    border-radius: 5px;
    width: 100%;
    height: 100%;
    color: white !important;

    :hover {
      border-color: transparent !important;
    }
  }
`;
/* menuItem: (isPromotion: boolean) => css`
    padding: 24px 0;
    display: flex;
    align-items: center;
    gap: 8px;
    color: ${isPromotion ? "#B98D1B" : theme.colors.black};
    position: relative;
  `, */
