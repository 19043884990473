/** @jsxImportSource @emotion/react */

import { useQuery } from "@tanstack/react-query";
import { Logo } from "assets/common/images";
import { LIST_MENU_HEADER } from "constants/list-menu-header";
import { PATH } from "constants/path";
import { QueryKeys } from "constants/queryKeys";
import { Link, NavLink } from "react-router-dom";

import DarkMode from "./DarkMode";
import HeaderAuth from "./HeaderAuth";
import HeaderUnAuth from "./HeaderUnAuth";
import { HeaderStyle, MenuItem } from "./index.style";

export const Header = () => {
  const { data } = useQuery([QueryKeys.isAuthenticated]);

  return (
    <HeaderStyle>
      <div className="wrapper">
        <div className="mainContentHeader">
          <Link to={PATH.Home} className="logo">
            <img src={Logo} alt="" />
          </Link>
          <div className="menu">
            {LIST_MENU_HEADER.map((item, index) => {
              const isPromotion = item.name === "Promotions";
              return (
                <MenuItem isPromotion={isPromotion} key={index}>
                  <NavLink to={item.href} className="menuItem">
                    <item.icon />
                    <span>{item.name}</span>
                  </NavLink>
                </MenuItem>
              );
            })}
          </div>

          <DarkMode />
        </div>
        {data ? <HeaderAuth /> : <HeaderUnAuth />}
      </div>
    </HeaderStyle>
  );
};
