/** @jsxImportSource @emotion/react */

import { IconArrowBack } from "assets/mobile/icons/profile";
import { useNavigate } from "react-router-dom";

import { HeaderUserContainer } from "./style.index";

type Props = {
  title: string;
  onBack?: () => void;
};

const HeaderUser = ({ title, onBack }: Props) => {
  const navigate = useNavigate();
  return (
    <HeaderUserContainer>
      <div onClick={() => (onBack ? onBack() : navigate(-1))} className="iconH">
        <IconArrowBack />
      </div>
      <div className="titleH">
        <span>{title}</span>
      </div>
    </HeaderUserContainer>
  );
};

export default HeaderUser;
