/** @jsxImportSource @emotion/react */

import { Empty, message, Spin } from "antd";
import { gameApi } from "api/game";
import LoadingCustom from "components/common/LoadingCustom";
import { ResponseCode } from "constants/response";
import { useAppContext } from "contexts/app.context";
import { handleLoginGame } from "helpers/handleLoginGame";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SubGameResponse, SubGameType } from "types/game";

import { ListSubGameStyle } from "./index.style";

type Props = {
  game_type: string;
  provider_code?: string;
};

interface DataStateType extends SubGameResponse {
  is_data?: boolean;
}

const initData: DataStateType = {
  data: [],
  totalCount: 0,
  totalPages: 0,
  status: 0,
  msg: "",
  currentPage: 0,
  is_data: true,
};

const pageSize = Number.MAX_SAFE_INTEGER;

function ListSubGame({ game_type, provider_code }: Props) {
  const navigate = useNavigate();
  const { state, dispatch } = useAppContext();

  const [data, setData] = useState<DataStateType>(initData);
  const [isLoading, setIsLoading] = useState(false);

  const dataGames = data.data;

  useEffect(() => {
    if (!provider_code) return;
    getDataSubGame(1);
  }, [provider_code]);

  const getDataSubGame = async (page: number) => {
    setIsLoading(true);
    const res = await gameApi.getSubGameList({
      page: page,
      size: pageSize,
      game_type: game_type,
      provider: provider_code === "HOT" ? "" : provider_code,
      featured: provider_code === "HOT" ? "1" : "",
    });
    if (res.data.status !== ResponseCode.SUCCESS) {
      message.destroy();
      message.error(res.data.msg);
      setIsLoading(false);
      setData({ ...initData, is_data: false });
      return;
    }
    setData({
      ...res.data,
      data: formatData(res.data.data, page),
    });
    setIsLoading(false);
  };

  const formatData = (newData: SubGameType[], page: number) => {
    if (page > 1) return [...dataGames, ...newData];
    return newData;
  };

  return (
    <div css={ListSubGameStyle.index}>
      <div css={ListSubGameStyle.listGame}>
        {isLoading ? (
          <LoadingCustom height="50vh" />
        ) : (
          <>
            {dataGames.length > 0 ? (
              dataGames.map((item, index) => {
                return (
                  <div
                    onClick={() =>
                      handleLoginGame(item, state, dispatch, navigate)
                    }
                    key={index}
                    className={`casino-card ${provider_code}`}
                    id={`sub-game-${index}`}
                  >
                    <div className="image">
                      <div className="img__container">
                        <img
                          src={item.picture}
                          alt={item.name}
                          loading="lazy"
                          onError={(e) => {
                            e.currentTarget.src =
                              "/assets/game/image-error.png";
                            const element = document.getElementById(
                              `sub-game-${index}`,
                            );
                            if (element) {
                              element.classList.add("image-error");
                            }
                          }}
                        />
                      </div>
                      <div className="overplay">
                        <p className="button">Play Now</p>
                      </div>
                      <div className="name-game">
                        <span>{item.name}</span>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : !data.is_data ? (
              <div className="empty">
                <Empty description="No Data" />
              </div>
            ) : null}
          </>
        )}
      </div>

      {data.currentPage > 0 && data.currentPage < data.totalPages && (
        <div css={ListSubGameStyle.loadMore}>
          <p
            onClick={() => {
              if (isLoading) return;
              getDataSubGame(data.currentPage + 1);
            }}
          >
            <span>{isLoading ? <Spin /> : "LoadMore"}</span>
          </p>
        </div>
      )}
    </div>
  );
}

export default ListSubGame;
