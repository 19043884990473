import { QueryKeys } from "constants/queryKeys";
import { ResponseCode } from "constants/response";
import { queryClient } from "index";

import { BankApi } from ".";

export const getMemberBank = async (username: string) => {
  const res = await BankApi.getListPlayerBank(username);
  const resData = res.data;

  if (res.status === ResponseCode.SUCCESS) {
    queryClient.setQueryData([QueryKeys.MemberBank], resData);
    return;
  }
  queryClient.setQueryData([QueryKeys.MemberBank], null);
};
export const getListBank = async (username: string) => {
  const res = await BankApi.getListBank(username);
  const resData = res.data;

  if (res.status === ResponseCode.SUCCESS) {
    queryClient.setQueryData([QueryKeys.GetBankList], resData);
    return;
  }
  queryClient.setQueryData([QueryKeys.GetBankList], null);
};
