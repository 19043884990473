import "./css/fonts.css";
import "./css/input.css";
import "./css/select.css";

import { ThemeProvider } from "@emotion/react";
import { useQuery } from "@tanstack/react-query";
import { AuthApi } from "api/auth";
import { withdrawApi } from "api/withdraw";
import { Sticky } from "components/common/Sticky";
import { LoadingMobile } from "components/mobile/ui/Loading";
import { LOCAL } from "constants/constants";
import { PATH } from "constants/path";
import { QueryKeys } from "constants/queryKeys";
import { AppContext } from "contexts/app.context";
import { ModalTypeValue } from "enums/modalType";
import { EnumItemMenuUserModal } from "enums/user";
import { handleLogout as handleLogoutEvent } from "helpers/handleLogout";
import { handleCloseModal, handleOpenModal } from "helpers/handleModal";
import { queryClient } from "index";
import { useGetProfile } from "query/users/queryHookUser";
import { useContext, useEffect, useRef } from "react";
import { Outlet, useNavigate, useSearchParams } from "react-router-dom";
import { isMobile } from "router";
import GlobalStyles from "styles/global";
import { theme, themeDark } from "styles/theme";

function App() {
  const username = localStorage.getItem("username");

  const navigate = useNavigate();

  const [params] = useSearchParams();
  const { state, dispatch } = useContext(AppContext);

  const { data: tokenCache } = useQuery([QueryKeys.token]);
  const { data: usernameCache } = useQuery([QueryKeys.username]);
  const { data: isAuthenticated } = useQuery([QueryKeys.isAuthenticated]);

  const { isLoading } = useGetProfile(username || "");
  const appRef = useRef<NodeJS.Timeout | undefined>(undefined);
  const mode = sessionStorage.getItem(LOCAL.DARK_MODE) || LOCAL.LIGHT;

  useQuery({
    queryKey: ["fetchToken", usernameCache, tokenCache],
    queryFn: async () => {
      if (!tokenCache) return "Token invalid";
      if (!usernameCache) return "username invalid";

      try {
        const response = await AuthApi.verifyToken(usernameCache as any, {
          username: usernameCache as any,
          token: tokenCache as any,
        });

        if (response.status !== 0) {
          handleLogout();
        }
        return response;
      } catch (error) {
        return error;
      }
    },
    refetchInterval: isAuthenticated ? 1000 * 15 : false,
  });

  useQuery({
    queryKey: [QueryKeys.WithDrawForm],
    queryFn: async () => {
      if (!usernameCache) return "username invalid";

      try {
        const response = await withdrawApi.getWithdrawalForm(
          usernameCache as any,
        );

        if (response.status !== 0) {
          localStorage.clear();
          navigate("/");
          window.location.reload();
        }
        return response;
      } catch (error) {
        return error;
      }
    },
    refetchInterval: isAuthenticated ? 300000 : false,
  });

  const handleInteraction = () => {
    if (!isAuthenticated) return;
    if (appRef.current) {
      clearTimeout(appRef.current);
    }
    appRef.current = setTimeout(
      () => {
        handleLogout();
        clearTimeout(appRef.current);
      },
      1000 * 60 * 60 * 24,
    );
  };

  useEffect(() => {
    handleInteraction();
    if (isAuthenticated) {
      window.addEventListener("click", handleInteraction);
    } else {
      window.removeEventListener("click", handleInteraction);
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (!isAuthenticated) {
      if (params.get("code")) {
        return !isMobile
          ? handleOpenModal(state, dispatch, ModalTypeValue.REGISTER)
          : navigate(`${PATH.Signup}?code=${params.get("code")}`);
      }
      return;
    }

    if (isMobile) {
      handleOpenModal(state, dispatch, ModalTypeValue.MOBILE_NEWS);

      return;
    }
    if (params.get("code")) {
      return handleOpenModal(state, dispatch, ModalTypeValue.REGISTER);
    }
    if (
      state.keyItem === EnumItemMenuUserModal.profileData ||
      state.openModal === ModalTypeValue.LOGIN
    ) {
      handleOpenModal(state, dispatch, ModalTypeValue.ANNOUNCEMENT);
    }
  }, [isAuthenticated, isMobile]);

  const handleLogout = () => {
    handleCloseModal(state, dispatch);
    handleLogoutEvent(dispatch, state);
  };

  useEffect(() => {
    if (!username) return;
    if (isAuthenticated) return;

    queryClient.setQueryData(
      [QueryKeys.isAuthenticated],
      username ? true : false,
    );
  }, [username]);

  if (isLoading && username && isMobile) return <LoadingMobile />;

  const currentTheme = mode === LOCAL.DARK ? themeDark : theme;

  return (
    <ThemeProvider theme={currentTheme}>
      <GlobalStyles />
      <Outlet />
      {!isMobile && <Sticky />}
    </ThemeProvider>
  );
}

export default App;
