import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const MLayoutGameStyle = {
  wrapper: css`
    display: flex;
    flex-direction: column;
    overflow: hidden;
    height: 100vh;
  `,

  content: css`
    position: relative;
    flex: 1;
    overflow-y: auto;
  `,
  loadMore: css`
    padding-bottom: 12px;
    display: flex;
    align-items: center;
    justify-content: center;

    p {
      background: #ffbc00;
      font-size: 12px;
      letter-spacing: 0.05px;
      padding: 6px 10px;
      transition: all 0.32s ease;
      color: #fff;
      cursor: pointer;
      min-width: 44px;
      text-align: center;
    }
  `,

  loading: css`
    text-align: center;
    margin-top: 6px;
  `,
};

export const HeaderGameStyle = styled.div`
  background-color: ${(props) => props.theme.colors.black};
`;
