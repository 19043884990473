import { message } from "antd";
import { AppState } from "contexts/app.context";
import { queryClient } from "index";

export const handleLogout = (dispatch: any, state: AppState) => {
  queryClient.clear();
  const username = localStorage.getItem("username") as string;
  localStorage.clear();
  localStorage.setItem("lastLoggedIn", username);
  if (!dispatch) return;
  dispatch({
    type: "LAST_LOGGEDIN",
    payload: {
      ...state,
      lastLoggedIn: username as string,
    },
  });

  window.location.reload();
  message.success({
    key: "logout",
    content: "Successfully logout",
  });
};
