/** @jsxImportSource @emotion/react */

import { useQuery } from "@tanstack/react-query";
import { QueryKeys } from "constants/queryKeys";
import { getWithdrawalForm } from "query/withdraw/queryFnsWithdraw";

import { ProgressTurnOver, RolloverStyle } from "./style";

function Rollover() {
  const { data: username } = useQuery<string>({
    queryKey: [QueryKeys.username],
  });

  const { data } = useQuery({
    queryKey: [QueryKeys.WithDrawForm],
    queryFn: () => getWithdrawalForm(username || ""),
    enabled: !!username,
  });

  const pending_rollover = data?.pending_rollover;
  const detail = pending_rollover ? pending_rollover.detail[0] : null;
  if (
    !detail ||
    Number(detail.current_amount?.replace(/\.00$/, "")) >=
      Number(detail.achieve_amount?.replace(/\.00$/, "")) ||
    parseFloat(detail.achieve_amount) === 0
  )
    return (
      <RolloverStyle>
        <div className="indexRoll">
          <ProgressTurnOver percent={0}>
            <span className="noMission">No Mission</span>
          </ProgressTurnOver>
        </div>
      </RolloverStyle>
    );

  const currentTurnOver = Number(detail.current_amount?.replace(/\.00$/, ""));

  const targeTurnOver = Number(detail.achieve_amount?.replace(/\.00$/, ""));
  const percentTurnOver = (currentTurnOver / targeTurnOver) * 100;

  return (
    <RolloverStyle>
      <div className="indexRoll">
        <ProgressTurnOver percent={percentTurnOver}>
          <span>Rollover</span>
          <span>
            {currentTurnOver}/{targeTurnOver}
          </span>
        </ProgressTurnOver>
      </div>
    </RolloverStyle>
  );
}

export default Rollover;
