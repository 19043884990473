import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const MenuInfoUserStyle = {
  wrapper: css`
    padding: 16px;
    max-height: 55vh;
    overflow: auto;
    padding-bottom: 20%;

    display: flex;
    flex-direction: column;

    gap: 16px;
  `,

  listMenu: css`
    a {
      text-decoration: none;
    }
    border-radius: 5px;
    background-color: rgba(187, 185, 185, 0.2);
    display: flex;
    flex-direction: column;
    gap: 5px;

    &:first-of-type {
      box-shadow: 0 7px 29px 0 #64646f33;
    }
    &:last-of-type {
      box-shadow: 0 7px 29px 0 #64646f33;
    }
  `,
};

export const ItemMenuHeaderStyle = styled.div`
  display: flex;
  align-items: center;

  span {
    margin-left: 43.36px;

    color: ${(props) => props.theme.colors.dark};

    font-size: 12.504px;
    font-style: normal;
    font-weight: 400;
    line-height: 20.256px;
  }

  .icon-menu {
    padding: 1px;
    background-color: white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    svg {
      path {
        stroke-width: 1px !important;
      }
    }
  }
`;

export const ItemMenuStyle = styled.div`
  padding: 12px 27.96px 12px 10.52px;
  border-radius: 5px;
  background: ${(props) => props.theme.colors?.darkProfile};
  border: 1px solid white;

  .icon-arrow {
    path {
      fill: ${(props) => props.theme.colors.white};
    }
  }
`;
