import styled from "@emotion/styled";
import { theme } from "styles/theme";

export const HeaderUnAuthStyle = styled.div`
  display: flex;

  button {
    width: 114px;
    height: 40px;

    color: ${theme.colors.gray[100]};
    &:last-of-type {
      border-radius: 6px;
      background: ${theme.colors.text?.link};

      border: unset;
      span {
        color: ${(props) => props.theme.colors.black};
      }
    }
  }
`;
