/** @jsxImportSource @emotion/react */

import { Button } from "antd";
import { AppContext } from "contexts/app.context";
import { ModalTypeValue } from "enums/modalType";
import { handleOpenModal } from "helpers/handleModal";
import { useContext } from "react";

import { HeaderUnAuthStyle } from "./style.index";

const HeaderUnAuth = () => {
  const { state, dispatch } = useContext(AppContext);

  const onOpenModal = (type: "login" | "register") => {
    if (type === "register") {
      handleOpenModal(state, dispatch, ModalTypeValue.REGISTER);
    } else {
      handleOpenModal(state, dispatch, ModalTypeValue.LOGIN);
    }
  };

  return (
    <HeaderUnAuthStyle>
      <Button type="link" onClick={() => onOpenModal("login")}>
        Login
      </Button>
      <Button onClick={() => onOpenModal("register")}>Sign up</Button>
    </HeaderUnAuthStyle>
  );
};

export default HeaderUnAuth;
