import axiosRequest from "api";
import { payloadHelper } from "helpers/payload-helper";
import { ApiResponse } from "types/api";

import { DataMemberDocumentResponse, DocumentUserPayload } from "./type";

export const DocumentsApi = {
  async addBankDocuments(username: string, payload: DocumentUserPayload) {
    const response = await axiosRequest.post<ApiResponse>(
      "",
      payloadHelper("AddMemberDoc", username, payload),
    );
    return response.data;
  },

  async getBankDocuments(username: string) {
    const response = await axiosRequest.post<DataMemberDocumentResponse>(
      "",
      payloadHelper("GetMemberDoc", username, { username }),
    );
    return response.data;
  },
};
