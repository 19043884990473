/** @jsxImportSource @emotion/react */

import { Image } from "antd";
import { LogoText } from "assets/common/images";
import { IconPrev } from "assets/desktop/homepage/icons";
import { useNavigate } from "react-router-dom";

import { PageHeaderContainer } from "./index.style";
interface props {
  title: string;
  disableGoBack?: boolean;
}
const PageHeaderAffiliate = ({ title, disableGoBack = false }: props) => {
  const navigate = useNavigate();

  const onHistory = () => {
    if (window.history.state && window.history.state.idx > 0) {
      navigate(-1);
    } else {
      navigate("/", { replace: true });
    }
  };
  return (
    <PageHeaderContainer>
      <div className="contentPageHeader">
        {disableGoBack ? (
          <span className="w-30" />
        ) : (
          <button onClick={onHistory} className="buttonBack">
            <IconPrev />
          </button>
        )}
        <Image src={LogoText} alt="" preview={false} />
        <span className="w-30" />
      </div>
    </PageHeaderContainer>
  );
};

export default PageHeaderAffiliate;
