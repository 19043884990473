import { css } from "@emotion/react";
import { theme } from "styles/theme";

export const ModalUserPictureStyle = {
  wrapper: css`
    .ant-modal {
      &-content {
        padding: unset !important;
        background: white;
      }
      &-close {
        display: none;
        top: 4px;
        right: 4px;
        &-x {
          color: ${theme.colors.black};
        }
      }
    }
  `,
  header: css`
    color: rgba(255, 255, 255, 0.85);
    border-radius: 8px 8px 0 0;
    margin-bottom: 8px;
    border-bottom: 1px solid #303030;

    background: #00d8a0 !important;
    padding: 0 !important;
  `,
  contentHeader: css`
    align-items: center;
    display: flex;
    font-weight: 900;
    gap: 1rem;
    justify-content: center;
    margin: 0 !important;
    padding: 1rem;

    img {
      border: 0;
      vertical-align: middle;
    }
  `,
  close: css`
    background-color: initial;
    border: none;
    border-radius: 50%;
    color: #fff;
    cursor: pointer;
    font-size: 2rem;
    font-weight: 500;
    height: 30px;
    margin: 0.5rem;
    transition: all 0.2s ease;
    width: 30px;
  `,
  title: css`
    width: 100%;
    background-color: #00d8a0;
    border-radius: 0.5rem 0.5rem 0 0;
    display: flex;
    gap: 1rem;
    justify-content: space-between;
  `,
  body: css`
    display: flex;
    flex-direction: column;
    overflow: hidden;

    table {
      border-collapse: initial;
      border-radius: 2px 2px 0 0;
      border-spacing: 0;
      text-align: left;
      width: 100%;

      col {
        width: 183px;
      }

      thead {
        background: transparent !important;

        tr {
          border-color: inherit;
          display: table-row;

          th {
            text-align: center;
            height: 46px;
            position: relative;

            color: #000;
            text-align: center;
            font-family: Roboto;
            font-size: 10px;
            font-style: normal;
            font-weight: 600;
            line-height: 15.71px; /* 157.1% */

            &::before {
              position: absolute;
              top: 50%;
              inset-inline-end: 0;
              width: 1px;
              height: 1.6em;
              background-color: #303030;
              transform: translateY(-50%);
              transition: background-color 0.2s;
              content: "";
            }
          }
        }
      }
    }
  `,
  listBody: css`
    display: flex;
    padding: 48px 16px 49px 16px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  `,
};
