/** @jsxImportSource @emotion/react */

import { useQuery } from "@tanstack/react-query";
import { Button, Form, message, Select } from "antd";
import { BankApi } from "api/bank";
import { getMemberBank } from "api/bank/query";
import { PlayerBankFormType } from "api/bank/type";
import Loading from "components/common/Loading";
import InputCustomMobile from "components/mobile/base/InputCustomMobile";
import SelectCustomMobile from "components/mobile/base/SelectCustomMobile";
import HeaderUser from "components/mobile/HeaderUser";
import { PATH } from "constants/path";
import { QueryKeys } from "constants/queryKeys";
import { ResponseCode } from "constants/response";
import { AppContext } from "contexts/app.context";
import { ModalTypeValue } from "enums/modalType";
import { handleOpenModal } from "helpers/handleModal";
import { queryClient } from "index";
import { useQueryCurrencies } from "query/currencies/queryHookCurrencies";
import { useGetProfile } from "query/users/queryHookUser";
import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { OptionSelect } from "types";
import { BankType } from "types/bank";
import { ProfileType } from "types/user";

import { BankEnrollContainer } from "./index.style";

const BankEnroll = () => {
  const navigate = useNavigate();
  const { state, dispatch } = useContext(AppContext);

  const username = queryClient.getQueryData<string>([QueryKeys.username]);
  const { refetch } = useGetProfile(String(username));
  const profile = queryClient.getQueryData<ProfileType>([
    QueryKeys.MemberDetail,
  ]);

  const { data: dataCurrency } = useQueryCurrencies();

  const { data: listBank, isLoading } = useQuery(
    ["bank", username],
    async () => {
      const response = await BankApi.getListBank(username || "");

      return response.data;
    },
  );

  const [banks, setBanks] = useState<OptionSelect[]>([]);
  const [valueCurrency, setValueCurrency] = useState(
    profile?.currency.id || "",
  );
  const [isLoadingSubmit, setIsLoadingSubmit] = useState<boolean>(false);
  const [selectCurrency, setSelectCurrency] = useState<OptionSelect[]>([]);

  const { control, handleSubmit, reset, setValue } =
    useForm<PlayerBankFormType>({
      mode: "onChange",
      // resolver: yupResolver(schema),
      defaultValues: {
        username,
        account_name: profile?.full_name || "",
      },
    });

  const onSubmit = async (data: PlayerBankFormType) => {
    setIsLoadingSubmit(true);

    const response = await BankApi.addPlayerBank(data.username, {
      ...data,
      currency_id: valueCurrency,
    });

    setIsLoadingSubmit(false);

    const { msg, status } = response;
    if (status !== ResponseCode.SUCCESS) {
      message.error({
        key: "bank",
        content: msg,
      });
      return;
    }
    message.success({
      key: "bank",
      content: msg,
    });
    getMemberBank(username as string);
    navigate(PATH.user.bankListUser);
    refetch();
    queryClient.invalidateQueries([QueryKeys.WithDrawForm]);
    reset();
  };
  const onChangeCurrency = (val: string) => {
    setValueCurrency(val);
  };

  useEffect(() => {
    if (!listBank) return;

    if ((listBank as BankType[]).length === 0) return;

    const listOptionsBank = (listBank as BankType[]).map((item) => {
      return {
        value: item.bank_id,
        label: item.bank_name,
      };
    });

    setBanks(listOptionsBank);
  }, [listBank]);

  useEffect(() => {
    if (!profile) return;

    if (profile.full_name) return;
    navigate(PATH.user.profile);
    handleOpenModal(state, dispatch, ModalTypeValue.USER_DETAIL);
    // message.warning("Please update fullname before creating bank");
  }, [profile]);

  useEffect(() => {
    if (!dataCurrency) return;

    const options: OptionSelect[] = dataCurrency?.map((item) => ({
      value: item.currency_id,
      label: item.currency_name,
    }));

    setSelectCurrency(options);
  }, [dataCurrency]);

  return (
    <BankEnrollContainer>
      <HeaderUser title="Add Bank Account" />

      {isLoading ? (
        <Loading visible />
      ) : (
        <>
          <Form onSubmitCapture={handleSubmit(onSubmit)}>
            <div className="boxFrom">
              <div className="flex">
                <label className="labelBankAccount">Bank Name</label>

                <div>
                  <SelectCustomMobile
                    control={control}
                    name="id"
                    options={banks}
                    placeholder="Select bank name"
                    // error={errors.id?.message}
                    // validate_status={errors.id && "error"}
                  />
                </div>
              </div>

              <div className="flex">
                <label className="labelBankAccount">Bank Account Name</label>

                <InputCustomMobile
                  control={control}
                  name={"account_name"}
                  margin_bottom="0"
                  disabled
                />
              </div>

              <div className="flex">
                <label className="labelBankAccount">Bank Account Number</label>

                <InputCustomMobile
                  control={control}
                  name={"account_no"}
                  margin_bottom="0"
                  placeholder="Bank account number"
                  // type="number"
                  onChange={(e) => {
                    const key = e.target.value;
                    const regex = new RegExp(/^\d+$/);

                    if (key === "") {
                      setValue("account_no", "");
                      return;
                    }

                    if (regex.test(key)) {
                      setValue("account_no", key);
                      return;
                    }

                    if (/^[^\d]+$/.test(key)) {
                      setValue("account_no", "");
                    }
                  }}
                />
              </div>

              <div className="flex">
                <label className="labelBankAccount">Select Currency</label>

                {profile?.currency.id ? (
                  <InputCustomMobile
                    control={control}
                    value={profile.currency.name}
                    name={"currency_id"}
                    margin_bottom="0"
                    disabled
                  />
                ) : (
                  <Select
                    onChange={onChangeCurrency}
                    options={selectCurrency}
                    placeholder="Select Currency"
                  />
                )}
              </div>

              <Button
                loading={isLoadingSubmit}
                className="buttonSubmit"
                htmlType="submit"
              >
                Submit
              </Button>
            </div>
          </Form>
        </>
      )}
    </BankEnrollContainer>
  );
};

export default BankEnroll;
