import { css } from "@emotion/react";
import { theme } from "styles/theme";

export const DatePickerCustomStyle = {
  self: css`
    width: 100%;
    & .ant-picker {
      width: 100%;
      height: 34px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      padding: 5px 10px;
      font-size: 14px;
      background-color: ${theme.colors.white};
      border-radius: 4px;
      color: ${theme.colors.black};
      outline: none;
      box-shadow: none;
      font-weight: 500;

      &-disabled {
        opacity: 0.5;
      }
      &-input {
        .ant-picker-clear {
          display: none;
        }
      }
      &-status-error {
        border-color: var(--error-color) !important;
        input,
        input::placeholder {
          color: var(--error-color);
        }
        & .ant-picker-suffix {
          svg {
            color: var(--error-color);
          }
        }
      }
    }
    & .ant-form-item-explain-error {
      color: var(--error-color) !important;
    }
  `,
};
