/** @jsxImportSource @emotion/react */

import { css, keyframes } from "@emotion/react";
import { useQuery } from "@tanstack/react-query";
import { Button } from "antd";
import HeaderUser from "components/mobile/HeaderUser";
import HideFooter from "components/mobile/HideFooter";
import { QueryKeys } from "constants/queryKeys";
import { useGetMemberBalance } from "query/users/queryHookUser";

export interface BalanceType {
  title: string;
  total: string;
}

const skeletonAnimate = keyframes`
  to {
      background-position-x: -200%;
    }
`;

const WalletPage = () => {
  const { data: username } = useQuery<string>([QueryKeys.username]);
  const {
    data: balanceMember,
    refetch,
    isFetching,
  } = useGetMemberBalance(username || "");

  // const listBalanceGame: BalanceType[] = [
  //   {
  //     title: "CMD Sport",
  //     total: balanceMember?.cmd_balance || "0.00",
  //   },
  //   {
  //     title: "Jili",
  //     total: balanceMember?.jili_balance || "0.00",
  //   },
  //   {
  //     title: "PGS2",
  //     total: balanceMember?.pgs2_balance || "0.00",
  //   },
  //   {
  //     title: "JDB",
  //     total: balanceMember?.jdb_balance || "0.00",
  //   },

  //   {
  //     title: "Sexy",
  //     total: balanceMember?.sx_balance || "0.00",
  //   },
  //   {
  //     title: "SA Gaming",
  //     total: balanceMember?.sa_balance || "0.00",
  //   },
  //   {
  //     title: "SBO Sport",
  //     total: balanceMember?.sbo_balance || "0.00",
  //   },
  //   {
  //     title: "WGB",
  //     total: balanceMember?.wgb_balance || "0.00",
  //   },
  //   {
  //     title: "PP",
  //     total: balanceMember?.pp_balance || "0.00",
  //   },
  // ];

  return (
    <>
      <HeaderUser title="Wallet" />
      <div
        css={css`
          padding: 63px 15px 15px;
        `}
      >
        <div
          css={css`
            display: flex;
            flex-direction: column;
            gap: 16px;
          `}
        >
          <div
            css={css`
              border-radius: 5px;
              background-color: #f3f3f3;
              padding: 32px 18px 20px;
              display: flex;
              flex-direction: column;
              gap: 20px;
            `}
          >
            <div
              css={css`
                padding: 4px;
                display: flex;
                flex-direction: column;
                border-left: 2px solid #f00;
              `}
            >
              <div
                css={css`
                  color: #000;
                  font-size: 12px;
                  font-weight: 700;
                `}
              >
                Main
              </div>

              <p
                css={css`
                  color: #9e9e9e;
                  font-size: 12px;
                  font-weight: 400;
                  margin-top: 5px;
                `}
              >
                {isFetching ? (
                  <div
                    css={css`
                      width: 100%;
                      height: 14px;
                      background: #eee;
                      background: linear-gradient(
                        110deg,
                        #ececec 8%,
                        #f5f5f5 18%,
                        #ececec 33%
                      );
                      border-radius: 5px;
                      background-size: 200% 100%;
                      animation: 1.5s ${skeletonAnimate} linear infinite;
                      display: block;
                    `}
                  />
                ) : (
                  <> ₹ {balanceMember ? balanceMember?.total : "0.00"}</>
                )}
              </p>
            </div>
            <div
              onClick={() => refetch()}
              css={css`
                border-radius: 4px;
                border: 1px solid #d0d0d0;
                background: #fff;
                padding: 9px 9px 8.5px 9px;
                width: 120px;
                text-align: center;
                font-size: 12px;
              `}
            >
              Collect All
            </div>
          </div>
          {/* <div
            css={css`
              padding: 0 16px;
            `}
          >
            <div
              css={css`
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                row-gap: 16px;
              `}
            >
              {listBalanceGame.map((balance: BalanceType, index: number) => (
                <div
                  key={index}
                  css={css`
                    padding-top: 16px;
                  `}
                >
                  <div
                    css={css`
                      padding-left: 6px;
                      border-left: 2px solid #f00;
                      display: grid;
                      grid-template-columns: repeat(2, 1fr);
                    `}
                  >
                    <div
                      css={css`
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                      `}
                    >
                      <div
                        css={css`
                          font-size: 11px;
                          font-weight: 700;
                        `}
                      >
                        {balance.title}
                      </div>
                      <p
                        css={css`
                          color: #9e9e9e;
                          font-size: 12px;
                          font-weight: 400;
                        `}
                      >
                        ₹ {balance.total}
                      </p>
                    </div>
                    <div
                      css={css`
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        gap: 4px;
                        flex-direction: column;
                      `}
                    >
                      <div
                        css={css`
                          display: flex;
                          justify-content: center;
                          align-items: center;
                          height: 12px;
                          width: 12px;
                        `}
                      >
                        <Image src={loadingImage} alt="" preview={false} />
                      </div>
                      <div
                        css={css`
                          color: #9e9e9e;
                          text-align: center;
                          font-size: 9px;
                          font-weight: 400;
                        `}
                      >
                        Refresh
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div> */}

          <div
            css={css`
              margin: 32px 0 16px;
            `}
          >
            <Button
              css={css`
                background-color: rgb(39, 216, 160);
                padding: 4px 16px;
                border-radius: 5px;
                width: 100%;
                height: 36px;
                color: white !important;
                border: unset !important;

                :hover {
                  border-color: transparent !important;
                }
              `}
              loading={isFetching}
              onClick={() => refetch()}
            >
              Refresh All
            </Button>
          </div>
        </div>
      </div>

      <HideFooter />
    </>
  );
};

export default WalletPage;
