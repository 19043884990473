import { ContextAction } from "types";

import { AppState, ContextActionType } from "./app.context";

export const reducer = (
  state: AppState,
  action: ContextAction<ContextActionType, AppState>,
): AppState => {
  switch (action.type) {
    case "OPENMODAL":
      return {
        ...state,
        openModal: action.payload.openModal,
      };
    case "KEYMODAL":
      return {
        ...state,
        keymodal: action.payload.keymodal,
      };
    case "KEYITEM":
      return {
        ...state,
        keyItem: action.payload.keyItem,
      };
    case "START_DATE":
      return {
        ...state,
        startDate: action.payload.startDate,
      };
    case "END_DATE":
      return {
        ...state,
        endDate: action.payload.endDate,
      };
    case "CURRENT_PAGE":
      return {
        ...state,
        currentPage: action.payload.currentPage,
      };
    case "AMOUNT_WITHDRAWAL":
      return {
        ...state,
        amountWithDrawal: action.payload.amountWithDrawal,
      };
    case "MODAL_RECORD":
      return {
        ...state,
        openModalRecord: action.payload.openModalRecord,
      };
    case "LAST_LOGGEDIN":
      return {
        ...state,
        lastLoggedIn: action.payload.lastLoggedIn,
      };
    case "START_DATE_ORDER":
      return {
        ...state,
        startDateOrder: action.payload.startDateOrder,
      };
    case "END_DATE_ORDER":
      return {
        ...state,
        endDateOrder: action.payload.endDateOrder,
      };
    case "STATUS_ORDER":
      return {
        ...state,
        statusOrder: action.payload.statusOrder,
      };
    case "TRANFER_TYPE":
      return {
        ...state,
        transfer_type: action.payload.transfer_type,
      };
    case "SET_NAVIGATE_PROFILE":
      return {
        ...state,
        idNavigateProfile: action.payload.idNavigateProfile,
      };
    case "SET_NAVIGATE_AFFILIATE":
      return {
        ...state,
        idNavigateAffiliate: action.payload.idNavigateAffiliate,
      };
    case "DARK_MODE":
      return {
        ...state,
        darkMode: action.payload.darkMode,
      };
    default:
      return state;
  }
};
