/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Spin } from "antd";

type Props = {
  visible: boolean;
};

function LoadingGame({ visible }: Props) {
  if (!visible) return null;
  return (
    <div
      css={css`
        position: fixed;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      `}
    >
      <Spin />
    </div>
  );
}

export default LoadingGame;
