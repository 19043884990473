/** @jsxImportSource @emotion/react */

import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Form, message } from "antd";
import { DocumentsApi } from "api/documents";
import { DocumentUserPayload } from "api/documents/type";
import InputCustom from "components/common/InputCustom";
import InputFile from "components/common/InputFile";
import LoadingCustom from "components/common/LoadingCustom";
import HeaderUser from "components/mobile/HeaderUser";
import HideFooter from "components/mobile/HideFooter";
import MESSAGE from "constants/message";
import { PATH } from "constants/path";
import { QueryKeys } from "constants/queryKeys";
import { REGEX_FULLNAME } from "constants/regex";
import { ResponseText } from "constants/response";
import dayjs, { Dayjs } from "dayjs";
import { queryClient } from "index";
import { useGetMemberDoc } from "query/member/queryHookMember";
import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { ProfileType } from "types/user";
import * as yup from "yup";

import { PanCardMobileContainer } from "./style.index";

const schema: any = yup.object({
  file: yup.mixed().required("File is required"),
  fullname: yup.string().trim(),
  birthday: yup.string().trim(),
  card_id: yup.string().trim().required("Card id is required"),
  message: yup.string().trim().required("Message is required"),
});

interface State {
  birdthDay: Dayjs;
  previewUrl: string;
  loading: boolean;
}

interface DocumentFormData {
  file: any;
  card_id: string;
  birthday?: string;
  message: string;
  fullname?: string;
}

const defaultValue = {
  file: undefined,
  fullname: "",
  birthday: dayjs().toString(),
  card_id: "",
  message: "",
};

const PanCardMobile = () => {
  const navigate = useNavigate();

  const username = queryClient.getQueryData<string>([
    QueryKeys.username,
  ]) as string;

  const { data: dataMemberDocuments, isLoading } = useGetMemberDoc(username);
  const dataUser = queryClient.getQueryData<ProfileType>([
    QueryKeys.MemberDetail,
  ]);

  const [state, setState] = useState<State>({
    birdthDay: dayjs(),
    loading: false,
    previewUrl: "",
  });

  const [isLoad, setLoad] = useState<string>("");

  const documentIsActive =
    dataMemberDocuments?.filter((item) => item.active === "Acitve") || [];

  const imageActive = useMemo(() => {
    return documentIsActive && documentIsActive.length > 0
      ? documentIsActive[0].document || ""
      : "";
  }, [documentIsActive]);

  const isDisable = !!documentIsActive.length;

  const {
    handleSubmit,
    control,
    reset,
    watch,
    formState: { errors, isValid },
  } = useForm<DocumentFormData>({
    mode: "all",
    resolver: yupResolver(schema) as any,
    defaultValues: defaultValue,
  });

  const handlePreviewImage = (file: any) => {
    if (state.previewUrl) {
      URL.revokeObjectURL(state.previewUrl);
    }
    setState((prev) => ({
      ...prev,
      previewUrl: URL.createObjectURL(file),
    }));
  };

  const convertBase64 = (file: any) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      if (file) {
        fileReader.readAsDataURL(file);
      }
      fileReader.onload = () => {
        resolve(fileReader?.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const handleInputFullNameChange = (
    event: React.KeyboardEvent<HTMLInputElement>,
  ) => {
    const key = event.key;

    if (!REGEX_FULLNAME.test(key) && key !== "Enter") {
      event.preventDefault();
    }
  };

  const onSubmit = async (form: DocumentFormData) => {
    const dataBase64 = await convertBase64(form.file);

    if (!dataBase64) return;

    const prefix = "\\";
    const document = prefix + (dataBase64 as string).split(",")[1];
    const payload: DocumentUserPayload = {
      ...form,
      username,
      document,
      fullname: dataUser?.full_name,
      birthday: dataUser?.birth,
    };

    try {
      setState((prev) => ({
        ...prev,
        loading: true,
      }));
      setLoad("");
      const response = await DocumentsApi.addBankDocuments(username, payload);
      if (response.msg === ResponseText.UPLOAD_SUCCESS) {
        message.success(MESSAGE.UPLOAD_FILE_SUCCESS);
        setState((prev) => ({
          ...prev,
          previewUrl: "",
          loading: false,
        }));
        setLoad(response.msg);
        reset({ file: null });
      } else {
        message.error(MESSAGE.UPLOAD_FILE_FAIL);
        reset({ file: null });
      }
    } catch (error) {
      reset({ file: null });
    }
    reset();
  };
  // const disabledDate: RangePickerProps["disabledDate"] = (current) => {
  //   // Can not select days before today and today
  //   return current && current > dayjs();
  // };

  useEffect(() => {
    if (!dataUser) return;

    if (!dataUser.birth) {
      navigate(PATH.user.profile);
      message.warning({
        key: "PAN_CARD_MOBILE",
        content: "Please update profile information to use pan card",
      });
    }
  }, [dataUser]);

  return (
    <PanCardMobileContainer>
      <HeaderUser title="Pan Card" />
      <div className="wrapperPan">
        <Form onSubmitCapture={handleSubmit(onSubmit)}>
          {isLoading ? (
            <div className="loading">
              <LoadingCustom height="200px" />
            </div>
          ) : (
            <>
              {imageActive || state.previewUrl ? (
                <div className="inputFile">
                  <img src={imageActive || state.previewUrl} alt="" />
                </div>
              ) : (
                <div className="inputFile">
                  <InputFile
                    buttonName=""
                    icon
                    placeholder=""
                    control={control}
                    file={watch("file")}
                    name="file"
                    validate_status={errors.file ? "error" : "success"}
                    onChange={(file) => handlePreviewImage(file)}
                    disabled={isDisable}
                  />
                </div>
              )}
            </>
          )}
          <div className="btnSubmit">
            <Button disabled={!isValid} htmlType="submit">
              Submit
            </Button>
          </div>
          <div className="title">Card Details</div>

          <div className="formGroup">
            <label>Full Name</label>
            <InputCustom
              disabled
              value={dataUser?.full_name}
              key={isLoad}
              name={"fullname"}
              control={control}
              id={"fullname"}
              prefix={<></>}
              onKeyDown={handleInputFullNameChange}
            />
          </div>

          <div className="formGroup">
            <label>Card ID</label>
            <InputCustom
              disabled={isDisable}
              value={documentIsActive[0]?.card_id}
              key={isLoad}
              name={"card_id"}
              control={control}
              id={"card_id"}
              prefix={<></>}
            />
          </div>

          <div className="formGroup">
            <label>Birthday</label>
            <InputCustom
              disabled
              value={dataUser?.birth}
              key={isLoad}
              name={"birthday"}
              control={control}
              id={"birthday"}
              prefix={<></>}
            />
          </div>

          {/* <div className= "formGroup">
            <label>Birthday</label>
            <ConfigProvider
              theme={{
                token: {
                  colorPrimary: "#ffbc00",
                  controlItemBgHover: "#EBEBEB",
                  borderRadiusSM: 50,
                  controlHeight: 38,
                },
              }}
            >
              <DatePickerCustom
                key={isLoad}
                defaultValue={dayjs(watch("birthday"))}
                disabled={isDisable}
                value={
                  documentIsActive.length === 0
                    ? dayjs(watch("birthday"))
                    : dayjs.unix(Number(documentIsActive[0]?.birthday))
                }
                name={"birthday"}
                id={"birthday"}
                onChange={(val) => {
                  setValue("birthday", dayjs(val).toString());
                }}
                format={["DD-MM-YYYY", "DD-MM-YY", "DD-MM-YYYY", "DD-MM-YY"]}
                suffixIcon={
                  <CalendarOutlined className= "iconCalendar" />
                }
                disabledDate={disabledDate}
              />
            </ConfigProvider>
          </div> */}

          <div className="formGroup">
            <label>Message</label>
            <InputCustom
              disabled={isDisable}
              value={documentIsActive[0]?.message}
              key={isLoad}
              name={"message"}
              control={control}
              id={"message"}
              prefix={<></>}
            />
          </div>
        </Form>
      </div>
      <HideFooter />
    </PanCardMobileContainer>
  );
};

export default PanCardMobile;
