import styled from "@emotion/styled";
import { theme } from "styles/theme";

export const FooterStyle = styled.div`
  background: ${(props) => props.theme.colors.black};

  .wrapperFooter {
    position: relative;
    .background {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .textBox {
    position: absolute;
    top: 15%;
    left: 25%;
  }

  .heading {
    text-align: center;
    font-style: normal;
    h2 {
      font-size: 48px;
      font-weight: 600;
      color: ${(props) => props.theme.colors.dark};
    }

    p {
      font-size: 20px;
      font-weight: 400;
      line-height: 24px;
      color: ${(props) => props.theme.colors.gray[100]};
    }
  }

  .download {
    margin-top: 72px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 81px;
  }
  .downloadItem {
    display: flex;
    align-items: center;
    flex-direction: column;

    span {
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;

      margin: 16px 0;
      color: ${(props) => props.theme.colors.white};
    }

    p {
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;

      cursor: pointer;

      color: ${theme.colors.text?.link};
      position: relative;
      &::before {
        content: "";
        position: absolute;
        bottom: 0;
        background-color: #1877f2;
        height: 1px;
        width: 100%;
      }
    }

    img {
      background: ${(props) => props.theme.colors.fullWhite};
    }
  }
`;
