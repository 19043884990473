/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import { Button } from "antd";
import LoadingCustom from "components/common/LoadingCustom";
import HeaderUser from "components/mobile/HeaderUser";
import { PATH } from "constants/path";
import { QueryKeys } from "constants/queryKeys";
import { queryClient } from "index";
import { useQueryWithdraw } from "query/withdraw/queryHookWithdraw";
import { Fragment } from "react";
import { useNavigate } from "react-router-dom";

import BankCard from "./BankCard";
import { BankListUserStyle } from "./style.index";

const BankListUser = () => {
  const username = queryClient.getQueryData<string>([QueryKeys.username]);

  const navigate = useNavigate();

  const { data: dataMemberBank, isLoading } = useQueryWithdraw(
    String(username),
  );

  const renderLayout = () => {
    if (isLoading) {
      return <LoadingCustom height="100vw" />;
    }

    return (
      <Fragment>
        <BankCard />
        {(!dataMemberBank?.player_bank?.length ||
          dataMemberBank?.player_bank?.length < 3) && (
          <Button
            css={css`
              width: 100%;
              height: 40px;
              margin-top: 12px;
              font-size: 13px;
              border-radius: 8px;
              border: 1px solid #d0d0d0;
            `}
            onClick={() => {
              navigate(PATH.user.bankEnrollUser);
            }}
          >
            Enroll Bank
          </Button>
        )}
      </Fragment>
    );
  };

  return (
    <>
      <HeaderUser title="Enroll" onBack={() => navigate(PATH.user.profile)} />
      <div css={BankListUserStyle.wrapper}>{renderLayout()}</div>
    </>
  );
};

export default BankListUser;
