import { useQuery } from "@tanstack/react-query";
import { QueryKeys } from "constants/queryKeys";

import { getMemberQueryFn } from "./queryFnsMember";

export const useGetMemberDoc = (username: string) =>
  useQuery({
    queryKey: [QueryKeys.GetMemberDoc, username],
    queryFn: () => getMemberQueryFn(username),
    staleTime: Infinity,
  });
