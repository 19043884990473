import axiosRequest from "api";
import { QueryKeys } from "constants/queryKeys";
import { ResponseCode } from "constants/response";
import { payloadHelper } from "helpers/payload-helper";
import { ApiResponse } from "types/api";

import {
  ChangePasswordFormType,
  ForgotPasswordFormType,
  LoginFormType,
  LoginResponse,
  RegisterFormType,
  RequestOTPType,
  VerifyTokenPayload,
} from "./type";

export const AuthApi = {
  // 1: successful => return
  // 2: failed => throw error
  login: async (payload: LoginFormType) => {
    const res = await axiosRequest.post<LoginResponse>(
      "/",
      payloadHelper(QueryKeys.MemberLogin, payload.username, payload),
    );
    if (res.data.status === ResponseCode.SUCCESS) {
      return res.data;
    }
    throw new Error(res.data.msg);
  },
  async verifyToken(typename: string, payload: VerifyTokenPayload) {
    const { data } = await axiosRequest.post<ApiResponse>(
      "/",
      payloadHelper("VerifyToken", typename, payload),
    );
    return data;
  },

  register: async (payload: RegisterFormType) => {
    const response = await axiosRequest.post<ApiResponse>(
      "/",
      payloadHelper(QueryKeys.MemberRegister, payload.username, payload),
    );
    if (response.data.status === ResponseCode.SUCCESS) {
      return {
        ...response.data,
        data: {
          ...response.data,
          ...payload,
        },
      };
    }
    throw new Error(response.data.msg);
  },

  forgotPassword: (typename: string, payload: ForgotPasswordFormType) =>
    axiosRequest.post<ApiResponse>(
      "/",
      payloadHelper("ForgotPassword", typename, payload),
    ),

  async changePassword(typename: string, payload: ChangePasswordFormType) {
    const response = await axiosRequest.post<ApiResponse>(
      "/",
      payloadHelper("ChangePassword", typename, payload),
    );
    return response.data;
  },
  requestOTP: (payload: RequestOTPType) =>
    axiosRequest.post<ApiResponse>(
      "/",
      payloadHelper("RequestOTP", payload.username, payload),
    ),
};
