/** @jsxImportSource @emotion/react */

import { useMutation } from "@tanstack/react-query";
import { message } from "antd";
import { BankApi } from "api/bank";
import { ReactComponent as TrashIcon } from "assets/common/icons/trash.svg";
import { QueryKeys } from "constants/queryKeys";
import { ResponseCode } from "constants/response";
import { queryClient } from "index";
import { BankStateType, PlayerBankType } from "types/bank";

import { BankCardStyle } from "./style.index";
const defaultValue: BankStateType = {
  player_bank: [],
  currencies: [],
  setting: {
    max_withdrawal: "",
    min_withdrawal: "",
  },
};

const BankCard = () => {
  const data = queryClient.getQueryData<BankStateType>([
    QueryKeys.WithDrawForm,
  ]);
  const username = queryClient.getQueryData<string>([QueryKeys.username]);

  const dataWithDraw: BankStateType = data || defaultValue;
  const handleRemoveBank = async (id: string) => {
    const response = await BankApi.removeMemberBank(String(username), {
      username: String(username),
      id,
    });

    if (response.status === ResponseCode.SUCCESS) {
      message.success({
        content: response.msg,
      });
    } else {
      message.error({
        content: response.msg,
      });
    }
  };

  const mutation = useMutation((id: string) => handleRemoveBank(id), {
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKeys.WithDrawForm]);
    },
  });

  const handleDelete = (id: string) => {
    mutation.mutate(id);
  };

  return (
    <>
      {dataWithDraw?.player_bank?.map((item: PlayerBankType) => {
        const sizeAccountNo = item.bank_account_no.length / 2;
        const bankAccountNumber =
          item.bank_account_no?.substring(0, sizeAccountNo - 1) +
          "**" +
          item.bank_account_no?.substring(sizeAccountNo + 1);

        return (
          <div key={item.player_bank_id} css={BankCardStyle.wrapper}>
            <div css={BankCardStyle.nameContentBank}>
              <div css={BankCardStyle.nameBank}>
                <img src="/user/bankNameIcon.png" alt="" />
                <span>{item.bank_name}</span>
              </div>
              <div
                css={BankCardStyle.icon}
                onClick={() => handleDelete(item.player_bank_id)}
              >
                <TrashIcon fill="#9e9e9e" />
              </div>
            </div>
            <div css={BankCardStyle.numberBank}>
              <p>{bankAccountNumber}</p>
            </div>
            <div css={BankCardStyle.nameUser}>{item.bank_account_name}</div>
          </div>
        );
      })}
    </>
  );
};

export default BankCard;
