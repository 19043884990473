/** @jsxImportSource @emotion/react */

import { useQuery } from "@tanstack/react-query";
import { getMemberVipUser } from "api/vip/query";
import { VipMemberDetailType } from "api/vip/type";
import { IconArrowNext } from "assets/mobile/icons/profile";
import HeaderUser from "components/mobile/HeaderUser";
import HideFooter from "components/mobile/HideFooter";
import {
  ItemMenuHeaderStyle,
  ItemMenuStyle,
  MenuInfoUserStyle,
} from "components/mobile/MenuInformationUser/style.index";
import { LIST_DETAIL_USER } from "constants/menu-user";
import { PATH } from "constants/path";
import { QueryKeys } from "constants/queryKeys";
import { AppContext } from "contexts/app.context";
import { ModalTypeValue } from "enums/modalType";
import { handleOpenModal } from "helpers/handleModal";
import { queryClient } from "index";
import { useGetProfile } from "query/users/queryHookUser";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { ConfigCss } from "styles/config";
import { ProfileType } from "types/user";

import { ProfileUserContainer } from "./style.index";

const ProfileUser = () => {
  const { state, dispatch } = useContext(AppContext);
  const navigate = useNavigate();

  const username = queryClient.getQueryData<string>([QueryKeys.username]);
  const { data: dataProfile } = useGetProfile(username || "");

  const { data: dataMemberVip } = useQuery<VipMemberDetailType>({
    queryKey: [QueryKeys.MemberVIP],
    queryFn: () => getMemberVipUser(String(username)),
  });

  const handleOpenModalNavigate = (key: string) => {
    if (
      key === ModalTypeValue.USER_DETAIL ||
      key === ModalTypeValue.USER_NICKNAME ||
      key === ModalTypeValue.USER_PICTURE
    ) {
      handleOpenModal(state, dispatch, key);
      return;
    }
    navigate(key);
  };

  const totalCurrentVip =
    Number(dataMemberVip?.next_level.current_deposit || 0) +
    Number(dataMemberVip?.next_level.current_turnover || 0);

  const targeNextVip =
    Number(dataMemberVip?.next_level.deposit || 0) +
    Number(dataMemberVip?.next_level.turnover || 0);

  const percentNextVip = (totalCurrentVip / targeNextVip) * 100;

  return (
    <ProfileUserContainer percent={percentNextVip}>
      <HeaderUser
        onBack={() => {
          if (state.idNavigateProfile === "home") {
            navigate("/");
            return;
          }

          navigate(PATH.user.index);
        }}
        title="Profile"
      />
      <div className="wrapperProfile">
        <div className="header-index">
          <p>UID: {username}</p>
          <span>{(dataProfile as ProfileType)?.full_name}</span>
          <div className="header-vip">
            <span>VIP {dataMemberVip?.current_level}</span>
          </div>
        </div>

        <div className="progress">
          <span className="vipProgress-lv">
            V{dataMemberVip?.current_level}
          </span>
          <span className="vipProgress-total">
            {totalCurrentVip}/{targeNextVip}
          </span>
        </div>

        <div className="describe">
          To next level still need {targeNextVip} Exp
        </div>

        <div css={MenuInfoUserStyle.listMenu}>
          {LIST_DETAIL_USER.map((item) => (
            <ItemMenuStyle
              css={ConfigCss.flexBetween}
              key={item.key}
              onClick={() => handleOpenModalNavigate(item.key)}
            >
              <ItemMenuHeaderStyle>
                <div className="icon-menu">
                  <item.icon />
                </div>
                <span>{item.name}</span>
              </ItemMenuHeaderStyle>
              <div className="icon-arrow">
                <IconArrowNext />
              </div>
            </ItemMenuStyle>
          ))}
        </div>
      </div>
      <HideFooter />
    </ProfileUserContainer>
  );
};

export default ProfileUser;
