import styled from "@emotion/styled";
import { themeCustom } from "styles/theme";

interface Props {
  percent: number;
  percentTurnOver: number;
}

export const VipDetailUserStyle = styled.div<Props>`
  .wrapperVip {
    padding: 64px 22px 0 22px;
  }

  .flexCenter {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .headerVip {
    span {
      color: ${(props) => props.theme.colors.white};
      text-align: right;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 19.5px;
    }
  }
  .progressDeposit {
    margin-top: 7.5px;

    position: relative;
    background-color: #ad7e00;
    border-radius: 4px;
    height: 32px;

    padding: 0 6px;
    &::after {
      content: "";
      background-color: #fbb700;
      width: ${(props) =>
        props.percent && props.percent > 100 ? 100 : props.percent}%;
      height: 100%;
      border-radius: 4px;
      position: absolute;
      top: 0;
      left: 0;
    }
    span {
      z-index: 2;
      color: ${themeCustom.colors.white};
      font-size: 11px;
      font-weight: 700;
      margin-left: 8px;
      line-height: 12px;
    }
  }

  .progressRollover {
    margin-top: 16px;

    position: relative;
    background-color: #ad7e00;
    border-radius: 4px;
    height: 32px;

    padding: 0 6px;
    &::after {
      content: "";
      background-color: #fbb700;
      width: ${(props) =>
        props.percentTurnOver && props.percentTurnOver > 100
          ? 100
          : props.percentTurnOver}%;
      height: 100%;
      border-radius: 4px;
      position: absolute;
      top: 0;
      left: 0;
    }
    span {
      z-index: 2;
      color: ${themeCustom.colors.white};
      font-size: 11px;
      font-weight: 700;
      margin-left: 8px;
      line-height: 12px;
    }
  }
  .describe {
    color: #ad7e00;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 19.5px;

    margin: 8px 0 15.5px 0;
  }
  .table {
    table {
      border-collapse: collapse;
      width: 100%;
      tr {
        display: block;
        padding: 12px;
        display: flex;

        background: #5c49d8;

        &:not(:first-of-type) {
          background-color: #e3e9ff;
          margin-bottom: 2px;
        }
        &:first-of-type {
          border-radius: 5px 5px 0px 0px;
        }
        td,
        th {
          width: 100%;
        }

        th {
          text-align: left;

          color: ${themeCustom.colors.white};
          font-size: 11px;
          font-style: normal;
          font-weight: 700;
          line-height: 19.5px;
        }
        td {
          color: #1d1d1d;
          font-size: 10px;
          font-style: normal;
          font-weight: 400;
          line-height: 16.5px;
        }

        .flex {
          display: flex;
          justify-content: center;
        }
      }
    }
  }
`;
