import { useEffect } from "react";

function HideFooter() {
  useEffect(() => {
    const element = document.getElementById(`app-footer`);
    if (element) {
      element.style.display = "none";
    }
    return () => {
      const element = document.getElementById(`app-footer`);
      if (element) {
        element.style.display = "block";
      }
    };
  }, []);
  return null;
}

export default HideFooter;
