import { css } from "@emotion/react";

export const ListSubGameStyle = {
  index: css`
    color: #fff;
    position: relative;
  `,
  listGame: css`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    position: relative;
    row-gap: 1vw;
    width: 100%;
    column-gap: 1%;

    .empty {
      .ant-empty-description {
        color: #fff;
      }
    }

    .casino-card {
      width: 15.8333333333%;
      cursor: pointer;

      &.image-error {
        .name-game {
          display: flex !important;
        }
      }

      .image {
        width: 100%;
        height: 100%;
        overflow: hidden;
        border-radius: 14%;
        border: 0.01vw solid #0000;
        position: relative;

        .name-game {
          display: none;
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 3vw;
          background-color: rgba(0, 0, 0, 0.3);
          text-align: center;
          padding: 4px;
          font-size: medium;
          align-items: center;
          justify-content: center;
          font-size: 0.8vw;
          span {
            text-align: center;
          }
        }

        &:hover {
          border: 0.01vw solid #ff3737;
          .overplay {
            display: flex;
          }
        }

        .overplay {
          display: none;
          position: absolute;
          background: linear-gradient(
            43deg,
            #2c40a1b3,
            #2c60a1b3 43%,
            #278ab6b3
          );
          z-index: 2;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          transition-duration: 0.2s;
          transition-property: all;
          transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);

          &:hover {
            .button {
              right: 50%;
              transform: translate(50%, -50%);
            }
          }

          .button {
            background: #ffbc00;
            font-size: 0.8vw;
            letter-spacing: 0.018vw;
            padding: 0.3vw 0.6vw;
            position: absolute;
            right: -50%;
            top: 50%;
            transform: translate(-50%, -50%);
            color: #fff;
          }
        }

        .img__container {
          width: 100%;
          height: 100%;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover !important;
            object-position: 50% 50% !important;

            &.image-error {
            }
          }
        }
      }
    }
  `,
  loadMore: css`
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0.8vw 0;
    p {
      background: #ffbc00;
      font-size: 0.8vw;
      letter-spacing: 0.05vw;
      padding: 0.3vw 0.5vw;
      width: max-content;
      transition: all 0.32s ease;
      color: #fff;
      cursor: pointer;

      &:hover {
        transform: scale(1.05);
      }
    }
  `,
};
